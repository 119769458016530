import React, { useState, useEffect } from 'react';

const GlassCard = ({ title, dynamicTitle, iconSrc, children }) => {
  const [currentTitle, setCurrentTitle] = useState(title);

  useEffect(() => {
    if (dynamicTitle) {
      setCurrentTitle(dynamicTitle); // Update title when dynamicTitle changes
    } else {
      setCurrentTitle(title); // Fall back to static title
    }
  }, [dynamicTitle, title]);

  return (
    <div className="glass-card flex-grow flex-shrink-0 basis-[45%] h-[35vh] w-[48vh] m-2 p-4 rounded-3xl shadow-lg backdrop-blur-lg bg-lavender/5 relative border-r-2 border-b-2 border-white/30">
      <h2 className="text-xl font-light mb-2 text-customLightBlue1 font-lato flex items-center glow-effect">  
        <img
          src={iconSrc}
          alt={` Icon`}
          className="mr-2 w-6 h-6 glow-icon text-customLightBlue1"
        />
        {currentTitle}
      </h2>
      <div className="h-full w-full flex justify-center items-center">{children}</div> {/* Ensure children fill the card */}
    </div>
  );
};

export default GlassCard;
