import React, { useState } from 'react';
import { useDeveloperProjects } from 'data/project';
import {
  useAnnualizedRoi,
  useCapitalAdequacy,
  useLoanToValue,
  useScheduleFeasibility,
} from 'data/capital';
import { useCreditTermCoverage } from 'data/debt';
import { useAppContext } from 'context';
import { CircularProgress } from '@mui/material';
import { toast } from 'sonner';
import LoanToValueFlasks from './capital-components/ProjectLoantoValue';
import SliderComponent from './capital-components/Scenario';
import AnnualizedRoi from './capital-components/aroi';
import CapitalAd from './capital-components/CapAdequacyComp';
import CapGlassCard from './capital-components/CapGlassCardComp';
import AnnualizedRoiICcon from '../../assets/Title Icons/AnnualizedROI.svg';
import AOISceneIcon from '../../assets/Title Icons/AnnualizedROIsCENARIO.svg';
import CapAdIcon from '../../assets/Title Icons/CapitalAdequacy.svg';
import LTVIcon from '../../assets/Title Icons/ProjectLoantoValue.svg';
import ScenarioIcon from '../../assets/Title Icons/ScenarioAnalysis.svg';
import CapAdSceneIcon from '../../assets/Title Icons/CapitalAdequacyScenario.svg';

export default function CapitalAdvisory() {
  const { developer } = useAppContext();

  // Extract the first word of the developer name
  const developerName = developer?.split(' ')?.[0];

  // Hooks for fetching data
  const loanToValue = useLoanToValue({ developer: developerName });
  const capitalAdequacy = useCapitalAdequacy({ developer: developerName });
  const scheduleFeasibility = useScheduleFeasibility({ developer: developerName });
  const annualizedRoi = useAnnualizedRoi({ developer: developerName });
  const creditTermCoverage = useCreditTermCoverage({ developer: developerName });

  // State to hold the results from the slider
  const [capitalVariationResult, setCapitalVariationResult] = useState([]);
  const [roiVariationResult, setRoiVariationResult] = useState([]);

  // State to track which slider option is selected
  const [selectedOption, setSelectedOption] = useState(null);

  // Handle slider updates (API responses) and update variation results
  const handleSliderNext = ({ capitalData, roiData }, selectedOption) => {
    // When a new option is selected, reset the results
    setSelectedOption(selectedOption);
    if (capitalData) {
      setCapitalVariationResult(capitalData);
    }
    if (roiData) {
      setRoiVariationResult(roiData);
    }
  };

  // Function to reset the slider results when a new option is selected
  const resetResultsOnNewSelection = (newOption) => {
    if (newOption !== selectedOption) {
      // Reset the variation results to fall back on the base data
      setCapitalVariationResult([]);
      setRoiVariationResult([]);
      setSelectedOption(newOption); // Update the selected option
    }
  };

  const {
    data: projects,
    isLoading: isLoadingProjects,
    isError: isErrorFetchingProjects,
  } = useDeveloperProjects({ developer: developerName });

  // Handle early return if developer information is missing
  if (!developer) {
    return <div>Developer information not available</div>;
  }

  // Error handling for project fetching
  if (isErrorFetchingProjects) {
    toast.error('Error fetching developer projects');
    return null; // Stop rendering in case of error
  }

  // Error handling for data fetching
  if (
    loanToValue.isError ||
    capitalAdequacy.isError ||
    scheduleFeasibility.isError ||
    creditTermCoverage.isError ||
    annualizedRoi.isError
  ) {
    toast.error('An error occurred while fetching graph information');
    return null; // Stop rendering in case of error
  }

  // Loading state
  if (
    loanToValue.isLoading ||
    capitalAdequacy.isLoading ||
    scheduleFeasibility.isLoading ||
    annualizedRoi.isLoading ||
    creditTermCoverage.isLoading ||
    isLoadingProjects
  ) {
    return <CircularProgress />;
  }

  // Graceful handling for empty data
  const isLoanToValueEmpty = !loanToValue.data || loanToValue.data.length === 0;
  const isCapitalAdequacyEmpty = !capitalAdequacy.data || capitalAdequacy.data.length === 0;
  const isAnnualizedRoiEmpty = !annualizedRoi.data || annualizedRoi.data.length === 0;
  const isCreditTermCoverageEmpty = !creditTermCoverage.data || creditTermCoverage.data.length === 0;

  // If all data is empty, show a top-level message
  if (
    isLoanToValueEmpty &&
    isCapitalAdequacyEmpty &&
    isAnnualizedRoiEmpty &&
    isCreditTermCoverageEmpty
  ) {
    return <div className="text-base font-light pt-20 text-customLightBlue1 font-lato glow-effect">No capital advisory data available for this developer</div>;
  }

  return (
    <div className="bg-my-background-svg bg-cover bg-center min-h-screen w-full flex items-center justify-center overflow-hidden">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 p-3 pb-1 w-full max-w-[1800px] h-[80vh] box-border">
        {/* First column with two cards */}
        <div className="flex flex-col gap-2 h-full">
          <CapGlassCard title="Project Loan to Value" className="flex-grow h-[65%] lg:h-[65%]" iconSrc={LTVIcon}>
            {!isLoanToValueEmpty ? (
              <LoanToValueFlasks data={loanToValue.data} />
            ) : (
              <div className="text-base font-light mb-2 text-customLightBlue1 font-lato glow-effect">No loan-to-value data available</div>
            )}
          </CapGlassCard>
          <CapGlassCard title="Scenario Analysis" className="flex-grow h-[30%] lg:h-[30%]" iconSrc={ScenarioIcon}>
            <div className="h-[20vh] w-full p-2">
              {/* SliderComponent will trigger handleSliderNext and reset results on new selection */}
              <SliderComponent
                next={(data) => handleSliderNext(data, selectedOption)}
                resetResults={resetResultsOnNewSelection}
              />
            </div>
          </CapGlassCard>
        </div>

        {/* Second column with two cards */}
        <div className="flex flex-col gap-2 h-full">
          <CapGlassCard title="Capital Adequacy" className="flex-grow h-[44%] lg:h-[44%]" iconSrc={CapAdIcon}>
            {!isCapitalAdequacyEmpty && !isCreditTermCoverageEmpty ? (
              <CapitalAd
                data={creditTermCoverage.data}
                capitalAdequacyData={capitalAdequacy.data}
              />
            ) : (
              <div className="text-base font-light mb-2 text-customLightBlue1 font-lato glow-effect">No capital adequacy data available</div>
            )}
          </CapGlassCard>
          <CapGlassCard title="Capital Adequacy Scenario" className="flex-grow h-[45%] lg:h-[45%]" iconSrc={CapAdSceneIcon}>
            {!isCapitalAdequacyEmpty && !isCreditTermCoverageEmpty ? (
              <CapitalAd
                data={creditTermCoverage.data}
                capitalAdequacyData={capitalVariationResult.length ? capitalVariationResult : capitalAdequacy.data} 
              />
            ) : (
              <div className="text-base font-light mb-2 text-customLightBlue1 font-lato glow-effect">No capital adequacy scenario data available</div>
            )}
          </CapGlassCard>
        </div>

        {/* Third column with two cards */}
        <div className="flex flex-col gap-2 h-full">
          <CapGlassCard title="Annualized ROI" className="flex-grow h-[43%] lg:h-[43%]" iconSrc={AnnualizedRoiICcon}>
            {!isAnnualizedRoiEmpty ? (
              <AnnualizedRoi data={annualizedRoi.data} />
            ) : (
              <div className="text-base font-light mb-2 text-customLightBlue1 font-lato glow-effect">No annualized ROI data available</div>
            )}
          </CapGlassCard>
          <CapGlassCard title="Annualized ROI Scenario" className="flex-grow h-[43%] lg:h-[43%]" iconSrc={AOISceneIcon} >
            {roiVariationResult.length > 0 ? (
              // Render the variation result if available
              <AnnualizedRoi data={roiVariationResult} />
            ) : !isAnnualizedRoiEmpty ? (
              // Render the base case if the variation result is not available and the base data is not empty
              <AnnualizedRoi data={annualizedRoi.data} />
            ) : (
              // Fallback message if both are empty
              <div className="text-base font-light mb-2 text-customLightBlue1 font-lato glow-effect">
                No annualized ROI scenario data available
              </div>
            )}
          </CapGlassCard>
        </div>
      </div>
    </div>
  );
}
