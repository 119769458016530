const LiqGlassCard = ({ title, children}) => {
    return (
        <div className="glass-card flex-grow flex-shrink-0 basis-[48%] h-[35vh] m-2 p-4 pb-10 rounded-3xl shadow-lg backdrop-blur-lg bg-lavender/5        relative border-r-2 border-b-2 border-white/30">
          <div className="h-full w-full flex justify-center items-center">{children}</div> {/* Ensure children fill the card */}
            <h2 className="text-base font-light text-lavender font-lato glow-effect text-center">
              {title}
            </h2>
         </div> 
      );
    };

export default LiqGlassCard;