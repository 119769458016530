import React from 'react'
import { twMerge } from 'tailwind-merge'

export const DataEntryButton = ({ title, icon }) => {
  return (
    <div className="buttonImage container-shadow w-300px bg-[#cefaed] rounded-full text-customDarkBlue2 font-regular text-xs text-center font-lato pt-1 pl-2 pr-2">
      <button className="flex py-1">
        <span className="pr-2">{title}</span>
        <span className="pl-3 text-customDarkBlue2">{icon}</span> {/* Apply text-customDarkBlue2 to icon */}
      </button>
    </div>
  )
}

export const UploadFileButton = ({ disabled, title, icon, className, onClick }) => {
  return (
    <button
      className={twMerge(
        'container-shadow relative h-[35px] grid grid-cols-[auto_auto] px-3 gap-x-3 justify-center items-center bg-customRed opacity-0.8 rounded-full ',
        className
      )}
      disabled={disabled}
      onClick={onClick}
    >
      <span className="line-clamp-1 text-customDarkBlue2 text-xs font-medium font-lato">{title}</span>
      <span className="text-customDarkBlue2">{icon}</span> {/* Apply text-customDarkBlue2 to icon */}
    </button>
  )
}

export const AddedFileButton = ({ title, icon, className }) => {
  return (
    <button
      className={twMerge(
        'container-shadow h-[35px] cursor-default flex gap-x-3 justify-center items-center bg-customGreen rounded-full ',
        className
      )}
    >
      <span className="text-customDarkBlue2 text-sm font-medium font-lato">{title}</span>
      <span className="text-customDarkBlue2">{icon}</span> {/* Apply text-customDarkBlue2 to icon */}
    </button>
  )
}

export const EditFileButton = ({ disabled, title, icon, className, onClick }) => {
  return (
    <button
      className={twMerge(
        'container-shadow h-[35px] px-3 grid grid-cols-[auto_auto] gap-x-3 justify-center items-center bg-customGreen rounded-full ',
        className
      )}
      disabled={disabled}
      onClick={onClick}
    >
      <span className="text-customDarkBlue2 line-clamp-1 text-xs font-medium font-lato">{title}</span>
      <span className="text-customDarkBlue2">{icon}</span> {/* Apply text-customDarkBlue2 to icon */}
    </button>
  )
}
