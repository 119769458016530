// Importing necessary components and functions from external libraries
import * as Dropdown from '@radix-ui/react-dropdown-menu'; // Importing all Dropdown components from Radix UI's dropdown menu module
import { IoIosArrowBack } from 'react-icons/io'; // Importing a specific back arrow icon from react-icons
import { TREASURY_SUBMENU_OPTIONS, DEVELOPER_SUBMENU_OPTIONS } from 'lib/constants'; // Importing submenu options constants
import { Link } from 'react-router-dom'; // Importing Link component from react-router-dom for navigation
import { useAppContext, useAuthActions } from 'context'; // Importing context hooks to handle app state and authentication actions
import ReportsDropDown from 'components/reports'; // Importing custom ReportsDropDown component



// MenuDropdown functional component to display a dropdown menu based on the user's profile
export const MenuDropdown = ({ profile }) => {
  // Destructuring setActiveMenu function from useAuthActions hook, which is used to set the active menu item
  const { setActiveMenu } = useAuthActions();
  
  // Destructuring activeMenu from the app context to track the current active menu item
  const { activeMenu } = useAppContext();

  // Return JSX only if the user profile exists (truthy check for profile)
  return (
    profile && (
      <Dropdown.Root>
            {/* Dropdown group to structure menu items */}
            <Dropdown.Group className="px-2 mt-4 w-[98%]">
              {/* Conditional rendering of submenu options based on the presence of developers in the profile */}
              {(profile?.payload?.developers?.length
                ? TREASURY_SUBMENU_OPTIONS // Show Treasury options if developers exist in the profile
                : DEVELOPER_SUBMENU_OPTIONS // Otherwise, show Developer submenu options
              ).map(({ label, value, url, icon: Icon }) => // Iterating over the submenu options to generate menu items
                value === 'report' ? ( // Special case for 'report' value where ReportsDropDown is used
                  // Wrap ReportsDropDown in a single element if necessary for Dropdown compatibility
                  <ReportsDropDown key={value} /> // Rendering custom dropdown for reports
                ) : (
                  // For other submenu items, use Link component for navigation
                  <Link
                    // Styling the link item with Tailwind classes, using flex for alignment
                    className="flex items-center cursor-pointer text-center w-full px-4 2xl:px-4 py-3 font-lato text-base text-customDarkBlue2 rounded-2xl border-b-2  border-customDarkBlue2/20 hover:bg-darkrinkle"
                    key={value} // Unique key for each menu item
                    to={url} // Link to the corresponding URL from the submenu option
                    // When clicked, set the active menu state to the selected value
                    onClick={() => setActiveMenu({ activeMenu: value })}
                  >
                    {/* Display a left arrow icon only if the current active menu matches the value */}
                    <span className={activeMenu === value ? 'visible' : 'invisible'}>
                      {/* Icon for indicating active menu item */}
                      <IoIosArrowBack color="#180d2b" size={25} />
                    </span>
                    {/* Label for the menu item */}
                    {Icon && <Icon size={20} className="mr-2" />}
                    <p>{label}</p>
                  </Link>
                )
              )}
            </Dropdown.Group>
      </Dropdown.Root>
    )
  );
};
