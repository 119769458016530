const SolvencyGlassCard = ({ title, iconSrc, children }) => {
    return (
      <div className="glass-card flex-grow flex-shrink-0 basis-[48%] h-[39vh] m-2 p-2 rounded-3xl shadow-lg backdrop-blur-lg bg-lavender/5         relative border-r-2 border-b-2 border-white/30 relative z-10">
          <h2 className="text-xl font-light pt-2 text-customLightBlue1 font-lato glow-effect">
          <img
                    src={iconSrc}
                    alt={` Icon`}
                    className="ml-2 mr-2 w-6 h-6 glow-icon text-customLightBlue1"
                />
            {title}
          </h2>
        <div className="h-full w-full flex justify-center items-center">{children}</div> {/* Ensure children fill the card */}
       </div> 
    );
  };
  
  export default SolvencyGlassCard;