import AppLayout from 'components/layout'
import CapitalAdvisory from 'pages/capital'
import Liquidity from 'pages/liquidity'
import Login from 'pages/login'
import ClientOnboardingForm from 'pages/client-onboarding-form'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ProjectDataForm from 'pages/project/project-data-form'
import ClientDataForm from 'pages/client-data-form'
import Debt from 'pages/debt'
import Solvency from 'pages/solvency'
import LiquidityDataForm from 'pages/liquidity/liquidity-data-form'
import DebtDataForm from 'pages/debt/debt-data-form'
import ActivitySalesTableForm from 'pages/activity-and-sales-table'
import CLDataForm from 'pages/debt/debt-data-form/cl-data-form'
import AdminForm from 'pages/admin'
import { AppProvider } from 'context'
import EditProjectData from 'pages/project/project-data-form/edit-project-data'
import FirmwideDataForm from 'pages/firmwide/upload-data-form'
import ProjectAccount from 'pages/project/account-list'
import ProjectAccountForm from 'pages/project/add-account'
import EditAccountForm from 'pages/project/edit-account'
import CapitalSources from 'pages/capital/capital-sources'
import AddCapitalSources from 'pages/capital/capital-sources/add-capital-sources'
import EditCapitalSource from 'pages/capital/capital-sources/edit-capital-source'
import Checklist from 'pages/checklist'
import UpcomingFinancings from 'pages/debt/debt-data-form/upcoming-financings'
import EditCLDataForm from 'pages/debt/debt-data-form/cl-data-form/edit-cl-data'
import DebtTransactionHistory from 'pages/debt/debt-transaction/transaction-history'
import AddDebtTransaction from 'pages/debt/debt-transaction/add-transaction'
import NotFound from 'pages/NotFound'
import EditDebtTransaction from 'pages/debt/debt-transaction/edit-transaction'

export default function AllRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          element={
            <AppProvider>
              <AppLayout />
            </AppProvider>
          }
        >
          <Route path="*" element={<NotFound />} />
          <Route path="/admin" element={<AdminForm />} />
          <Route path="/client-onboarding-form" element={<ClientOnboardingForm />} />
          <Route path="/checklist" element={<Checklist />} />
          <Route path="/project-data-form" element={<ProjectDataForm />} />
          <Route path="/project-data-form/:id" element={<EditProjectData />} />
          <Route path="/client-data-form" element={<ClientDataForm />} />
          <Route path="/capital-sources">
            <Route index element={<CapitalSources />} />
            <Route path="new" element={<AddCapitalSources />} />
            <Route path=":id" element={<EditCapitalSource />} />
          </Route>
          <Route path="/dashboard">
            <Route path="liquidity" element={<Liquidity />} />
            <Route path="debt" element={<Debt />} />
            <Route path="solvency" element={<Solvency />} />
            <Route path="capital" element={<CapitalAdvisory />} />
          </Route>
          <Route path="/account" element={<ProjectAccount />} />
          <Route path="/add-account" element={<ProjectAccountForm />} />
          <Route path="/account/:id" element={<EditAccountForm />} />
          <Route path="/app-data">
            <Route path="liquidity" element={<LiquidityDataForm />} />
            <Route path="debt">
              <Route index element={<DebtDataForm />} />
              <Route path="cl-data-form" element={<CLDataForm />} />
              <Route path="cl-data-form/:id" element={<EditCLDataForm />} />
              <Route path="transaction">
                <Route path=":id" element={<EditDebtTransaction />} />
                <Route path="new" element={<AddDebtTransaction />} />
                <Route path="history" element={<DebtTransactionHistory />} />
              </Route>
              <Route path="upcoming-financings" element={<UpcomingFinancings />} />
            </Route>
            <Route path="solvency" element={<FirmwideDataForm />} />
            <Route path="capital" element={<ActivitySalesTableForm />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
