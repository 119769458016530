import React from 'react';
import GaugeComponent from 'react-gauge-component';
import Tooltip from '@mui/material/Tooltip';
import TooltipComponent from '../../../components/tool-tips/tooltipscomp';  // Import the central TooltipComponent
import ProjectIcon from '../../../assets/Tooltip Icons/Project.svg';
import CurrentIcon from '../../../assets/Tooltip Icons/Current.svg';
import TargetIcon from '../../../assets/Tooltip Icons/Target.svg';


export default function AnnualizedRoi({ data }) {
  // Transforming the data
  const items = data?.map((item) => ({
    project: item.project,
    currentValue: `${(Number(item.current_aroi) * 100).toFixed(2)}`,
    targetValue: (Number(item.initial_aroi) * 100).toFixed(2),
  }));

  return (
    <div className="flex flex-wrap justify-center gap-x-1 gap-y-1 items-center h-52">
      {items?.map((item, index) => (
        <div
          key={`${item.project}-${index}`} // Ensure unique keys by combining project and index
          className="relative text-black text-2xl flex justify-center"
        >
          <Tooltip
            key={`${item.project}-${index}`}
            title={
              <TooltipComponent
                fields={[
                  { value: item.project },
                  {  value: `${item.targetValue}%` },
                  { value: <b>{`${item.currentValue}%`}</b> }  // Current value wrapped in <b> tag
                ]}
                icons={[
                  <img src={ProjectIcon} className="w-4 h-4 mr-1" alt="Project" />,  
                  <img src={CurrentIcon} className="w-4 h-4 mr-1" alt="Initial ROI" />,  
                  <img src={TargetIcon} className="w-4 h-4 mr-1" alt="Current" />  
                ]} // Pass icons if you want, or leave empty
              />
            }
          >
            <div className="relative">
              <GaugeComponent
                type="semicircle"
                minValue={-20}
                maxValue={40}
                style={{ width: 150 }}
                marginInPercent={{ top: 0.06, bottom: 0.01, left: 0.0, right: 0.0 }}
                id={index} // Using index as an id is fine in this context
                nrOfLevels={2}
                cornerRadius={2}
                arc={{
                  cornerRadius: 2,
                  width: 0.3,
                  colorArray: ['#E6E6FA', '#CF9FFF', '#DF73FF'],
                  padding: 0.05,
                  subArcs: [
                    { limit: 0, color: '#E6E6FA' },
                    { limit: 20, color: '#CF9FFF' },
                    { limit: 40, color: '#DF73FF' },
                  ],
                }}
                pointer={{
                  type: 'arrow',
                  color: `${Number(item.currentValue) <= 10 ? '#DA70D6' : '#CCCCFF'}`,
                  baseColor: `${Number(item.currentValue) <= 10 ? '#DA70D6' : '#CCCCFF'}`,
                  length: 1,
                  width: 15,
                  elastic: true,
                }}
                labels={{
                  valueLabel: {
                    formatTextValue: (value) => `${value}%`,
                    style: {
                      fontSize: '24px',
                      font: 'lato' || 'sans-serif',
                      textShadow: '0 0 5px rgba(0, 153, 255, 0.5), 0 0 10px rgba(0, 153, 255, 0.5), 0 0 15px rgba(0, 153, 255, 0.5)', // Inline glow effect
                      fill: `${Number(item.currentValue) <= 10 ? '#DA70D6' : '#CCCCFF'}`,
                    },
                  },
                  tickLabels: {
                    hideMinMax: true,
                    type: 'outer',
                    ticks: [{ value: item.targetValue }],
                    defaultTickValueConfig: {
                      formatTextValue: (value) => `${value}%`,
                      style: {
                        fontSize: '0px',
                        opacity: 0,
                        textShadow: 'none',
                        fill: `${Number(item.targetValue) <= 10 ? '#DA70D6' : '#CCCCFF'}`,
                      },
                    },
                    defaultTickLineConfig: {
                      width: 4,
                      length: 20,
                      distanceFromArc: -15,
                      color: `${Number(item.targetValue) <= 10 ? '#DA70D6' : '#CCCCFF'}`,
                    },
                  },
                }}
                textComponentContainerClassName="text-sm"
                textColor={Number(item.currentValue) <= 10 ? '#DA70D6' : '#CCCCFF'}
                value={Number(item.currentValue)}
              />
            </div>
          </Tooltip>
        </div>
      ))}
    </div>
  );
}
