import React, { useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip as ChartTooltip, Legend } from 'chart.js'; // Alias chart.js Tooltip
import Tooltip from '@mui/material/Tooltip'; // Material UI Tooltip
import TooltipComponent from '../../../components/tool-tips/tooltipscomp';  // Import the central TooltipComponent
import ProjectIcon from '../../../assets/Tooltip Icons/Project.svg';
import ShortageIcon from '../../../assets/Tooltip Icons/Shortage.svg';

// Register the necessary chart.js components
ChartJS.register(ArcElement, ChartTooltip, Legend); // Register the aliased ChartTooltip

const stageOrder = {
  "Approval-OPA": 1,
  "Approval-ZBA": 2,
  "Approval-SPA-DPA": 3,
  "Servicing": 4,
  "Development Permit": 5,
  "Presales": 6,
  "Building Permit": 7,
  "Construction": 8,
  "Occupancy": 9,
  "Operational": 10,
};

const stageNames = Object.keys(stageOrder);

const periwinkle = '#CCCCFF';
const mediumPurple = '#9370DB';
const orchid = '#DA70D6';

const CapitalAd = ({ data, capitalAdequacyData }) => {
  const mergedData = data.map(projectData => {
    const capitalData = capitalAdequacyData.find(
      capital => capital.project === projectData.project
    );
    return {
      ...projectData,
      capital_adequacy: capitalData ? capitalData.capital_adequacy : null,
      shortage: capitalData ? capitalData.shortage : null, // Add shortage info
    };
  });

  const processData = () => {
    return mergedData.map(({ project, current_credit_coverage, final_closing_stage, capital_adequacy, shortage }) => {
      const totalStages = stageOrder[final_closing_stage];
      const filledStages = stageOrder[current_credit_coverage];
      const segments = Array(totalStages).fill(1);
      const backgroundColor = segments.map((_, i) =>
        i < filledStages ? `${periwinkle}` : `${periwinkle}2A`
      );

      return {
        project, // Include project info for tooltips
        shortage, // Include shortage info for tooltips
        capital_adequacy, // Include capital adequacy in chart data
        labels: stageNames.slice(0, totalStages),
        datasets: [
          {
            data: segments,
            backgroundColor: backgroundColor,
            borderWidth: 1,
            borderColor: orchid,
            borderRadius: 20,
            hoverOffset: 6,
          },
        ],
      };
    });
  };

  const processedData = processData();
  const isOdd = processedData.length % 2 !== 0;

  return (
    <div style={{ 
      display: 'flex', 
      flexWrap: 'wrap', 
      justifyContent: isOdd ? 'center' : 'space-between', 
      alignItems: 'center', 
      width: '44vw', 
      height: '25vh', 
      overflow: 'hidden' 
    }}>
      {processedData.map((chartData, index) => (
        <div 
          key={index} 
          style={{ 
            margin: '5px', 
            width: 'calc(50% - 10px)', 
            height: 'calc(50% - 10px)', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            position: 'relative'
          }}
        >
          <div style={{ width: '100%', height: '100%' }}>
          <Doughnut 
              data={chartData} 
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    backgroundColor: '#ccc8f6', // Set the background color to #ccc8f6
                    titleColor: '#180d2b',      // Set the title text color to #180d2b
                    bodyColor: '#180d2b',       // Set the body text color to #180d2b
                    titleFont: {
                      family: 'Lato',           // Set the font to Lato
                      size: 14,                 // You can adjust the size
                      weight: 'normal',
                    },
                    bodyFont: {
                      family: 'Lato',           // Set the font to Lato
                      size: 12,                 // You can adjust the size
                      weight: 'normal',
                    },
                    callbacks: {
                      label: function () {
                        return ''; // Custom label content
                      },
                    },
                  },
                },
                maintainAspectRatio: false,
                responsive: true,
                cutout: '70%',
                rotation: 0,
                circumference: 360,
              }} 
            />
            {/* Tooltip around capital adequacy percentage */}
            <Tooltip
                title={
                  <TooltipComponent
                    fields={[
                      { value: `${chartData.project}` },
                      { 
                        value: chartData.shortage 
                          ? `$${parseFloat(chartData.shortage).toLocaleString('en-US')}` 
                          : 'N/A' 
                      },
                    ]}
                    icons={[
                      <img src={ProjectIcon} className="w-4 h-4 mr-1" alt="Project Icon" />,  
                      <img src={ShortageIcon} className="w-6 h-6 mr-1" alt="Shortage Icon" />,  
                    ]}
                  />
                }
                placement="bottom"
                arrow
              >
              {/* Capital adequacy percentage in the middle of the donut chart */}
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  font: 'lato',
                  fontSize: '0.6rem',
                  fontWeight: 'thin',
                  textShadow: '0 0 5px rgba(0, 153, 255, 0.5), 0 0 10px rgba(0, 153, 255, 0.5), 0 0 15px rgba(0, 153, 255, 0.5)', 
                  color: periwinkle,
                  cursor: 'pointer',
                }}
              >
                {chartData.capital_adequacy && !isNaN(parseFloat(chartData.capital_adequacy)) 
                  ? `${parseFloat(chartData.capital_adequacy).toFixed(2)}%` 
                  : 'N/A'}
              </div>
            </Tooltip>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CapitalAd;
