import axios from 'axios'
import { toast } from 'sonner'

const baseURL = 'https://alphamadesimple.ca'

const refresh_token = sessionStorage.getItem('WEB_APP_REFRESH_TOKEN')

export const refreshToken = async () => {
  try {
    const { data } = await axios({
      method: 'post',
      url: `${baseURL}/users/refresh`,
      data: {
        refresh_token,
      },
    })
    return data
  } catch (error) {
    toast.error(error?.message ?? 'Access expired, kindly log in')
    window.location.replace('/')
  }
}

async function makeApiCall(url, method = 'get', payload, axiosRequestConfig) {
  const token = sessionStorage.getItem('WEB_APP_TOKEN')
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  }
  try {
    if (!baseURL || typeof baseURL !== 'string') {
      throw new Error('API_BASEURL is not defined')
    }
    const { data } = await axios({
      url,
      method,
      data: payload,
      baseURL,
      ...axiosRequestConfig,
    })
    return data
  } catch (error) {
    if (error.response) {
      if (error.response.status === 403) {
        const response = await refreshToken()

        sessionStorage.setItem('WEB_APP_TOKEN', response.access_token)
        if (response) {
          await makeApiCall(url, method, payload, axiosRequestConfig)
          window.location.reload()
        }
      } else {
        const serverMessage =
          error.response.status === 500 || error.response.status === 503
            ? 'Something went wrong on our end, please try again later - if issue persists, please contact treasucre admin'
            : error.response?.data?.detail || 'An error occurred, please try again'

        throw new Error(serverMessage)
      }
    } else {
      throw new Error(error.message ?? 'An error occurred, please try again')
    }
    // use the server error response if available
  }
}

export default makeApiCall
