import {} from 'lib/api/liquidity'
import {
  fetchCashflowForecast,
  fetchFinancialRatios,
  fetchVarianceAnalysis,
} from 'lib/api/solvency'
import { useQuery } from 'react-query'

export function useCashflowForecast({ developer }) {
  const query = useQuery(['cash-flow-forecast', developer], () =>
    fetchCashflowForecast({ developer })
  )

  return query
}

export function useVarianceAnalysis({ developer }) {
  const query = useQuery(['variance-analysis', developer], () =>
    fetchVarianceAnalysis({ developer })
  )

  return query
}

export function useFinancialRatios({ developer }) {
  const query = useQuery(['financial-ratios', developer], () => fetchFinancialRatios({ developer }))

  return query
}
