import makeApiCall from '.'

export async function addAccountInfo({ developerName, payload }) {
  const { data } = await makeApiCall('/e-creprojects/bank-accounts/', 'post', payload, {
    baseURL: `https://${developerName}.alphamadesimple.ca`,
  })

  return data
}

export async function editAccountInfo({ developerName, payload, id }) {
  const { data } = await makeApiCall(`/e-creprojects/bank-accounts/${id}/`, 'patch', payload, {
    baseURL: `https://${developerName}.alphamadesimple.ca`,
  })

  return data
}

export async function fetchBankAccounts({ developerName, project }) {
  const data = await makeApiCall(
    `/e-creprojects/bank-account?project=${project}`,
    'get',
    undefined,
    {
      baseURL: `https://${developerName}.alphamadesimple.ca`,
    }
  )

  return data
}

export async function fetchBankAccount({ developerName, id }) {
  const data = await makeApiCall(`/e-creprojects/bank-accounts/${id}`, 'get', undefined, {
    baseURL: `https://${developerName}.alphamadesimple.ca`,
  })

  return data
}

export async function fetchAllBankAccounts({ developerName }) {
  const data = await makeApiCall('/e-creprojects/bank-accounts', 'get', undefined, {
    baseURL: `https://${developerName}.alphamadesimple.ca`,
  })

  return data
}
