import { fetchClientInformation } from 'lib/api/client'
import { useQuery } from 'react-query'

export function useClientInformation({ developer }) {
  const query = useQuery(['client-information', developer], () =>
    fetchClientInformation({ developer })
  )

  return query
}
