import makeApiCall from '.'

export async function uploadActivityFile({ developerName, payload }) {
  return await makeApiCall('/d-capital/upload-activity-csv/', 'post', payload, {
    baseURL: `https://${developerName}.alphamadesimple.ca`,
  })
}

export async function uploadSalesSummary({ developerName, payload }) {
  return await makeApiCall('/d-capital/upload-sales-summary-csv/', 'post', payload, {
    baseURL: `https://${developerName}.alphamadesimple.ca`,
  })
}

export async function uploadFinancingSchedule({ developerName, payload }) {
  return await makeApiCall('/d-capital/upload-financing-schedule-csv/', 'post', payload, {
    baseURL: `https://${developerName}.alphamadesimple.ca`,
  })
}

export async function saveCostVariation({ developerName, payload }) {
  return await makeApiCall('/d-capital/cost-variation/', 'post', payload, {
    baseURL: `https://${developerName}.alphamadesimple.ca`,
  })
}

export async function saveTimeVariation({ developerName, payload }) {
  return await makeApiCall('/d-capital/time-variation/', 'post', payload, {
    baseURL: `https://${developerName}.alphamadesimple.ca`,
  })
}

export async function saveRevenueVariation({ developerName, payload }) {
  return await makeApiCall('/d-capital/revenue-variation/', 'post', payload, {
    baseURL: `https://${developerName}.alphamadesimple.ca`,
  })
}

export async function saveCapitalSources({ developerName, payload }) {
  const { data } = await makeApiCall('/e-creprojects/capitalsources/', 'post', payload, {
    baseURL: `https://${developerName}.alphamadesimple.ca`,
  })

  return data
}

export async function fetchCapitalSources({ developer, project }) {
  const data = await makeApiCall(
    `/e-creprojects/capitalsource?project=${project}`,
    'get',
    undefined,
    {
      baseURL: `https://${developer}.alphamadesimple.ca`,
    }
  )

  return data
}

export async function fetchCapitalSource({ developer, id }) {
  const data = await makeApiCall(`/e-creprojects/capitalsources/${id}/`, 'get', undefined, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })

  return data
}

export async function editCapitalSource({ developerName, payload, id }) {
  const { data } = await makeApiCall(`/e-creprojects/capitalsources/${id}/`, 'patch', payload, {
    baseURL: `https://${developerName}.alphamadesimple.ca`,
  })

  return data
}

export async function fetchLoanToValue({ developer }) {
  const data = await makeApiCall(`/d-capital/project-loan-to-value-tracker/`, 'get', undefined, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })

  return data
}

export async function fetchCapitalAdequacy({ developer }) {
  const data = await makeApiCall(`/d-capital/capital-adequacy/`, 'get', undefined, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })

  return data
}

export async function fetchScenarioCapitalAdequacy({ developer }) {
  const data = await makeApiCall(`/d-capital/scenario-capital-adequacy/`, 'get', undefined, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })

  return data
}

export async function fetchScheduleFeasibility({ developer }) {
  const data = await makeApiCall(`/d-capital/schedule-feasibility/`, 'get', undefined, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })

  return data
}

export async function fetchScenarioScheduleFeasibility({ developer }) {
  const data = await makeApiCall(`/d-capital/scenario-schedule-feasibility/`, 'get', undefined, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })

  return data
}

export async function fetchAnnualizedRoi({ developer }) {
  const data = await makeApiCall(`/d-capital/annualized-return-on-investment/`, 'get', undefined, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })

  return data
}

export async function fetchScenarioAnnualizedRoi({ developer }) {
  const data = await makeApiCall(`/d-capital/scenario-annualized-roi/`, 'get', undefined, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })

  return data
}
