import Button from 'components/button'
import { TextField } from 'components/form/input'
import { IoChevronBackCircleOutline } from 'react-icons/io5'
import React from 'react'
import { Formik } from 'formik'
import yup from 'lib/yup'
import { toast } from 'sonner'
import { editClient, onboardClient } from 'lib/api/client'
import { useClientInformation } from 'data/client'
import { CircularProgress } from '@mui/material'
import { useAppContext } from 'context'
import { useNavigate } from 'react-router-dom'

const validationSchema = yup.object({
  developer: yup.string().required('Enter developer name'),
  unit_number: yup.string().max(10, "Your unit number doesn't meet the requirements"),
  street_number: yup.string().required('Enter your street number'),
  street_name: yup.string().required('Enter your street name'),
  city: yup.string().required('Enter your city'),
  province: yup.string().required('Enter your province'),
  postal_code: yup.string().required('Enter your postal code'),
  contact_person: yup.string().required('Enter your contact person'),
  contact_person_phone: yup.string().required('Enter contact person phone number'),
  target_interest_rate: yup.string().required('Enter target interest rate'),
  target_loan_to_value: yup.string().required('Enter target loan to value'),
  contact_person_email: yup
    .string()
    .email('Enter a valid email address')
    .required('Enter email address'),
})

export default function ClientOnboardingForm() {
  const { developer: clientOrgName } = useAppContext()
  const navigate = useNavigate()
  const {
    data: clientInformation,
    isLoading,
    isError,
    refetch: refetchClientInformation,
  } = useClientInformation({ developer: clientOrgName.split(' ')?.[0] })

  const developer = clientInformation?.length ? clientInformation[0] : null

  const formDefaultValues = {
    developer: developer?.developer ?? '',
    unit_number: developer?.unit_number ?? '',
    street_number: developer?.street_number ?? '',
    street_name: developer?.street_name ?? '',
    city: developer?.city ?? '',
    province: developer?.province ?? '',
    postal_code: developer?.postal_code ?? '',
    contact_person: developer?.contact_person ?? '',
    contact_person_email: developer?.contact_person_email ?? '',
    contact_person_phone: developer?.contact_person_phone ?? '',
    target_interest_rate: developer?.target_interest_rate ?? '',
    target_loan_to_value: developer?.target_loan_to_value ?? '',
  }

  if (isError) {
    toast.error('An error occurred while fetching client information')
  }

  return (
    <div className=" glass-card relative w-[85%] shadow-lg backdrop-blur-lg bg-lavender/5        relative border-r-2 border-b-2 border-white/30 mt-24  min-h-[65vh] max-h-[76vh]  overflow-y-auto overflow-x-auto 2xl:overflow-x-hidden rounded-[40px] p-4 2xl:py-8 2xl:px-16 justify-center justify-items-center">
      <div className="w-full text-center pt-3">
        <div className="capitalButton absolute left-0 w-2/12 ">
          <button onClick={() => navigate('/client-data-form')}>
            <IoChevronBackCircleOutline size={40} color="#ccc8f6" />
          </button>
        </div>
        <span className="bg-gradient-to-b from-[#da70d6] via-[#ccccff] to-[#e6e6fa] font-lato text-4xl text-transparent bg-clip-text">
          Onboarding Form
        </span>
      </div>
      {isLoading ? (
        <div className=" grid place-items-center mt-36">
          <CircularProgress color="inherit" className="text-customLightBlue1" size={30} />
        </div>
      ) : (
        <div className="  mt-8">
          <Formik
            initialValues={formDefaultValues}
            onSubmit={
              developer
                ? async (values) => {
                    try {
                      await editClient({
                        developer: clientOrgName.split(' ')?.[0],
                        payload: values,
                        id: developer.id,
                      })
                      toast.success('Client information successfully edited')
                      refetchClientInformation()
                    } catch (error) {
                      toast.error(
                        error.message ??
                          error.detail ??
                          'An error occurred while editing client information'
                      )
                    }
                  }
                : async (values) => {
                    try {
                      await onboardClient({
                        developer: clientOrgName.split(' ')?.[0],
                        payload: values,
                      })
                      toast.success('Client successfully onboarded')
                      refetchClientInformation()
                    } catch (error) {
                      toast.error(error.message ?? 'An error occurred while onboarding client')
                    }
                  }
            }
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              handleSubmit,
              isSubmitting,
            }) => (
              <section>
                <form className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-8 pt-6 place-items-center">
                  <div className=" gap-x-16 grid grid-cols-[140px_auto] items-center">
                    <TextField
                      label="Developer Name"
                      maxLength={50}
                      type="text"
                      id="developer"
                      name="developer"
                      value={values.developer}
                      error={touched.developer && !!errors.developer}
                      helperText={touched.developer ? errors.developer : ''}
                      onChange={handleChange}
                    />
                  </div>
                  <div className=" gap-x-16  grid grid-cols-[140px_auto] items-center">
                    <label
                      className=" flex font-semibold text-customLightBlue1 font-lato align-baseline"
                      // for="province"
                    >
                      Province
                    </label>
                    <select
                      className="flex  w-[11rem] xl:w-[13rem] 2xl:w-[16rem] h-10   text-bca-grey-9 focus:border-bca-success-6 focus:outline-none text-sm border-0 bg-lavender font-lato border-[#42644C]  rounded-full text-center"
                      name="province"
                      id="province"
                      value={values.province}
                      error={touched.province && !!errors.province}
                      onChange={handleChange}
                    >
                      <option value="" selected disabled>
                        Select
                      </option>
                      <option value="ON">ON</option>
                      <option value="QC">QC</option>
                      <option value="NS">NS</option>
                      <option value="NB">NB</option>
                      <option value="MB">MB</option>
                      <option value="BC">BC</option>
                      <option value="PE">PE</option>
                      <option value="SK">SK</option>
                      <option value="AB">AB</option>
                      <option value="NL">NL</option>
                    </select>
                    <p className="text-red-700 whitespace-nowrap">
                      {touched.province ? errors.province : ''}
                    </p>
                  </div>
                  <div className=" gap-x-16  grid grid-cols-[140px_auto] items-center">
                    <TextField
                      label="Unit Number"
                      type="text"
                      id="unit_number"
                      maxLength={10}
                      name="unit_number"
                      value={values.unit_number}
                      error={touched.unit_number && !!errors.unit_number}
                      helperText={touched.unit_number ? errors.unit_number : ''}
                      onChange={handleChange}
                    />
                  </div>

                  <div className=" gap-x-16  grid grid-cols-[140px_auto] items-center">
                    <TextField
                      label=" Postal Code"
                      type="text"
                      id="postal_code"
                      name="postal_code"
                      value={values.postal_code}
                      error={touched.postal_code && !!errors.postal_code}
                      helperText={touched.postal_code ? errors.postal_code : ''}
                      onChange={handleChange}
                      maxLength={20}
                    />
                  </div>

                  <div className=" gap-x-16  grid grid-cols-[140px_auto] items-center">
                    <TextField
                      label=" Street Number"
                      type="text"
                      id="street_number"
                      name="street_number"
                      value={values.street_number}
                      error={touched.street_number && !!errors.street_number}
                      helperText={touched.street_number ? errors.street_number : ''}
                      onChange={handleChange}
                      maxLength={10}
                    />
                  </div>

                  <div className=" gap-x-16  grid grid-cols-[140px_auto] items-center">
                    <TextField
                      label="Contact Person"
                      type="text"
                      id="contact_person"
                      name="contact_person"
                      value={values.contact_person}
                      error={touched.contact_person && !!errors.contact_person}
                      helperText={touched.contact_person ? errors.contact_person : ''}
                      onChange={handleChange}
                    />
                  </div>

                  <div className=" gap-x-16  grid grid-cols-[140px_auto] items-center">
                    <TextField
                      label="Street Name"
                      maxLength={50}
                      type="text"
                      id="street_name"
                      name="street_name"
                      value={values.street_name}
                      error={touched.street_name && !!errors.street_name}
                      helperText={touched.street_name ? errors.street_name : ''}
                      onChange={handleChange}
                    />
                  </div>

                  <div className=" gap-x-16 whitespace-nowrap  grid grid-cols-[140px_auto] items-center">
                    <TextField
                      label="  Contact Person Email"
                      type="text"
                      id="contact_person_email"
                      name="contact_person_email"
                      value={values.contact_person_email}
                      error={touched.contact_person_email && !!errors.contact_person_email}
                      helperText={touched.contact_person_email ? errors.contact_person_email : ''}
                      onChange={handleChange}
                    />
                  </div>

                  <div className=" gap-x-16  grid grid-cols-[140px_auto] items-center">
                    <TextField
                      label="City"
                      type="text"
                      id="city"
                      name="city"
                      value={values.city}
                      error={touched.city && !!errors.city}
                      helperText={touched.city ? errors.city : ''}
                      onChange={handleChange}
                    />
                  </div>

                  <div className=" gap-x-16 whitespace-nowrap  grid grid-cols-[140px_auto] items-center">
                    <TextField
                      label="Contact Person Phone"
                      type="text"
                      id="contact_person_phone"
                      name="contact_person_phone"
                      value={values.contact_person_phone}
                      error={touched.contact_person_phone && !!errors.contact_person_phone}
                      helperText={touched.contact_person_phone ? errors.contact_person_phone : ''}
                      onChange={handleChange}
                    />
                  </div>

                  <div className=" gap-x-16 whitespace-nowrap  grid grid-cols-[140px_auto] items-center">
                    <TextField
                      label="Target Interest Rate"
                      type="text"
                      maxLength={5}
                      id="target_interest_rate"
                      name="target_interest_rate"
                      value={values.target_interest_rate}
                      error={touched.target_interest_rate && !!errors.target_interest_rate}
                      helperText={touched.target_interest_rate ? errors.target_interest_rate : ''}
                      onChange={(event) => {
                        const value = event.target.value
                        if (!value.match(/[a-zA-Z?#@$?"':,;-]/)) {
                          setFieldValue('target_interest_rate', value)
                        }
                      }}
                    />
                  </div>
                  <div className=" gap-x-16  grid grid-cols-[140px_auto] items-center">
                  <TextField
                      label=" Target L.T.V."
                      type="text"
                      maxLength={5}
                      id="target_loan_to_value"
                      name="target_loan_to_value"
                      value={values.target_loan_to_value}
                      error={touched.target_loan_to_value && !!errors.target_loan_to_value}
                      helperText={touched.target_loan_to_value ? errors.target_loan_to_value : ''}
                      onChange={(event) => {
                        const value = event.target.value
                        if (!value.match(/[a-zA-Z?#@$?"',:;-]/)) {
                          setFieldValue('target_loan_to_value', value)
                        }
                      }}
                    /> 
                  </div>
                </form>
                <Button
                  title={developer ? 'Edit' : 'Onboard'}
                  type="submit"
                  className="h-16 mt-10 mb-5 w-[120px]  mx-auto"
                  isSubmitting={isSubmitting}
                  onClick={handleSubmit}
                />
              </section>
            )}
          </Formik>
        </div>
      )}
    </div>
  )
}
