import Button from 'components/button'
import { TextField } from 'components/form/input'
import { IoChevronBackCircleOutline } from 'react-icons/io5'
import React from 'react'
import yup from 'lib/yup'
import { toast } from 'sonner'
import { useAppContext } from 'context'
import { Formik } from 'formik'
import { CircularProgress } from '@mui/material'
import { editAccountInfo } from 'lib/api/account'
import { useNavigate, useParams } from 'react-router-dom'
import { useBankAccount } from 'data/account'
import { formatAmount } from 'lib/utils'

const validationSchema = yup.object({
  account_type: yup.string().required('Select account type'),
  institution_name: yup.string().required('This is required'),
  account_ending: yup.string().required('This is required'),
  currency: yup.string().required('Select currency type'),
  limit: yup.string().required('This is required'),
  opening_balance: yup.string().required('This is required'),
  payment_day: yup.number().required('This is required'),
  legal_entity: yup.string().required('This is required'),
})

export default function EditAccountForm() {
  const { developer } = useAppContext()
  const navigate = useNavigate()
  const { id } = useParams()

  const {
    data: account,
    isLoading: isLoadingAccount,
    isError: isErrorFetchingAccount,
    refetch: refetchAccount,
  } = useBankAccount({ developerName: developer.split(' ')?.[0], id })

  if (isErrorFetchingAccount) {
    toast.error('An error occurred while fetching your account information')
  }

  const formDefaultValues = {
    account_type: account?.account_type,
    institution_name: account?.institution_name,
    account_ending: account?.account_ending,
    currency: account?.currency,
    limit: account?.limit,
    opening_balance: account?.opening_balance,
    legal_entity: account?.legal_entity,
    payment_day: account?.payment_day,
  }

  return (
    <div className="glass-card relative w-[80%] shadow-lg backdrop-blur-lg bg-lavender/5        relative border-r-2 border-b-2 border-white/30  min-h-[65vh] max-h-[76vh] mt-24 overflow-y-auto overflow-x-hidden rounded-[40px] px-16 py-8 justify-center justify-items-center">
      <div className="w-full text-center">
        <div className="capitalButton absolute left-0 w-2/12 ">
          <button onClick={() => navigate(-1)}>
            <IoChevronBackCircleOutline size={40} color="#ccc8f6" />
          </button>
        </div>
        <span className="bg-gradient-to-b from-[#da70d6] via-[#ccccff] to-[#e6e6fa] font-lato text-4xl text-transparent bg-clip-text   ">
          {account?.project} Account
        </span>
      </div>
      {isLoadingAccount ? (
        <div className=" grid place-items-center mt-36">
          <CircularProgress color="inherit" className="text-customLightBlue1" size={30} />
        </div>
      ) : (
        account && (
          <Formik
            initialValues={formDefaultValues}
            onSubmit={async (values) => {
              try {
                values.limit = formatAmount(values.limit)
                values.opening_balance = formatAmount(values.opening_balance)
                const payload = {
                  ...values,
                  developer,
                  project: account?.project,
                }
                await editAccountInfo({
                  developerName: developer.split(' ')?.[0],
                  payload,
                  id: account?.id,
                })
                toast.success('Account Information successfully edited')
                refetchAccount()
                navigate(`/account?project=${account?.project.replace(/\s+/g, '-')}`)
              } catch (error) {
                toast.error(error.message ?? 'An error occurred while editing  account information')
              }
            }}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <div className="mt-6">
                <form className="graph-title grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-8 pt-6 place-items-center">
                  <div className="gap-x-8 xl:gap-x-10 2xl:gap-x-16  grid grid-cols-[120px_auto]  xl:grid-cols-[140px_auto]  items-center">
                    <label
                      className=" flex font-semibold text-customLightBlue1 font-lato align-baseline"
                      for="account-type"
                    >
                      Account Type
                    </label>
                    <select
                      className="flex w-[10rem] px-5  xl:w-[13rem] 2xl:w-[16rem] h-10  text-bca-grey-9 focus:border-bca-success-6 focus:outline-none text-sm border-0 bg-lavender font-lato border-[#42644C]  rounded-full "
                      name="account_type"
                      id="account_type"
                      value={values.account_type}
                      onChange={(e) => {
                        setFieldValue('account_type', e.target.value)
                      }}
                    >
                      <option value="" disabled selected>
                        Select
                      </option>
                      <option value="Credit">Credit</option>
                      <option value="Chequing">Chequing</option>
                      <option value="Savings">Savings</option>
                    </select>
                  </div>
                  <div className=" gap-x-8  xl:gap-x-10 2xl:gap-x-16  grid grid-cols-[120px_auto]  xl:grid-cols-[140px_auto]  items-center">
                    <label
                      className=" flex font-semibold text-customLightBlue1 font-lato align-baseline"
                      for="currency"
                    >
                      Currency
                    </label>
                    <select
                      className="flex w-[10rem] px-5 xl:w-[13rem] 2xl:w-[16rem] h-10  text-bca-grey-9 focus:border-bca-success-6 focus:outline-none text-sm border-0 bg-lavender font-lato border-[#42644C]  rounded-full "
                      name="currency"
                      id="currency"
                      value={values.currency}
                      onChange={(e) => {
                        setFieldValue('currency', e.target.value)
                      }}
                    >
                      <option value="" disabled selected>
                        Select
                      </option>
                      <option value="CAD">Canadian Dollar</option>
                      <option value="EUR">EURO</option>
                      <option value="GBP">Great British Pound</option>
                      <option value="USD">United States Dollar</option>
                    </select>
                  </div>
                  <div className=" gap-x-8 xl:gap-x-10 2xl:gap-x-16  grid grid-cols-[120px_auto]  xl:grid-cols-[140px_auto]   items-center">
                    <TextField
                      label="Institution Name"
                      type="text"
                      id="institution_name"
                      name="institution_name"
                      value={values.institution_name}
                      error={touched.institution_name && !!errors.institution_name}
                      helperText={touched.institution_name ? errors.institution_name : ''}
                      onChange={handleChange}
                    />
                  </div>
                  <div className=" gap-x-8 xl:gap-x-10 2xl:gap-x-16  grid grid-cols-[120px_auto]  xl:grid-cols-[140px_auto]  items-center">
                    <TextField
                      label="Limit"
                      type="text"
                      id="limit"
                      name="limit"
                      value={values.limit}
                      error={touched.limit && !!errors.limit}
                      helperText={touched.limit ? errors.limit : ''}
                      onChange={handleChange}
                    />
                  </div>
                  <div className=" gap-x-8 xl:gap-x-10 2xl:gap-x-16  grid grid-cols-[120px_auto]  xl:grid-cols-[140px_auto]  items-center">
                    <TextField
                      label="Account Ending"
                      maxLength={4}
                      type="text"
                      id="account_ending"
                      name="account_ending"
                      value={values.account_ending}
                      error={touched.account_ending && !!errors.account_ending}
                      helperText={touched.account_ending ? errors.account_ending : ''}
                      onChange={handleChange}
                    />
                  </div>
                  <div className=" gap-x-8 xl:gap-x-10 2xl:gap-x-16  grid grid-cols-[120px_auto]  xl:grid-cols-[140px_auto]   items-center">
                    <TextField
                      label="Legal entity"
                      type="text"
                      id="legal_entity"
                      name="legal_entity"
                      value={values.legal_entity}
                      error={touched.legal_entity && !!errors.legal_entity}
                      helperText={touched.legal_entity ? errors.legal_entity : ''}
                      onChange={handleChange}
                    />
                  </div>
                  <div className=" gap-x-8 xl:gap-x-10 2xl:gap-x-16  grid grid-cols-[120px_auto]  xl:grid-cols-[140px_auto]  items-center">
                    <TextField
                      label="Payment Day"
                      type="number"
                      id="payment_day"
                      name="payment_day"
                      value={values.payment_day}
                      error={touched.payment_day && !!errors.payment_day}
                      helperText={touched.payment_day ? errors.payment_day : ''}
                      onChange={handleChange}
                    />
                  </div>
                  <div className=" gap-x-8 xl:gap-x-10 2xl:gap-x-16  grid grid-cols-[120px_auto]  xl:grid-cols-[140px_auto]   items-center">
                    <TextField
                      label="Opening Balance"
                      type="text"
                      id="opening_balance"
                      name="opening_balance"
                      value={values.opening_balance}
                      error={touched.opening_balance && !!errors.opening_balance}
                      helperText={touched.opening_balance ? errors.opening_balance : ''}
                      onChange={handleChange}
                    />
                  </div>
                </form>
                <Button
                  title="Save"
                  type="submit"
                  isSubmitting={isSubmitting}
                  onClick={handleSubmit}
                  className="h-16 w-[120px] mt-12 2xl:mt-20 mx-auto"
                />
              </div>
            )}
          </Formik>
        )
      )}
    </div>
  )
}
