import { twMerge } from 'tailwind-merge' // Importing 'twMerge' to merge Tailwind CSS classes intelligently without overriding styles.

export const Input = ({ label, disabled, error, ref, className, placeholder, ...rest }) => {
  // Input is a functional component that takes in:
  // label: The label for the input field.
  // disabled: A boolean to control if the input field is disabled.
  // error: A string containing an error message.
  // ref: A reference to the input element for programmatic access.
  // className: Additional classes that can be merged with default ones.
  // placeholder: Placeholder text for the input field.
  // ...rest: Any additional props passed to the input element.

  return (
    <div className="relative grid grid-cols-1 gap-y-1">
      {/* Container with a single-column grid and gap between rows. */}
      
      {label && (
        <label
          // If the label prop is provided, display the label element.
          for={label?.toLocaleLowerCase().replace(/ /g, '_')}
          className="text-sm font-semibold text-customLightBlue1 font-lato"
        >
          {label} {/* Render the label text */}
        </label>
      )}

      <input
        disabled={disabled} // Conditionally disable the input field.
        className={twMerge(
          // Merge the provided className prop with the default classes using twMerge.
          'focus-visible: h-[4rem] rounded-full border border-bca-grey-2 px-8 bg-lavender text-customDarkBlue2 focus:border-bca-success-6 focus:outline-none disabled:opacity-50',
          className
        )}
        placeholder={placeholder} // Placeholder text inside the input.
        ref={ref} // Reference to the input element.
        {...rest} // Spread any additional props passed to the input field.
      />

      {error && <p className="text-xs text-red-700">{error}</p>}
      {/* Display error message below the input field if provided. */}
    </div>
  )
}

export const TextField = ({ label, id, disabled, className, placeholder, helperText, ...rest }) => {
  // TextField is a component that renders a label, an input field, and helper text.
  // It takes similar props as Input, plus helperText for additional information below the field.
  
  return (
    <>
      {label && (
        <label
          // If the label prop is provided, display the label element.
          className="flex font-semibold text-customLightBlue1 font-lato align-baseline w-36"
          for={id}
        >
          {label} {/* Render the label text */}
        </label>
      )}

      <input
        disabled={disabled} // Conditionally disable the input field.
        className={twMerge(
          // Merge the provided className prop with default classes for styling.
          'focus-visible: h-10 w-[10rem] xl:w-[13rem] 2xl:w-[16rem] text-center text-sm bg-lavender border-0 font-lato border-[#42644C] rounded-full px-8 text-customDarkBlue2 focus:border-bca-success-6 focus:outline-none disabled:opacity-50',
          className
        )}
        placeholder={placeholder} // Placeholder text inside the input field.
        {...rest} // Spread any additional props passed to the input field.
      />

      <p className="text-red-700 whitespace-nowrap">{helperText}</p>
      {/* Display the helper text below the input field. The text is in red and does not wrap onto a new line. */}
    </>
  )
}

export const Select = ({ label, id, disabled, className, placeholder, helperText, ...rest }) => {
  // Select is a component that renders a label, a select dropdown, and helper text.
  // It works similarly to TextField but uses a <select> element instead of <input>.
  
  return (
    <>
      {label && (
        <label
          // If the label prop is provided, display the label element.
          className="flex font-semibold text-customLightBlue1 font-lato align-baseline w-36"
          for={id}
        >
          {label} {/* Render the label text */}
        </label>
      )}

      <select
        disabled={disabled} // Conditionally disable the select dropdown.
        className={twMerge(
          // Merge the provided className prop with default classes for styling.
          'focus-visible: h-10 w-[10rem] xl:w-[13rem] 2xl:w-[16rem] text-sm bg-lavender border-0 font-lato border-[#42644C] rounded-full px-8 text-customDarkBlue2 focus:border-bca-success-6 focus:outline-none disabled:opacity-50',
          className
        )}
        placeholder={placeholder} // Placeholder text for the select dropdown.
        {...rest} // Spread any additional props passed to the select element.
      />

      <p className="text-red-700 whitespace-nowrap">{helperText}</p>
      {/* Display the helper text below the select dropdown. The text is in red and does not wrap onto a new line. */}
    </>
  )
}
