import AllRoutes from 'routes'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Toaster } from 'sonner'
import './App.css';


const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Toaster richColors position="top-center" />
      <AllRoutes />
    </QueryClientProvider>
  )
}

export default App
