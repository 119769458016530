import React from 'react';

const TooltipComponent = ({ fields, icons = [] }) => {
  return (
    <div className=" position-absolute p-4 rounded-2xl bg-gradient-to-r from-customLightBlue1 to-white border-r-2 border-b-2 border-customMidBlue3 shadow-lg bg-opacity-90 transform transition duration-200 ease-in-out">
      <table className="table-auto text-customDarkBlue2 font-lato text-base font-thin">
        <tbody>
          {fields.map((field, index) => (
            <tr key={index}>
              <td className="pr-2 font-bold border-r-2 border-customMidBlue3">
                {icons[index] && (
                  <span className="inline-flex items-center">
                    {icons[index]} {/* Render icon if provided */}
                  </span>
                )}
              </td>
              <td className="pl-2">{field.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TooltipComponent;
