import { EditFileButton, UploadFileButton } from 'components/button/data-entry'
import { useState } from 'react'
import { LuUpload } from 'react-icons/lu'
import { toast } from 'sonner'
import { uploadBankStatement, uploadLiquidityForcast } from 'lib/api/liquidity'
import { useAppContext } from 'context'
import { BiSolidEdit } from 'react-icons/bi'
import Button from 'components/button'
import { CircularProgress } from '@mui/material'
import { useFirmwideFilesUploadStatus } from 'data/project'
import { useBankAccounts } from 'data/account'

export default function FirmwideDataForm() {
  const { developer: clientOrgName } = useAppContext()
  const [fileDetails, setFileDetails] = useState([])
  const [statementFileDetails, setStatementFileDetails] = useState([])
  const [isSubmittingForecast, setIsSubmittingForecast] = useState(false)
  const [isSubmittingStatement, setIsSubmittingStatement] = useState(false)

  const {
    data: filesUploadStatus,
    isLoading: isLoadingFileStatus,
    isError: isErrorFetchingUploadStatus,
    refetch: refetchFileStatus,
  } = useFirmwideFilesUploadStatus({
    developer: clientOrgName.split(' ')?.[0],
  })

  if (isErrorFetchingUploadStatus) {
    toast.error('Error fetching files upload status')
  }

  const {
    data: accounts,
    isLoading: isLoadingAccounts,
    isError: isErrorFetchingAccounts,
  } = useBankAccounts({ developerName: clientOrgName.split(' ')?.[0], project: 'Firmwide' })

  if (isErrorFetchingAccounts) {
    toast.error('Error fetching bank account')
  }

  const uploadForecast = async () => {
    for (let i = 0; i < fileDetails.length; i++) {
      try {
        setIsSubmittingForecast(true)
        const formData = new FormData()
        formData.append('file', fileDetails[i].file)
        formData.append('developer', clientOrgName)
        const result = await uploadLiquidityForcast({
          developerName: clientOrgName.split(' ')?.[0],
          payload: formData,
        })
        refetchFileStatus()
        toast.success(`${fileDetails[i]?.fileTitle} : ${result.status}`)
      } catch (error) {
        toast.error(`${fileDetails[i]?.fileTitle} : ${error.message}` ?? 'An error occurred')
      } finally {
        setIsSubmittingForecast(false)
      }
    }
  }

  const uploadStatement = async () => {
    for (let i = 0; i < statementFileDetails.length; i++) {
      try {
        setIsSubmittingStatement(true)
        const formData = new FormData()
        formData.append('file', statementFileDetails[i].file)
        formData.append('developer', clientOrgName)
        const result = await uploadBankStatement({
          developerName: clientOrgName.split(' ')?.[0],
          payload: formData,
        })
        refetchFileStatus()
        toast.success(`${statementFileDetails[i]?.fileTitle} bank statement : ${result.status}`)
      } catch (error) {
        toast.error(
          `${statementFileDetails[i]?.fileTitle} bank statement : ${error.message}` ??
            'An error occurred'
        )
      } finally {
        setIsSubmittingStatement(false)
      }
    }
  }

  const handleFileUpload = async (e, fileTitle) => {
    if (!e.target.files || !e.target.files[0]) {
      toast.error('No file selected')
      return
    }
    const resource = e.target.files[0]
    if (fileDetails.length) {
      if (fileDetails?.find((item) => item.fileTitle === fileTitle)) {
        return setFileDetails(
          fileDetails.map((item) =>
            item.fileTitle === fileTitle
              ? {
                  file: resource,
                  fileTitle: fileTitle,
                }
              : item
          )
        )
      }
    }
    setFileDetails([
      ...fileDetails,
      {
        file: resource,
        fileTitle: fileTitle,
      },
    ])
  }

  const handleStatementFileUpload = async (e, fileTitle) => {
    if (!e.target.files || !e.target.files[0]) {
      toast.error('No file selected')
      return
    }
    const resource = e.target.files[0]
    if (statementFileDetails.length) {
      if (statementFileDetails?.find((item) => item.fileTitle === fileTitle)) {
        return setStatementFileDetails(
          statementFileDetails.map((item) =>
            item.fileTitle === fileTitle
              ? {
                  file: resource,
                  fileTitle: fileTitle,
                }
              : item
          )
        )
      }
    }
    setStatementFileDetails([
      ...statementFileDetails,
      {
        file: resource,
        fileTitle: fileTitle,
      },
    ])
  }

  const getButtonTitle = (fileTitle, action, title) => {
    if (fileDetails.length && fileDetails?.find((item) => item.fileTitle === fileTitle)) {
      return fileDetails?.find((item) => item.fileTitle === fileTitle)?.file.name
    } else {
      return action === 'edit' ? title : `Upload ${fileTitle}`
    }
  }

  const getStatementButtonTitle = (fileTitle, action, title) => {
    if (
      statementFileDetails.length &&
      statementFileDetails?.find((item) => item.fileTitle === fileTitle)
    ) {
      return statementFileDetails?.find((item) => item.fileTitle === fileTitle)?.file.name
    } else {
      return action === 'edit' ? title : `Upload ${fileTitle} statement`
    }
  }

  return (
    <div className=" glass-card relative w-[90%] shadow-lg backdrop-blur-lg bg-lavender/5        relative border-r-2 border-b-2 border-white/30  h-[75vh] mt-24 overflow-y-auto oveflow-x-auto 2xl:overflow-x-hidden rounded-[40px] px-16 py-8 flex flex-col items-center  ">
      <div className="capitalHeading w-full text-center">
        <span className="bg-gradient-to-b from-[#da70d6] via-[#ccccff] to-[#e6e6fa] font-lato text-4xl text-transparent bg-clip-text   ">
          Firmwide Data
        </span>
      </div>
      {isLoadingFileStatus || isLoadingAccounts ? (
        <div className=" grid place-items-center mt-36">
          <CircularProgress color="inherit" className="text-customLightBlue1" size={30} />
        </div>
      ) : filesUploadStatus ? (
        <>
          <div className=" mt-16 space-y-8">
            <div className="flex gap-x-10 items-center justify-between">
              <p className=" graph-title w-80 font-lato font-semibold text-customLightBlue1">
                Overhead Transaction Forecast
              </p>
              {filesUploadStatus.payload?.forecasts_exists ? (
                <div className="relative">
                  <EditFileButton
                    disabled={isSubmittingForecast}
                    icon={<BiSolidEdit color="customDarkBlue2" size={22} />}
                    title={getButtonTitle('Overhead forecast', 'edit', 'Overhead forecast updated')}
                    className="w-[290px] flex justify-evenly"
                  />
                  <input
                    type="file"
                    name="file"
                    accept=" .csv"
                    disabled={isSubmittingForecast}
                    onChange={(e) => handleFileUpload(e, 'Overhead forecast')}
                    className="absolute cursor-pointer inset-0 opacity-0"
                  />
                </div>
              ) : (
                <div className="relative">
                  <UploadFileButton
                    disabled={isSubmittingForecast}
                    title={getButtonTitle('Overhead forecast')}
                    className="w-[290px] cursor-pointer"
                    icon={<LuUpload color="customDarkBlue2" size={22} />}
                  />
                  <input
                    type="file"
                    name="file"
                    accept=" .csv"
                    disabled={isSubmittingForecast}
                    onChange={(e) => handleFileUpload(e, 'Overhead forecast')}
                    className="absolute cursor-pointer inset-0 opacity-0"
                  />
                </div>
              )}
            </div>

            {accounts?.map((account) => (
              <div className="flex gap-x-10 items-center justify-between">
                <p className=" graph-title w-80 font-lato font-semibold  text-customLightBlue1">
                  {account.account_ending} Statement
                </p>
                {filesUploadStatus?.payload?.bank_transaction_exists &&
                filesUploadStatus?.payload?.bank_account_names.includes(account.account_ending) ? (
                  <div className="relative">
                    <EditFileButton
                      disabled={isSubmittingStatement}
                      icon={<BiSolidEdit color="customDarkBlue2" size={22} />}
                      title={getStatementButtonTitle(
                        account.account_ending,
                        'edit',
                        'Bank statement updated'
                      )}
                      className="w-[290px] flex justify-evenly"
                    />
                    <input
                      type="file"
                      name="upload-statement"
                      accept=" .csv"
                      disabled={isSubmittingStatement}
                      onChange={(e) => handleStatementFileUpload(e, account.account_ending)}
                      className="absolute inset-0 opacity-0"
                    />
                  </div>
                ) : (
                  <div className="relative">
                    <UploadFileButton
                      title={getStatementButtonTitle(account.account_ending)}
                      className="w-[290px] cursor-pointer"
                      icon={<LuUpload color="customDarkBlue2" size={22} />}
                      disabled={isSubmittingStatement}
                    />
                    <input
                      type="file"
                      name="upload-statement"
                      accept=" .csv"
                      disabled={isSubmittingStatement}
                      onChange={(e) => handleStatementFileUpload(e, account.account_ending)}
                      className="absolute inset-0 opacity-0"
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="w-full mt-28 grid  gap-x-3 justify-end grid-cols-[auto_auto] pr-8">
            <Button
              title="Upload forecast"
              onClick={uploadForecast}
              isSubmitting={isSubmittingForecast}
              className="h-16 w-[180px]"
            />
            <Button
              title="Upload statement"
              onClick={uploadStatement}
              isSubmitting={isSubmittingStatement}
              className="h-16 w-[180px]"
            />
          </div>
        </>
      ) : null}
    </div>
  )
}
