import React, { useMemo, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell, ReferenceLine } from 'recharts';
import LiqGlassCard from './LiquidityGlass';
import AccountIcon from '../../../assets/Tooltip Icons/Account.svg';
import AmountIcon from '../../../assets/Tooltip Icons/Amount.svg';
import DateIcon from '../../../assets/Tooltip Icons/Date.svg';


const MoneyMarket = ({ data }) => {
  // Hover state to track the hovered bar
  const [hoveredIndex, setHoveredIndex] = useState(null);

  // Function to convert string values to numbers
  const convertToNumbers = (entry) => ({
    ...entry,
    amount: parseFloat(entry.amount),
  });

  // Memoize numericData to prevent unnecessary recalculations
  const numericData = useMemo(() => data.map(convertToNumbers), [data]);

  // Memoize the earliest date calculation
  const earliestDate = useMemo(() => new Date(Math.min(...numericData.map(d => new Date(d.date)))), [numericData]);

  // Memoize filteredData based on the earliest date
  const filteredData = useMemo(() => 
    numericData.filter(item => 
      new Date(item.date).getTime() === earliestDate.getTime()
    ), 
    [numericData, earliestDate]
  );

  // Memoize the processedData to ensure both "savings" and "borrowing" categories are present
  const processedData = useMemo(() => {
    const categories = ['savings', 'borrowing'];
    return categories.map(category => {
      const found = filteredData.find(item => item.cash_direction === category);
      return found ? found : {
        id: null,
        developer: '',
        cash_direction: category,
        date: earliestDate.toISOString().split('T')[0],
        period_type: '',
        forecast_type: '',
        amount: 0
      };
    });
  }, [filteredData, earliestDate]);

  const colors = {
    liquidityRed: '#e4cfcf',
    liquidityGreen: '#e4fff5',
  };

  // Set the desired dimensions for the chart container
  const cardWidth = '30vw'; // 30% of the viewport width
  const cardHeight = '30vh'; // 30% of the viewport height

  // Handle hover state and apply dynamic styles
  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };
  // Tooltip content function for custom rendering
  const renderTooltip = ({ payload }) => {
    if (payload && payload.length) {
      const { cash_direction, amount, date } = payload[0].payload;

      const tooltipData = [
        {
          icon: <img src={AccountIcon} className="w-4 h-4 mr-1" alt="Cash Direction" />,  // Cash direction icon
          label: cash_direction,  // Cash direction (savings or borrowing)
        },
        {
          icon: <img src={AmountIcon} className="w-4 h-4 mr-1" alt="Amount" />,  // Amount icon
          label: `$${amount.toLocaleString()}`,  // Formatted amount
        },
        {
          icon: <img src={DateIcon} className="w-4 h-4 mr-1" alt="Date" />,  // Date icon
          label: new Date(date).toISOString().split('T')[0],  // Formatted date
        },
      ];

      return (
        <div className="p-4 rounded-lg bg-gradient-to-r from-customLightBlue1 to-white border-r-2 border-b-2 border-customMidBlue3 shadow-lg opacity-95 transform transition duration-200 ease-in-out">
          <table className="table-auto text-customDarkBlue2 font-lato">
            <tbody>
              {tooltipData.map((item, index) => (
                <tr key={index}>
                  {/* Icon cell */}
                  <td className="pr-2 font-bold border-r-2 border-customMidBlue3">
                    <span className="inline-flex items-center">{item.icon}</span>
                  </td>
                  {/* Label (text value) cell */}
                  <td className="pl-2">{item.label}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
    return null;
  };


  return (
    <div 
      className="flex justify-end items-end" 
      style={{ width: cardWidth, height: cardHeight, marginBottom: '50px', paddingBottom: '20px', marginLeft: '5px' }}
    >
      <div style={{ width: '90%', height: '100%' }}>
        <LiqGlassCard title="Money Market">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart 
              data={processedData} 
              layout="horizontal"  // Horizontal layout
            >
              <XAxis 
                type="category" 
                dataKey="cash_direction"
                tick={false}  // Use cash_direction as the X-axis
              />
              <YAxis type="number" hide={true} />
              <Tooltip cursor={{ fill: 'transparent' }} content={renderTooltip} />
              <ReferenceLine y={0} stroke="#E6E6FA" strokeWidth={1} />  {/* Horizontal line at y=0 */}
              <Bar 
                dataKey="amount" 
                radius={[30, 30, 0, 0]}  // Adjust the rounding for bars
                minPointSize={5}  // Ensure small bars are visible
              >
                {processedData.map((entry, index) => (
                  <Cell 
                    key={`cell-${index}`} 
                    fill={entry.amount >= 0 ? colors.liquidityGreen : colors.liquidityRed} 
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    style={{
                      filter: hoveredIndex === index ? 'brightness(1.2)' : 'none',  // Apply hover effect conditionally
                      cursor: 'pointer',
                      transition: 'filter 0.3s ease',
                    }}
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </LiqGlassCard>
      </div>
    </div>
  );
};

export default MoneyMarket;
