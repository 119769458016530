import { fetchAllBankAccounts, fetchBankAccount, fetchBankAccounts } from 'lib/api/account'
import { useQuery } from 'react-query'

export function useBankAccounts({ developerName, project }) {
  const query = useQuery(['bank-accounts', developerName, project], () =>
    fetchBankAccounts({ developerName, project })
  )

  return query
}

export function useBankAccount({ developerName, id }) {
  const query = useQuery(['bank-account', developerName, id], () =>
    fetchBankAccount({ developerName, id })
  )

  return query
}

export function useAllBankAccounts({ developerName }) {
  const query = useQuery(['bank-accounts', developerName], () =>
    fetchAllBankAccounts({ developerName })
  )

  return query
}
