import Button from 'components/button'
import { UploadFileButton } from 'components/button/data-entry'
import { useAppContext } from 'context'
import { uploadFundingTimeline, uploadInterestRate, uploadPrimeSchedule } from 'lib/api/admin'
import { useState } from 'react'
import { LuUpload } from 'react-icons/lu'
import { toast } from 'sonner'

export default function AdminForm() {
  const { developer } = useAppContext()
  const [fileDetails, setFileDetails] = useState([])
  const [isSubmittingFile, setIsSubmittingFile] = useState(false)

  const handleFileUpload = async (e, fileType) => {
    if (!e.target.files || !e.target.files[0]) {
      toast.error('No file selected')
      return
    }
    const resource = e.target.files[0]
    if (fileDetails.length) {
      if (fileDetails?.find((item) => item.fileType === fileType)) {
        return setFileDetails(
          fileDetails.map((item) =>
            item.fileType === fileType
              ? {
                  file: resource,
                  fileType: fileType,
                }
              : item
          )
        )
      }
    }
    setFileDetails([
      ...fileDetails,
      {
        file: resource,
        fileType: fileType,
      },
    ])
  }

  const uploadFile = async () => {
    for (let i = 0; i < fileDetails.length; i++) {
      try {
        setIsSubmittingFile(true)
        const formData = new FormData()
        formData.append('file', fileDetails[i].file)

        if (fileDetails[i].fileType === 'interest_rate') {
          const result = await uploadInterestRate({
            developerName: developer.split(' ')?.[0],
            payload: formData,
          })
          toast.success(`Interest Rate Path: ${result.status ?? 'csv successfully uploaded'}`)
        } else if (fileDetails[i].fileType === 'prime_schedule') {
          const result = await uploadPrimeSchedule({
            developerName: developer.split(' ')?.[0],
            payload: formData,
          })
          toast.success(`Prime Schedule: ${result.status ?? 'csv successfully uploaded'}`)
        } else if (fileDetails[i].fileType === 'funding_stage_timeline') {
          const result = await uploadFundingTimeline({
            developerName: developer.split(' ')?.[0],
            payload: formData,
          })
          toast.success(`Funding Stage Timeline: ${result.status ?? 'csv successfully uploaded'}`)
        }
      } catch (error) {
        toast.error(
          `${fileDetails[i].fileType.replace(/_/g, ' ')}: ${error.message}` ?? 'An error occurred'
        )
      } finally {
        setIsSubmittingFile(false)
      }
    }
  }

  const getButtonTitle = (fileType) => {
    if (fileDetails.length && fileDetails?.find((item) => item.fileType === fileType)) {
      return fileDetails?.find((item) => item.fileType === fileType)?.file.name
    } else {
      return `Upload ${fileType.replace(/_/g, ' ')}`
    }
  }

  return (
    <div className=" glass-card relative w-[90%] shadow-lg backdrop-blur-lg bg-lavender/5 relative border-r-2 border-b-2 border-white/30  h-[75vh] mt-24 overflow-y-auto oveflow-x-auto 2xl:overflow-x-hidden rounded-[40px] px-16 py-8 flex flex-col items-center ">
      <div className="capitalHeading w-full text-center">
        <span className="bg-gradient-to-b from-[#da70d6] via-[#ccccff] to-[#e6e6fa] font-lato text-4xl text-transparent bg-clip-text ">
          Admin Data
        </span>
      </div>

      <div className=" mt-16 space-y-8">
        <div className="flex gap-x-10 items-center justify-between">
          <p className=" graph-title w-80 font-lato font-semibold text-customLightBlue1">Prime Schedule</p>
          <div className="relative">
            <UploadFileButton
              title={getButtonTitle('prime_schedule')}
              className="w-[300px] xl:w-[356px] cursor-pointer"
              disabled={isSubmittingFile}
              icon={<LuUpload className="absolute right-5 bottom-2" color="customDarkBlue2" size={20} />}
            />
            <input
              type="file"
              name="file"
              accept=" .csv"
              disabled={isSubmittingFile}
              onChange={(e) => handleFileUpload(e, 'prime_schedule')}
              className="absolute cursor-pointer inset-0 opacity-0"
            />
          </div>
        </div>

        <div className="flex gap-x-10 items-center justify-between">
          <p className=" graph-title w-80 font-lato font-semibold text-customLightBlue1">Interest Rate Path</p>
          <div className="relative">
            <UploadFileButton
              title={getButtonTitle('interest_rate')}
              className="w-[300px] xl:w-[356px] cursor-pointer"
              disabled={isSubmittingFile}
              icon={<LuUpload className="absolute right-5 bottom-2" color="customDarkBlue2" size={20} />}
            />
            <input
              type="file"
              name="file"
              accept=" .csv"
              disabled={isSubmittingFile}
              onChange={(e) => handleFileUpload(e, 'interest_rate')}
              className="absolute cursor-pointer inset-0 opacity-0"
            />
          </div>
        </div>
        <div className="flex gap-x-10 items-center justify-between">
          <p className=" graph-title w-80 font-lato font-semibold  text-customLightBlue1">Drop-Down</p>
          <div className="relative">
            <UploadFileButton
              title={getButtonTitle('drop_down')}
              className="w-[300px] xl:w-[356px] cursor-pointer"
              disabled={isSubmittingFile}
              icon={<LuUpload className="absolute right-5 bottom-2" color="customDarkBlue2" size={20} />}
            />
            <input
              type="file"
              name="file"
              accept=" .csv"
              onChange={(e) => handleFileUpload(e, 'drop_down')}
              className="absolute cursor-pointer inset-0 opacity-0"
            />
          </div>
        </div>
        <div className="flex gap-x-10 items-center justify-between">
          <p className=" graph-title w-80 font-lato font-semibold  text-customLightBlue1">
            Average Funding Stage Timeline
          </p>
          <div className="relative">
            <UploadFileButton
              title={getButtonTitle('funding_stage_timeline')}
              className="w-[300px] xl:w-[356px] cursor-pointer"
              disabled={isSubmittingFile}
              icon={<LuUpload className="absolute right-5 bottom-2" color="customDarkBlue2" size={20} />}
            />
            <input
              type="file"
              name="file"
              accept=" .csv"
              disabled={isSubmittingFile}
              onChange={(e) => handleFileUpload(e, 'funding_stage_timeline')}
              className="absolute cursor-pointer inset-0 opacity-0"
            />
          </div>
        </div>
        <div className="flex gap-x-10 items-center justify-between">
          <p className=" graph-title w-80 font-lato font-semibold  text-customLightBlue1">
            User Account Information
          </p>
          <div className="relative">
            <UploadFileButton
              title={getButtonTitle('user_account_information')}
              className="w-[300px] xl:w-[356px] cursor-pointer"
              disabled={isSubmittingFile}
              icon={<LuUpload className="absolute right-5 bottom-2" color="customDarkBlue2" size={20} />}
            />
            <input
              type="file"
              name="file"
              accept=" .csv"
              disabled={isSubmittingFile}
              onChange={(e) => handleFileUpload(e, 'user_account_information')}
              className="absolute cursor-pointer inset-0 opacity-0"
            />
          </div>
        </div>
      </div>
      <div className="w-full mt-16 grid  justify-end">
        <Button
          title="Upload File"
          onClick={uploadFile}
          disabled={fileDetails.length ? false : true}
          isSubmitting={isSubmittingFile}
          className="h-16 w-[150px]"
        />
      </div>
    </div>
  )
}
