import makeApiCall from '.'

export async function fetchCashProjectionBalance({ developer }) {
  const data = await makeApiCall('/a-liquidity/cash-balance-projection/', 'get', undefined, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })

  return data
}

export async function fetchMoneyMarket({ developer }) {
  const data = await makeApiCall('/a-liquidity/money-market/', 'get', undefined, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })

  return data
}

export async function fetchShortTermFinancing({ developer }) {
  const data = await makeApiCall('/a-liquidity/short-term-financing/', 'get', undefined, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })

  return data
}

export async function uploadLiquidityForcast({ developerName, payload }) {
  return await makeApiCall('/a-liquidity/upload-forecast-csv/', 'post', payload, {
    baseURL: `https://${developerName}.alphamadesimple.ca`,
  })
}

export async function uploadBankStatement({ developerName, payload }) {
  return await makeApiCall('/a-liquidity/upload-csv/', 'post', payload, {
    baseURL: `https://${developerName}.alphamadesimple.ca`,
  })
}

export async function recalculateAllFunctions({ developerName }) {
  return await makeApiCall('/a-liquidity/allfunctions/', 'get', undefined, {
    baseURL: `https://${developerName}.alphamadesimple.ca`,
  })
}
