import {
  fetchCashProjectionBalance,
  fetchMoneyMarket,
  fetchShortTermFinancing,
} from 'lib/api/liquidity'
import { useQuery } from 'react-query'

export function useCashProjectionBalance({ developer }) {
  const query = useQuery(['cash-projection-balance', developer], () =>
    fetchCashProjectionBalance({ developer })
  )

  return query
}

export function useMoneyMarket({ developer }) {
  const query = useQuery(['money-market', developer], () => fetchMoneyMarket({ developer }))

  return query
}

export function useShortTermFinancing({ developer }) {
  const query = useQuery(['short-term-financing', developer], () =>
    fetchShortTermFinancing({ developer })
  )

  return query
}
