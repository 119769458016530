import makeApiCall from '.'

export async function fetchCashflowForecast({ developer }) {
  const data = await makeApiCall('/c-solvency/net-cashflow-forecast/', 'get', undefined, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })

  return data
}

export async function fetchVarianceAnalysis({ developer }) {
  const data = await makeApiCall('/c-solvency/forecast-variance-analysis/', 'get', undefined, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })

  return data
}

export async function fetchFinancialRatios({ developer }) {
  const data = await makeApiCall('/c-solvency/financial-ratios/', 'get', undefined, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })

  return data
}
