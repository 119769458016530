import makeApiCall from '.'

export async function onboardClient({ developer, payload }) {
  const { data } = await makeApiCall('/e-creprojects/clients/', 'post', payload, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })

  return data
}

export async function fetchClientInformation({ developer }) {
  const data = await makeApiCall('/e-creprojects/clients/', 'get', undefined, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })

  return data
}

export async function editClient({ developer, payload, id }) {
  const { data } = await makeApiCall(`/e-creprojects/clients/${id}/`, 'patch', payload, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })

  return data
}

export async function uploadImage({ payload }) {
  const { data } = await makeApiCall('/users/upload-image/', 'post', payload)

  return data
}
