const CapGlassCard = ({ title, children, iconSrc, className = "", centerTitle = false }) => {
    return (
        <div className={`glass-card flex-grow flex-shrink-0 h-full m-2 p-6 rounded-3xl shadow-lg backdrop-blur-lg bg-lavender/5        relative border-r-2 border-b-2 border-white/30 ${className}`}>
            <h2 className={`text-xl font-light mb-2 text-customLightBlue1 font-lato glow-effect ${centerTitle ? 'flex justify-center items-center h-full' : ''}`}>
                <img
                    src={iconSrc}
                    alt={` Icon`}
                    className="mr-2 w-6 h-6 glow-icon text-customLightBlue1"
                />
                {title}
            </h2>
            <div className={`h-full w-full ${centerTitle ? '' : 'flex justify-center items-center'}`}>
                {children}
            </div>
        </div>
    );
};

export default CapGlassCard;
