import React from "react";
import Tooltip from '@mui/material/Tooltip';
import TooltipComponent from '../../../components/tool-tips/tooltipscomp';  // Assuming you have the TooltipComponent

import ProfitMarginIcon from "../../../assets/fin-ratios-svgs/ProfitMargin.svg";
import CashReturnIcon from "../../../assets/fin-ratios-svgs/CashonCash.svg";
import CashConversionIcon from "../../../assets/fin-ratios-svgs/CashConversion.svg";
import LiquidityIcon from "../../../assets/fin-ratios-svgs/LiquidityRatio.svg";
import DebtToEquityIcon from "../../../assets/fin-ratios-svgs/DebttoEquity.svg";
import IRRIcon from "../../../assets/fin-ratios-svgs/IRR.svg";
import RatioIcon from '../../../assets/Tooltip Icons/Ratio.svg';
import CurrentIcon from '../../../assets/Tooltip Icons/Current.svg';
import PreviousIcon from '../../../assets/Tooltip Icons/Previous.svg';

import { motion } from 'framer-motion';

const FinancialRatios = ({ data }) => {
  const formatPercentage = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const getBorderColor = (currentValue, previousValue, thresholds) => {
    const change = currentValue - previousValue;

    if (change >= thresholds.green) {
      return 'border-customGreen'; // green
    } else if (change <= thresholds.red) {
      return 'border-customRed'; // red
    } else {
      return 'border-customYellow'; // yellow
    }
  };

  const RatioContainer = ({ current, previous, tooltip, iconSrc, thresholds, format = (v) => v }) => {
    const borderColor = getBorderColor(current, previous, thresholds);

    // Tooltip content with custom icons and fields for current and previous ratios
    const tooltipContent = (
      <TooltipComponent
        fields={[
          { value: `${tooltip}` },               // Ratio name
          { value: `Current: ${format(current)}` },  // Current value
          { value: `Previous: ${format(previous)}` } // Previous value
        ]}
        icons={[
          <img src={RatioIcon} className="w-4 h-4 mr-1" alt={`Ratio Icon`} />,
          <img src={CurrentIcon} className="w-4 h-4 mr-1" alt={`Current Icon`} />,
          <img src={PreviousIcon} className="w-4 h-4 mr-1" alt={`Previous Icon`} />
        ]}
      />
    );

    return (
      <Tooltip title={tooltipContent}>
        <motion.div
          className={`flex flex-col items-center p-4 rounded-full gap-2 border-2 ${borderColor} bg-transparent flex-grow`}
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Circle
            value={format(current)}
            tooltip={`Current ${tooltip}`}
          />
          <img
            src={iconSrc}
            alt={`${tooltip} Icon`}
            className="mb-2 w-8 h-8 fill-current text-customLightBlue1"
          />
          <Circle
            value={format(previous)}
            tooltip={`Previous ${tooltip}`}
            isBottom={true}
            previousValue={format(previous)}
          />
        </motion.div>
      </Tooltip>
    );
  };

  const Circle = ({ value, tooltip, isBottom, previousValue }) => (
    <div className="relative flex justify-center items-center mb-1">
      <div
        className={`w-12 h-12 rounded-full flex justify-center items-center text-[0.7rem] font-lato font-semibold shadow-lg shadow-lavender/50 ${
          isBottom ? 'bg-transparent border-2 border-mauve' : 'bg-customLightBlue1 text-customDarkBlue2'
        }`}
        title={tooltip}
      >
        {isBottom ? previousValue : value}
      </div>
      {isBottom && (
        <div className="absolute flex justify-center items-center w-8 h-8 text-customLightBlue1 text-[0.65rem] rounded-full">
          {value}
        </div>
      )}
    </div>
  );

  return (
    <div className="flex flex-wrap justify-center space-x-6 2xl:space-x-10 space-y-4 pb-4">
      {data.map((item, index) => (
        <motion.div
          key={item.id}
          className="flex space-x-12 flex-wrap justify-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: index * 0.2 }}  // Delays each item slightly
        >
          <RatioContainer
            current={item.current_profit_margin}
            previous={item.previous_profit_margin}
            tooltip="Profit Margin"
            iconSrc={ProfitMarginIcon}
            thresholds={{ green: 0.02, red: -0.02 }}
            format={formatPercentage}
          />

          <RatioContainer
            current={item.current_cash_on_cash_return}
            previous={item.previous_cash_on_cash_return}
            tooltip="Cash on Cash Return"
            iconSrc={CashReturnIcon}
            thresholds={{ green: 0.5, red: -0.5 }}
          />

          <RatioContainer
            current={item.current_cash_conversion}
            previous={item.previous_cash_conversion}
            tooltip="Cash Conversion"
            iconSrc={CashConversionIcon}
            thresholds={{ green: 10, red: -10 }}
          />

          <RatioContainer
            current={item.current_liquidity_ratio}
            previous={item.previous_liquidity_ratio}
            tooltip="Liquidity Ratio"
            iconSrc={LiquidityIcon}
            thresholds={{ green: 0.5, red: -0.5 }}
          />

          <RatioContainer
            current={item.current_debt_to_equity}
            previous={item.previous_debt_to_equity}
            tooltip="Debt to Equity"
            iconSrc={DebtToEquityIcon}
            thresholds={{ green: 0.5, red: -0.5 }}
          />

          <RatioContainer
            current={item.current_internal_rate_of_return}
            previous={item.previous_internal_rate_of_return}
            tooltip="Internal Rate of Return"
            iconSrc={IRRIcon}
            thresholds={{ green: 0.02, red: -0.02 }}
            format={formatPercentage}
          />
        </motion.div>
      ))}
    </div>
  );
};

export default FinancialRatios;
