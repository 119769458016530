import { EditFileButton, UploadFileButton } from 'components/button/data-entry'
import { React } from 'react'
import { useNavigate } from 'react-router-dom'
import { IoIosAddCircleOutline } from 'react-icons/io'
import { BiSolidEdit } from 'react-icons/bi'
import { useAppContext } from 'context'
import { useDeveloperProjects } from 'data/project'
import { toast } from 'sonner'
import { CircularProgress } from '@mui/material'
import { useDebtCls } from 'data/debt'
import { format } from 'date-fns'

export default function DebtDataForm() {
  const navigate = useNavigate()
  const { developer: clientOrgName } = useAppContext()
  const {
    data: projects,
    isLoading: isLoadingProjects,
    isError: isErrorFetchingProjects,
  } = useDeveloperProjects({ developer: clientOrgName.split(' ')?.[0] })

  const debtCls = useDebtCls({ developer: clientOrgName.split(' ')?.[0] })

  if (isErrorFetchingProjects) {
    toast.error('Error fetching developer projects')
  }

  if (debtCls.isError) {
    toast.error('Error fetching debt commitment letters')
  }

  return (
    <div className="glass-card relative w-[90%] shadow-lg backdrop-blur-lg bg-lavender/5        relative border-r-2 border-b-2 border-white/30 rounded-3xl h-[75vh] mt-24  overflow-y-auto overflow-x-hidden px-16 py-8 justify-center justify-items-center">
      <div className="w-full text-center block">
        <div className="capitalHeading w-full text-center">
          <span className="bg-gradient-to-b from-[#da70d6] via-[#ccccff] to-[#e6e6fa] font-lato text-4xl text-transparent bg-clip-text ">
            Debt Data
          </span>
        </div>
        <div className="capitalButton my-4  flex justify-end ">
          <button onClick={() => navigate('upcoming-financings')}>
          <img
            src="/assets/svgs/debt-data/calendar.svg"
            alt="add financing stage"
            height={60}  // Change this to your desired height
            width={60}   // Change this to your desired width
    />
          </button>
        </div>
      </div>
      {debtCls.isLoading || isLoadingProjects ? (
        <div className=" grid place-items-center mt-36">
          <CircularProgress color="inherit" className="text-customLightBlue1" size={30} />
        </div>
      ) : (
        projects &&
        debtCls?.data &&
        (projects.length ? (
          <div className="flex  gap-x-8 w-[100%] overflow-x-auto   xl:gap-x-12 flex-nowrap text-center">
            {projects.map((project) => (
              <div key={project.id} className="flex flex-col items-center gap-y-6">
                <label className="whitespace-nowrap graph-title w-40 font-lato font-semibold text-sm text-customLightBlue1">
                  {project.project_name}
                </label>

                {debtCls.data
                  ?.filter((item) => item.project === project.project_name)
                  .map((item) => (
                    <EditFileButton
                      icon={<BiSolidEdit color="customDarkBlue2" size={22} />}
                      title={`${format(new Date(item.interest_adjustment_date), 'MM-yy')} ${item.lender}`}
                      className="w-[230px] 2xl:w-[250px] flex justify-evenly cursor-pointer"
                      onClick={() => navigate(`cl-data-form/${item.id}`)}
                    />
                  ))}
                <UploadFileButton
                  icon={<IoIosAddCircleOutline color="customDarkBlue2" size={24} />}
                  title="Add CL Data"
                  className="w-[230px] 2xl:w-[250px] flex justify-evenly cursor-pointer"
                  onClick={() =>
                    navigate(`cl-data-form?project=${project.project_name.replace(/\s+/g, '-')}`)
                  }
                />
              </div>
            ))}
          </div>
        ) : (
          <p className=" mt-40 w-full grid place-items-center graph-title  font-lato font-semibold text-lg text-customLightBlue1">
            No active project
          </p>
        ))
      )}
    </div>
  )
}
