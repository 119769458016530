import React from "react";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import WAIRIcon from "../../../assets/Title Icons/WAIR.svg";
import DebstLTVIcon from "../../../assets/Title Icons/ProjectLoantoValue.svg";

const InterestLTVTracker = ({ data }) => {
  const {
    current_wair,
    target_wair,
    current_loan_to_value,
    target_loan_to_value,
  } = data[0];

  const GlassCard = ({ title, iconSrc, children }) => {
    return (
      <div className="glass-card w-[20vw] h-[7vh] mt-4 p-4 rounded-3xl shadow-lg backdrop-blur-lg bg-lavender/5 relative border-r-2 border-b-2 border-white/30 flex items-center justify-between">
        {/* Title */}
        <h2 className="text-base font-light text-customLightBlue1 font-lato flex items-center glow-effect">
          <img
            src={iconSrc}
            alt={` Icon`}
            className="mr-2 w-4 h-4 glow-icon text-customLightBlue1"
          />
          {title}
        </h2>
        {/* Children */}
        <div className="flex justify-between items-center glow-effect">
          {children}
        </div>
      </div>
    );
  };

  const formatPercentage = (value) => `${(value * 100).toFixed(2)}%`;

  return (
    <div className="flex justify-between items-center w-full">
      {/* Loan to Value Card */}
      <GlassCard title="LTV" iconSrc={DebstLTVIcon}>
        {/* Current LTV */}
        <div
          className={`relative group flex-1 text-base font-bold flex items-center justify-center ${
            current_loan_to_value > target_loan_to_value
              ? "text-customRed"
              : "text-customGreen"
          }`}
        >
          {formatPercentage(current_loan_to_value)}
          {current_loan_to_value > target_loan_to_value ? (
            <FaArrowUp className="ml-2" />
          ) : (
            <FaArrowDown className="ml-2" />
          )}
          {/* Tooltip for Current */}
          <div className="absolute bottom-full left-1/2 w-72 -mb-1 transform -translate-x-1/2 p-2 rounded-2xl bg-gradient-to-r from-customLightBlue1 to-white border-r-2 border-b-2 border-customMidBlue3 shadow-lg bg-opacity-90 opacity-0 transition-opacity duration-300 group-hover:opacity-100 text-customDarkBlue2 text-center font-lato text-base font-thin tooltip">
            Current Weighted Average Loan to Value
          </div>
        </div>

        {/* Vertical Divider */}
        <div className="w-px h-[5vh] bg-customLightBlue1 mx-4"></div>

        {/* Target LTV */}
        <div className="relative group flex-1 text-base font-bold text-customLightBlue1 flex items-center justify-center glow-effect">
          {formatPercentage(target_loan_to_value)}
          {/* Tooltip for Target */}
          <div className="absolute bottom-full left-1/2 w-72 -mb-1 transform -translate-x-1/2 p-2 rounded-2xl bg-gradient-to-r from-customLightBlue1 to-white border-r-2 border-b-2 border-customMidBlue3 shadow-lg bg-opacity-90 opacity-0 transition-opacity duration-300 group-hover:opacity-100 text-customDarkBlue2 text-center font-lato text-base font-thin tooltip">
            Target Weighted Average Loan to Value
          </div>
        </div>
      </GlassCard>

      {/* WAIR Card */}
      <GlassCard title="WAIR" iconSrc={WAIRIcon}>
        {/* Current WAIR */}
        <div
          className={`relative group flex-1 text-base font-bold flex items-center justify-center ${
            current_wair > target_wair ? "text-customRed" : "text-customGreen"
          }`}
        >
          {formatPercentage(current_wair)}
          {current_wair > target_wair ? (
            <FaArrowUp className="ml-2" />
          ) : (
            <FaArrowDown className="ml-2" />
          )}
          {/* Tooltip for Current */}
          <div className="absolute bottom-full left-1/2 w-72 -mb-1 transform -translate-x-1/2 p-2 rounded-2xl bg-gradient-to-r from-customLightBlue1 to-white border-r-2 border-b-2 border-customMidBlue3 shadow-lg bg-opacity-90 opacity-0 transition-opacity duration-300 group-hover:opacity-100 text-customDarkBlue2 text-center font-lato text-base font-thin tooltip">
            Current Weighted Average Interest Rate
          </div>
        </div>

        {/* Vertical Divider */}
        <div className="w-px h-[5vh] bg-customLightBlue1 mx-4"></div>

        {/* Target WAIR */}
        <div className="relative group flex-1 text-base font-bold text-customLightBlue1 flex items-center justify-center glow-effect">
          {formatPercentage(target_wair)}
          {/* Tooltip for Target */}
          <div className="absolute bottom-full left-1/2 w-72 -mb-3 transform -translate-x-1/2 p-2 rounded-2xl bg-gradient-to-r from-customLightBlue1 to-white border-r-2 border-b-2 border-customMidBlue3 shadow-lg bg-opacity-90 opacity-0 transition-opacity duration-300 group-hover:opacity-100 text-customDarkBlue2 text-center font-lato text-base font-thin tooltip">
            Target Weighted Average Interest Rate
          </div>
        </div>
      </GlassCard>
    </div>
  );
};

export default InterestLTVTracker;
