import React, { useMemo, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell, ReferenceLine } from 'recharts';
import LiqGlassCard from './LiquidityGlass';
import ProjectIcon from '../../../assets/Tooltip Icons/Project.svg';
import ClosingBalanceIcon from '../../../assets/Tooltip Icons/ClosingBalance.svg';


const CashBalanceProj = ({ data }) => {

    // Hover state to track the hovered bar
    const [hoveredIndex, setHoveredIndex] = useState(null);
  // Function to convert string values to numbers
  const convertToNumbers = (entry) => ({
    ...entry,
    opening_balance: parseFloat(entry.opening_balance),
    net_cash_flow: parseFloat(entry.net_cash_flow),
    closing_balance: parseFloat(entry.closing_balance),
  });

  // Memoize numericData so it's only recalculated if data changes
  const numericData = useMemo(() => data.map(convertToNumbers), [data]);

// Memoize groupedData so it's only recalculated if numericData changes
const groupedData = useMemo(() => {
  return numericData.reduce((acc, current) => {
    const date = current.date;
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(current);

    // Sort the grouped data by closing_balance in descending order
    acc[date] = acc[date].sort((a, b) => b.closing_balance - a.closing_balance);

    return acc;
  }, {});
}, [numericData]);

  const colors = {
    liquidityRed: '#e4cfcf',
    liquidityGreen: '#e4fff5'
  };

  // Get the number of charts
  const numberOfCharts = Object.keys(groupedData).length;

  // Calculate the width and height percentage for each chart
  const cardWidth = `${100 / numberOfCharts}%`;
  const cardHeight = '100%';

  // Helper function to format date in 'mmm dd' format
  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return `${months[parseInt(month, 10) - 1]} ${day}`;
  };

    // Handle hover state and apply dynamic styles
    const handleMouseEnter = (index) => {
      setHoveredIndex(index);
    };
  
    const handleMouseLeave = () => {
      setHoveredIndex(null);
    };
    const renderTooltip = ({ payload }) => {
      if (payload && payload.length) {
        const { project, closing_balance } = payload[0].payload;
  
        const tooltipData = [
          { icon: <img src={ProjectIcon} className="w-4 h-4 mr-1 text-customDarkBlue2" alt="Project" />, label: project  },
          { icon: <img src={ClosingBalanceIcon} className="w-4 h-4 mr-1" alt="Closing Balance" />, label:`$${closing_balance.toLocaleString()}`},
        ];
  
        return (
          <div className="p-4 rounded-lg bg-gradient-to-r from-customLightBlue1 to-white border-r-2 border-b-2 border-customMidBlue3 shadow-lg opacity-95 transform transition duration-200 ease-in-out">
            <table className="table-auto text-customDarkBlue2 font-lato">
              <tbody>
                {tooltipData.map((item, index) => (
                  <tr key={index}>
                    <td className="pr-2 font-bold border-r-2 border-customMidBlue3">
                      <span className="inline-flex items-center">{item.icon}</span>
                    </td>
                    <td className="pl-2">{item.label}</td> {/* Add this line to render the label (value) */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      }
      return null;
    };
  return (
    <div className="mx-auto w-[93vw] h-[40vh] pt-20 2xl:pt-24 flex flex-col items-center">

      {/* Container for the charts */}
      <div className="w-full h-full flex justify-between mt-4">
        {Object.keys(groupedData).map((date) => {
          // Use the formatDate function to get the desired date format
            const formattedDate = formatDate(date);
            return (
              <div 
                key={date} 
                style={{ width: cardWidth, height: cardHeight }}  // Ensure each card fits within the available space
              >
                <LiqGlassCard title={formattedDate}>
                  <ResponsiveContainer width="100%" height="85%">
                    <BarChart 
                      data={groupedData[date]} 
                      layout="vertical"  // This flips the chart 90 degrees
                    >
                      <XAxis type="number" hide={true} />
                      <YAxis 
                        type="category" 
                        dataKey="project" 
                        hide={true}  // Hide the Y-axis labels
                      />
                      <Tooltip cursor={{ fill: 'transparent' }} content={renderTooltip} />
                      <ReferenceLine x={0} stroke="#E6E6FA" strokeWidth={1} />  {/* Add a vertical line at x=0 */}
                      <Bar 
                        dataKey="closing_balance" 
                        //className="glow-bar" 
                        radius={[0, 30, 30, 0]}  // Fully rounded corners
                        minPointSize={5}  // Ensure small bars are still visible
                      >
                        {groupedData[date].map((entry, index) => {
                          // Create a unique identifier for each bar by combining date and index
                          const uniqueKey = `${date}-${index}`;
                          return (
                            <Cell 
                              key={uniqueKey} 
                              fill={entry.closing_balance >= 0 ? colors.liquidityGreen : colors.liquidityRed} 
                              onMouseEnter={() => handleMouseEnter(uniqueKey)}  // Use uniqueKey for hover effect
                              onMouseLeave={handleMouseLeave}
                              style={{
                                filter: hoveredIndex === uniqueKey ? 'brightness(1.2)' : 'none',  // Apply hover effect based on uniqueKey
                                cursor: 'pointer',
                                transition: 'filter 0.3s ease'
                              }}
                            />
                          );
                        })}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </LiqGlassCard>
              </div>
            );
          })}
          
      </div>
    </div>
  );
};

export default CashBalanceProj;
