import React from 'react';
import { motion } from 'framer-motion';
import Tooltip from '@mui/material/Tooltip';
import TooltipComponent from '../../../components/tool-tips/tooltipscomp';  // Import the central TooltipComponent
import ProjectIcon from '../../../assets/Tooltip Icons/Project.svg';
import LoanIcon from '../../../assets/Tooltip Icons/Amount.svg';
import ValueIcon from '../../../assets/Tooltip Icons/Value.svg';


// Function to get the color code based on the value
const getColorCode = (value) => {
  if (value > 0 && value <= 59.99) {
    return '#cefaed';
  } else if (value >= 60 && value <= 79.99) {
    return '#feffc9';
  } else if (value >= 80) {
    return '#ffbbbb';
  }
};

export default function LoanToValue({ data }) {
  // Map the data array to create an array of objects with relevant properties
  const items = data?.map((item) => ({
    project: item.project,
    // Convert project_loan_to_value to a percentage and round
    value: `${Math.round(Number(item.project_loan_to_value ?? 0) * 100)}%`,
    // Convert loan and appraisal_value to numbers and use toLocaleString to add commas
    loan: Number(item.loan).toLocaleString(),  // Convert loan to a number and format
    appraisal_value: Number(item.appraisal_value).toLocaleString(),  // Convert appraisal_value to a number and format
  }));

  return (
    <div className="space-y-3 flex flex-col pb-2 justify-center">
      {items?.map((item, index) => (
        <Tooltip
          key={`${item.project}-${index}`}
          title={
            <TooltipComponent
              fields={[
                { value: item.project },
                { value: `$${item.loan}` },  // Display the loan with commas
                { value: `$${item.appraisal_value}` }  // Display the appraisal value with commas
              ]}
              icons={[
                <img src={ProjectIcon} className="w-4 h-4 mr-1" alt="Project" />,  
                <img src={LoanIcon} className="w-4 h-4 mr-1" alt="Loan" />,  
                <img src={ValueIcon} className="w-4 h-4 mr-1" alt="Appraisal Value" />  
              ]}
            />
          }
        >
          <motion.div
            className="relative flex items-center p-5 font-lato"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            {/* Circular div displaying the loan-to-value percentage */}
            <div
              className="absolute left-0 grid place-items-center w-12 h-12 text-customDarkBlue2 rounded-full container-shadow text-sm 2xl:text-base font-lato"
              style={{
                backgroundColor: getColorCode(Number(item.value.replace('%', ''))),
                boxShadow: `0 0 10px 2px ${getColorCode(Number(item.value.replace('%', '')))}`
              }}
            >
              {item.value} {/* Display the loan-to-value percentage */}
            </div>
            {/* Bar container */}
            <div className="relative ml-6 w-[300px] h-4 rounded-br-full rounded-tr-full">
              {/* Background with lavender color and reduced opacity */}
              <div
                className="absolute inset-0 rounded-br-full rounded-tr-full"
                style={{
                  backgroundColor: '#E6E6FA',
                  opacity: 0.3, // Low opacity for the lavender background
                }}
              ></div>
              {/* Foreground bar representing the value with full opacity */}
              <div
                className="relative z-10 h-[90%] rounded-br-full rounded-tr-full"
                style={{
                  width: Number(item.value.replace('%', '')) > 100 ? '100%' : item.value, // Cap the width at 100%
                  backgroundColor: getColorCode(Number(item.value.replace('%', ''))),     // Background color based on the value
                  opacity: 1, // Full opacity for the progress bar
                  boxShadow: `0 0 10px 2px ${getColorCode(Number(item.value.replace('%', '')))}`
                }}
              ></div>
            </div>
          </motion.div>
        </Tooltip>
      ))}
    </div>
  );
}
