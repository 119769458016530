// Import necessary libraries and components
import React from 'react'
import { IoMenu } from 'react-icons/io5' // Import menu icon from react-icons
import { NavLink, Outlet, useLocation } from 'react-router-dom' // Import components from react-router-dom
import SideBar from './side-bar' // Import custom SideBar component
import { useState, useMemo, useEffect } from 'react' // Import useState hook from React for managing local state
import { twMerge } from 'tailwind-merge' // Utility for merging Tailwind CSS classes
import { useProfile } from 'data/profile' // Hook to fetch user profile data
import { toast } from 'sonner' // Notification library for showing toast messages
import { useAppContext } from 'context' // Custom context to get global state (active menu)
import Footer from './Header-Footer/footer'
import Header from './Header-Footer/header'


export default function AppLayout() {
    const { activeMenu } = useAppContext() // Get the active menu from global context
  
    // Fetch profile data, loading state, error state, and refetch method from useProfile hook
    const {
      data: profile,
      isLoading: isLoadingProfile,
      isError: isErrorFetchingProfile,
      refetch: refetchProfile,
    } = useProfile()
  
    // Local state to control sidebar visibility
    const [isSideBarOpened, toggleSideBar] = useState(false)
    const location = useLocation() // Get current route location
  
    // Memoize profile to avoid unnecessary re-renders
    const memoizedProfile = useMemo(() => profile, [profile])

  
    // Show error notification if there's an error fetching the profile (only once when error occurs)
    useEffect(() => {
      if (isErrorFetchingProfile) {
        toast.error('Error fetching your profile')
      }
    }, [isErrorFetchingProfile])

  return (
    
    // Main layout section with a background gradient, full height, and grid layout
    <section className="bg-gradient-base-bg bg-cover grid-cols-1 relative bg-no-repeat w-full h-[100vh] overflow-hidden grid">
      {/* SideBar component, conditionally open or closed based on isSideBarOpened state */}
      <Header/>
      <SideBar
        open={isSideBarOpened} // Pass whether the sidebar is open
        profile={memoizedProfile} // Pass profile data to the sidebar
        isLoadingProfile={isLoadingProfile} // Pass profile loading state to the sidebar
        refetchProfile={refetchProfile} // Pass refetchProfile function to the sidebar
        close={() => toggleSideBar(!isSideBarOpened)} // Function to close the sidebar when triggered
      />

      {/* Main content section */}
      <section className="w-full">
        <div
          className={twMerge(
            // Flexbox container for top bar, adjusts based on sidebar state
            'flex w-full justify-between',
            isSideBarOpened ? 'justify-end' : 'justify-between' // Align to the right if sidebar is open, otherwise space-between
          )}
        >
          {/* Menu button, hidden when the sidebar is open */}
          <button
            className={twMerge('h-fit z-10 fixed top-4 right-4 m-4', isSideBarOpened && 'hidden')}
            onClick={() => toggleSideBar(!isSideBarOpened)} // Toggle sidebar visibility when clicked
          >
            <IoMenu color="#E6E6FA" size={25} /> {/* Menu icon */}
          </button>

          {/* Navigation links and user menu, dynamically rendered based on the current route */}
          <div className="bg-customLightBlue1 py-2 px-4 rounded-full shadow-lg fixed top-4 left-1/2 transform -translate-x-1/2 z-50">
          
            {/* Conditionally render navigation links if the path includes specific routes */}
            {location.pathname.includes('app-data') ||
            location.pathname.includes('dashboard') ||
            location.pathname.includes('report') ? (
              <>
                {/* NavLink components for different sections (Liquidity, Debt, Solvency, Capital) */}
                <ul className="flex space-x-4">
                <li>
                    <NavLink
                      to={`/${activeMenu}/liquidity`}
                      className={({ isActive }) =>
                        isActive
                          ? 'block px-4 py-2 rounded-full bg-customDarkBlue2 text-white'
                          : 'block px-4 py-2 rounded-full hover:bg-darkrinkle' // Add border if active
                      }
                    >
                      Liquidity
                    </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/${activeMenu}/debt`}
                    className={({ isActive }) =>
                      isActive
                        ? 'block px-4 py-2 rounded-full bg-customDarkBlue2 text-white'
                        : 'block px-4 py-2 rounded-full hover:bg-darkrinkle'
                    }
                  >
                    Debt
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/${activeMenu}/solvency`}
                    className={({ isActive }) =>
                      isActive
                        ? 'block px-4 py-2 rounded-full bg-customDarkBlue2 text-white'
                        : 'block px-4 py-2 rounded-full hover:bg-darkrinkle'
                    }
                  >
                    Solvency
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/${activeMenu}/capital`}
                    className={({ isActive }) =>
                      isActive
                        ? 'block px-4 py-2 rounded-full bg-customDarkBlue2 text-white'
                        : 'block px-4 py-2 rounded-full hover:bg-darkrinkle'
                    }
                  >
                    Capital
                  </NavLink>
                </li>
                </ul>
              </>
            ) : null}

          </div>
        </div>

        {/* Outlet component to render child components for the current route */}
        <section className="grid place-items-center mt-2 px-4 2xl:px-24">
          <Outlet /> {/* This will render the nested route components */}
        </section>
      </section>
      <Footer/>
    </section>
  )
}
