import React from 'react'
import {
  IoChevronBackCircleOutline,
  IoAddCircleOutline,
  IoChevronForwardCircleOutline,
} from 'react-icons/io5'
import { TextField } from 'components/form/input'
import { twMerge } from 'tailwind-merge'
import Button from 'components/button'
import { useState } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { toast } from 'sonner'
import { editCLData } from 'lib/api/debt'
import { useAppContext } from 'context'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useDebtCl } from 'data/debt'
import { CircularProgress } from '@mui/material'
import ProgressBar from '../progress-bar'
import { formatAmount } from 'lib/utils'

const validationSchema = yup.object().shape({
  credit_type: yup.string().required('select credit type'),
  lender_type: yup.string().required('Enter lender type'),
  borrower: yup.string().required('Enter borrower name'),
  lender: yup.string().required('enter lender name'),
  interest_adjustment_date: yup.string().required('A valid date is required'),
  loan_term: yup.number().required('Loan term is required'),
  maturity_date: yup.string().required('A valid date is required'),
  committed_loan_amount: yup.number().required('enter committed_loan_amount'),
  collateral: yup.string().required('A collateral is required'),
  purpose: yup.string().required('purpose required'),
  exit: yup.string().required('exit is required'),
  interest_rate_type: yup.string().required('rate type is required'),
  accrued_split: yup.number().required('enter accrued split'),
  paid_split: yup.number().required('Enter paid split'),
  floor_rate: yup.number().required('Floor rate is required'),
  ceiling_rate: yup.number(),
  reference_prime: yup.string().required('Enter reference_prime'),
  premium: yup.number(),
  compounding_frequency: yup.string().required('Enter compounding_frequency'),
  periodicity: yup.string().required('enter periodicity value'),
  financing_id: yup.string().required('enter Financing ID'),
  interest_free_period: yup.number().required('Enter interest free period'),
  recurring_payment_day: yup.number().required('enter payment day'),
  amortization: yup.number().required('Enter amortization'),
  first_contact_date: yup.string().required('A valid date is required'),
  discussion_paper: yup.string().required('A valid date is required'),
  loi_date: yup.string().required('A valid date is required'),
  commitment_letter_date: yup.string().required('A valid date is required'),
  finalized_agreement: yup.string().required('A valid date is required'),
  closing_date: yup.string().required('A valid date is required'),
})

export default function EditCLDataForm() {
  const [step, setStep] = useState(1)
  const navigate = useNavigate()
  const { developer } = useAppContext()
  const { id } = useParams()

  const {
    data: CLData,
    isLoading,
    isError,
    refetch: refetchCLData,
  } = useDebtCl({ developer: developer.split(' ')?.[0], id })

  if (isError) {
    toast.error('An error occurred while fetching CL Data')
  }

  const formDefaultValues = {
    credit_type: CLData?.credit_type ?? '',
    lender_type: CLData?.lender_type ?? '',
    borrower: CLData?.borrower ?? '',
    lender: CLData?.lender ?? '',
    interest_adjustment_date: CLData?.interest_adjustment_date ?? '',
    loan_term: CLData?.loan_term ?? 0,
    maturity_date: CLData?.maturity_date ?? '',
    committed_loan_amount: CLData?.committed_loan_amount ?? 0,
    collateral: CLData?.collateral ?? '',
    purpose: CLData?.purpose ?? '',
    exit: CLData?.exit ?? '',
    interest_rate_type: CLData?.interest_rate_type ?? '',
    accrued_split: CLData?.accrued_split ? (CLData.accrued_split * 100).toFixed(4) : 0,
    paid_split: CLData?.paid_split ? (CLData.paid_split * 100).toFixed(4) : 0,
    floor_rate: CLData?.floor_rate ? (CLData.floor_rate * 100).toFixed(4) : 0,
    ceiling_rate: CLData?.ceiling_rate ? (CLData.ceiling_rate * 100).toFixed(4) : 0,
    reference_prime: CLData?.reference_prime ?? '',
    premium: CLData?.premium ? (CLData.premium * 100).toFixed(4) : 0,
    compounding_frequency: CLData?.compounding_frequency ?? '',
    periodicity: CLData?.periodicity ?? '',
    financing_id: CLData?.financing_id ?? '',
    interest_free_period: CLData?.interest_free_period ?? 0,
    recurring_payment_day: CLData?.recurring_payment_day ?? 0,
    amortization: CLData?.armortization ?? 0,
    first_contact_date: CLData?.first_contact_date ?? '',
    discussion_paper: CLData?.discussion_paper ?? '',
    loi_date: CLData?.loi_date ?? '',
    commitment_letter_date: CLData?.commitment_letter_date ?? '',
    finalized_agreement: CLData?.finalized_agreement ?? '',
    closing_date: CLData?.closing_date ?? '',
  }

  return (
    <div className=" glass-card relative w-[90%] shadow-lg backdrop-blur-lg bg-lavender/5        relative border-r-2 border-b-2 border-white/30 mt-24 h-[75vh]  xl:overflow-y-hidden overflow-x-hidden rounded-[40px] px-16 py-8 justify-center justify-items-center">
      <div>
        <div className="w-1/12 flex absolute items-center gap-x-3 ">
          <button
            onClick={() => {
              setStep(step === 3 ? 2 : step === 2 ? 1 : navigate(-1))
            }}
          >
            <IoChevronBackCircleOutline size={40} color="#ccc8f6" />
          </button>
          {step !== 3 && (
            <button
              disabled={isLoading}
              onClick={() => {
                setStep(step === 1 ? 2 : 3)
              }}
            >
              <IoChevronForwardCircleOutline size={40} color="#ccc8f6" />
            </button>
          )}
        </div>
        <div className="capitalButton absolute  right-16">
          <button onClick={() => navigate(`/app-data/debt/transaction/history?cl-data-id=${id}`)}>
            <IoAddCircleOutline size={40} color="#ccc8f6" />
          </button>
        </div>
        <div className="  text-center">
          <span className="bg-gradient-to-b from-[#da70d6] via-[#ccccff] to-[#e6e6fa] font-lato text-4xl text-transparent bg-clip-text  ">
            CL Data Form
          </span>
        </div>
      </div>
      {isLoading ? (
        <div className=" grid place-items-center mt-36">
          <CircularProgress color="inherit" className="text-customLightBlue1" size={30} />
        </div>
      ) : (
        CLData && (
          <div className=" min-h-[48vh]  mt-6">
            <Formik
              initialValues={formDefaultValues}
              onSubmit={async (values) => {
                try {
                  values.accrued_split = (Number(values.accrued_split) / 100).toFixed(4)
                  values.paid_split = (Number(values.paid_split) / 100).toFixed(4)
                  values.floor_rate = (Number(values.floor_rate) / 100).toFixed(4)
                  values.ceiling_rate = (Number(values.ceiling_rate) / 100).toFixed(4)
                  values.premium = (Number(values.premium) / 100).toFixed(4)
                  values.committed_loan_amount = formatAmount(values.committed_loan_amount)
                  await editCLData({
                    developer: developer.split(' ')?.[0],
                    payload: values,
                    id: CLData?.id,
                  })
                  toast.success('Debt CL successfully edited')
                  refetchCLData()
                  navigate('/app-data/debt')
                } catch (error) {
                  toast.error(error.message ?? 'An error occurred while editing Debt CL')
                }
              }}
              validationSchema={validationSchema}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                setFieldValue,
                handleSubmit,
                isSubmitting,
              }) => (
                <section>
                  {/* project data form one */}
                  <form
                    className={twMerge(
                      'flex justify-between gap-4 pt-6 mb-16',
                      step === 1 ? '' : 'hidden'
                    )}
                  >
                    <div className="space-y-10">
                      <div className="   grid grid-cols-[130px_auto]  items-center">
                        <label
                          className=" flex font-semibold text-customLightBlue1 font-lato align-baseline"
                          for="credit-type"
                        >
                          Credit Type
                        </label>
                        <select
                          className="flex  w-[9rem] xl:w-[11rem] 2xl:w-[14rem] h-12  text-bca-grey-9 focus:border-bca-success-6 focus:outline-none text-sm border-0 bg-lavender font-lato border-[#42644C]  rounded-full text-center"
                          name="credit_type"
                          id="credit_type"
                          value={values.credit_type}
                          error={touched.credit_type && !!errors.credit_type}
                          helperText={touched.credit_type ? errors.credit_type : ''}
                          onChange={handleChange}
                        >
                          <option value="" disabled selected>
                            Select
                          </option>
                          <option value="Term Loan">Term Loan</option>
                          <option value="Shareholder Loan">Shareholder Loan</option>
                          <option value="Vendor Take-Back">Vendor Take-Back</option>
                          <option value="Construction Revolver">Construction Revolver</option>
                          <option value="Letters of Credit">Letters of Credit</option>
                        </select>
                      </div>
                      <div className=" grid grid-cols-[130px_auto]  items-center">
                        <label
                          className=" flex font-semibold text-customLightBlue1 font-lato align-baseline"
                          for="lender-type"
                        >
                          Lender Type
                        </label>
                        <select
                          className="flex w-[9rem] xl:w-[11rem] 2xl:w-[14rem] h-12   text-bca-grey-9 focus:border-bca-success-6 focus:outline-none text-sm border-0 bg-lavender font-lato border-[#42644C]  rounded-full text-center "
                          name="lender_type"
                          id="lender_type"
                          value={values.lender_type}
                          error={touched.lender_type && !!errors.lender_type}
                          helperText={touched.lender_type ? errors.lender_type : ''}
                          onChange={handleChange}
                        >
                          <option value="" disabled selected>
                            Select
                          </option>
                          <option value="Schedule 1 Bank">Schedule 1 Bank</option>
                          <option value="Credit Union">Credit Union</option>
                          <option value="Private Debt">Private Debt (Institutional)</option>
                          <option value="Private Debt (HNW)">Private Debt (HNW)</option>
                        </select>
                      </div>
                      <div className="  grid grid-cols-[130px_auto] items-center">
                        <label
                          className=" flex font-semibold text-customLightBlue1 font-lato align-baseline"
                          for="borrower"
                        >
                          Borrower
                        </label>
                        <TextField
                          className="flex w-[9rem] xl:w-[11rem] 2xl:w-[14rem] h-12   text-bca-grey-9 focus:border-bca-success-6 focus:outline-none text-sm border-0 bg-lavender font-lato border-[#42644C]  rounded-full text-center "
                          name="borrower"
                          id="borrower"
                          value={values.borrower}
                          error={touched.borrower && !!errors.borrower}
                          helperText={touched.borrower ? errors.borrower : ''}
                          onChange={handleChange}
                        />
                      </div>
                      <div className=" gap-x-8  grid grid-cols-[100px_auto]   items-center">
                        <TextField
                          className=" w-[9rem] xl:w-[11rem] 2xl:w-[14rem] h-12 "
                          label="Lender"
                          maxLength={50}
                          type="text"
                          id="lender"
                          name="lender"
                          value={values.lender}
                          error={touched.lender && !!errors.lender}
                          helperText={touched.lender ? errors.lender : ''}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="space-y-10">
                      <div className=" gap-x-8  grid grid-cols-[100px_auto] items-center">
                        <TextField
                          className=" w-[9rem] xl:w-[11rem] 2xl:w-[14rem] h-12 "
                          label="IAD"
                          maxLength={20}
                          type="date"
                          id="interest_adjustment_date"
                          name="interest_adjustment_date"
                          value={values.interest_adjustment_date}
                          error={
                            touched.interest_adjustment_date && !!errors.interest_adjustment_date
                          }
                          helperText={
                            touched.interest_adjustment_date ? errors.interest_adjustment_date : ''
                          }
                          onChange={handleChange}
                        />
                      </div>
                      <div className=" gap-x-8  grid grid-cols-[100px_auto] items-center">
                        <TextField
                          className=" w-[9rem] xl:w-[11rem] 2xl:w-[14rem] h-12 "
                          label="Loan Term"
                          maxLength={50}
                          type="number"
                          id="loan_term"
                          name="loan_term"
                          value={values.loan_term}
                          error={touched.loan_term && !!errors.loan_term}
                          helperText={touched.loan_term ? errors.loan_term : ''}
                          onChange={(event) => {
                            const value = event.target.value
                            setFieldValue('loan_term', value > 0 ? Math.floor(value) : '')
                          }}
                        />
                      </div>
                      <div className=" gap-x-8  grid grid-cols-[100px_auto] items-center">
                        <TextField
                          className=" w-[9rem] xl:w-[11rem] 2xl:w-[14rem] h-12 "
                          label="Maturity"
                          maxLength={20}
                          type="date"
                          id="maturity_date"
                          name="maturity_date"
                          value={values.maturity_date}
                          error={touched.maturity_date && !!errors.maturity_date}
                          helperText={touched.maturity_date ? errors.maturity_date : ''}
                          onChange={handleChange}
                        />
                      </div>

                      <div className=" gap-x-8  grid grid-cols-[100px_auto] items-center">
                        <TextField
                          className=" w-[9rem] xl:w-[11rem] 2xl:w-[14rem] h-12 "
                          label="Amount"
                          maxLength={50}
                          type="text"
                          id="committed_loan_amount"
                          name="committed_loan_amount"
                          value={values.committed_loan_amount}
                          error={touched.committed_loan_amount && !!errors.committed_loan_amount}
                          helperText={
                            touched.committed_loan_amount ? errors.committed_loan_amount : ''
                          }
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="space-y-10">
                      <div className="   grid grid-cols-[130px_auto]  items-center">
                        <label
                          className=" flex font-semibold text-customLightBlue1 font-lato align-baseline"
                          for="collateral"
                        >
                          Collateral
                        </label>
                        <TextField
                          className="flex w-[9rem] xl:w-[11rem] 2xl:w-[14rem] h-12  text-bca-grey-9 focus:border-bca-success-6 focus:outline-none text-sm border-0 bg-lavender font-lato border-[#42644C]  rounded-full text-center"
                          name="collateral"
                          id="collateral"
                          value={values.collateral}
                          error={touched.credit_type && !!errors.collateral}
                          helperText={touched.collateral ? errors.collateral : ''}
                          onChange={handleChange}
                        />
              
                      </div>
                      <div className=" grid grid-cols-[130px_auto]  items-center">
                        <label
                          className=" flex font-semibold text-customLightBlue1 font-lato align-baseline"
                          for="purpose"
                        >
                          Purpose
                        </label>
                        <select
                          className="flex  w-[9rem] xl:w-[11rem] 2xl:w-[14rem] h-12   text-bca-grey-9 focus:border-bca-success-6 focus:outline-none text-sm border-0 bg-lavender font-lato border-[#42644C]  rounded-full text-center"
                          name="purpose"
                          id="purpose"
                          value={values.purpose}
                          error={touched.purpose && !!errors.purpose}
                          helperText={touched.purpose ? errors.purpose : ''}
                          onChange={handleChange}
                        >
                          <option value="" selected disabled>
                            Select
                          </option>
                          <option value="Land Purchase">Land Purchase</option>
                          <option value="Pre-Construction">Pre-Construction</option>
                          <option value="Servicing">Servicing</option>
                          <option value="Construction">Construction</option>
                          <option value="Mezzanine">Mezzanine</option>
                          <option value="Bridge">Bridge</option>
                          <option value="Long-Term Debt">Long-Term Debt</option>
                        </select>
                      </div>
                      <div className="  grid grid-cols-[130px_auto] items-center">
                        <label
                          className=" flex font-semibold text-customLightBlue1 font-lato align-baseline"
                          for="exit"
                        >
                          Exit
                        </label>
                        <select
                          className="flex  w-[9rem] xl:w-[11rem] 2xl:w-[14rem] h-12   text-bca-grey-9 focus:border-bca-success-6 focus:outline-none text-sm border-0 bg-lavender font-lato border-[#42644C]  rounded-full text-center"
                          name="exit"
                          id="exit"
                          value={values.exit}
                          error={touched.exit && !!errors.exit}
                          helperText={touched.exit ? errors.exit : ''}
                          onChange={handleChange}
                        >
                          <option value="" selected disabled>
                            Select
                          </option>
                          <option value="Land Loan">Land Loan</option>
                          <option value="Pre-Construction Loan">Pre-Construction Loan</option>
                          <option value="Construction Loan">Construction Loan</option>
                          <option value="Bridge Loan">Bridge Loan</option>
                          <option value="Long Term Debt">Long Term Debt</option>
                          <option value="Sales Proceeds">Sales Proceeds</option>
                        </select>
                      </div>
                      <div className="  grid grid-cols-[130px_auto] items-center">
                        <label
                          className=" flex font-semibold text-customLightBlue1 font-lato align-baseline"
                          for="rate-type"
                        >
                          Rate Type
                        </label>
                        <select
                          className="flex  w-[9rem] xl:w-[11rem] 2xl:w-[14rem] h-12   text-bca-grey-9 focus:border-bca-success-6 focus:outline-none text-sm border-0 bg-lavender font-lato border-[#42644C]  rounded-full text-center "
                          name="interest_rate_type"
                          id="interest_rate_type"
                          value={values.interest_rate_type}
                          error={touched.interest_rate_type && !!errors.interest_rate_type}
                          helperText={touched.interest_rate_type ? errors.interest_rate_type : ''}
                          onChange={handleChange}
                        >
                          <option value="" selected disabled>
                            Select
                          </option>
                          <option value="Floating"> Floating</option>
                          <option value="Fixed">Fixed</option>
                        </select>
                      </div>
                    </div>
                  </form>

                  {/* project data form two */}
                  <form
                    className={twMerge(
                      'flex justify-between gap-4 pt-6 mb-16',
                      step === 2 ? '' : 'hidden'
                    )}
                  >
                    <div className="space-y-10">
                      <div className=" gap-x-8  grid grid-cols-[120px_auto] items-center">
                        <TextField
                          className=" w-[9rem] xl:w-[11rem] 2xl:w-[14rem] h-12 "
                          label="Accrued Split (%)"
                          type="text"
                          id="accrued_split"
                          maxLength={5}
                          name="accrued_split"
                          value={values.accrued_split}
                          error={touched.accrued_split && !!errors.accrued_split}
                          helperText={touched.accrued_split ? errors.accrued_split : ''}
                          onChange={(event) => {
                            const value = event.target.value
                            if (!value.match(/[a-zA-Z?#@$?"':;-]/)) {
                              setFieldValue('accrued_split', value)
                              setFieldValue('paid_split', `${Number(100 - value)}`)
                            }
                          }}
                        />
                      </div>
                      <div className=" gap-x-8  grid grid-cols-[120px_auto] items-center">
                        <TextField
                          className=" w-[9rem] xl:w-[11rem] 2xl:w-[14rem] h-12 "
                          label="Paid Split"
                          type="text"
                          id="paid_split"
                          name="paid_split"
                          value={values.paid_split}
                          error={touched.paid_split && !!errors.paid_split}
                          helperText={touched.paid_split ? errors.paid_split : ''}
                          onChange={handleChange}
                        />
                      </div>
                      <div className=" gap-x-8  grid grid-cols-[120px_auto] items-center">
                        <TextField
                          className=" w-[9rem] xl:w-[11rem] 2xl:w-[14rem] h-12 "
                          label="Floor Rate (%)"
                          type="text"
                          maxLength={5}
                          id="floor_rate"
                          name="floor_rate"
                          value={values.floor_rate}
                          error={touched.floor_rate && !!errors.floor_rate}
                          helperText={touched.floor_rate ? errors.floor_rate : ''}
                          onChange={(event) => {
                            const value = event.target.value
                            if (!value.match(/[a-zA-Z?#@$?"':;-]/)) {
                              setFieldValue('floor_rate', value)
                            }
                          }}
                        />
                      </div>

                      <div className=" gap-x-8  grid grid-cols-[120px_auto] items-center">
                        <TextField
                          className=" w-[9rem] xl:w-[11rem] 2xl:w-[14rem] h-12 "
                          label="Ceiling Rate (%)"
                          type="text"
                          id="ceiling_rate"
                          maxLength={3}
                          name="ceiling_rate"
                          value={values.ceiling_rate}
                          error={touched.ceiling_rate && !!errors.ceiling_rate}
                          helperText={touched.ceiling_rate ? errors.ceiling_rate : ''}
                          onChange={(event) => {
                            const value = event.target.value
                            if (!value.match(/[a-zA-Z?#@$?"':;-]/)) {
                              setFieldValue('ceiling_rate', value)
                            }
                          }}
                        />
                      </div>
                    </div>

                    <div className="space-y-10">
                      <div className=" gap-x-8  grid grid-cols-[100px_auto] items-center">
                        <TextField
                          className=" w-[9rem] xl:w-[11rem] 2xl:w-[14rem] h-12 "
                          label="Prime"
                          type="text"
                          id="reference_prime"
                          name="reference_prime"
                          value={values.reference_prime}
                          error={touched.reference_prime && !!errors.reference_prime}
                          helperText={touched.reference_prime ? errors.reference_prime : ''}
                          onChange={handleChange}
                        />
                      </div>
                      <div className=" gap-x-8  grid grid-cols-[100px_auto] items-center">
                        <TextField
                          className=" w-[9rem] xl:w-[11rem] 2xl:w-[14rem] h-12 "
                          label="Premium (%)"
                          type="text"
                          maxLength={5}
                          id="premium"
                          name="premium"
                          value={values.premium}
                          error={touched.premium && !!errors.premium}
                          helperText={touched.premium ? errors.premium : ''}
                          onChange={(event) => {
                            const value = event.target.value
                            if (!value.match(/[a-zA-Z?#@$?"':,;-]/)) {
                              setFieldValue('premium', value)
                            }
                          }}
                        />
                      </div>
                      <div className=" gap-x-8  grid grid-cols-[100px_auto] items-center">
                        <TextField
                          className=" w-[9rem] xl:w-[11rem] 2xl:w-[14rem] h-12 "
                          label="Frequency"
                          type="text"
                          id="compounding_frequency"
                          name="compounding_frequency"
                          value={values.compounding_frequency}
                          error={touched.compounding_frequency && !!errors.compounding_frequency}
                          helperText={
                            touched.compounding_frequency ? errors.compounding_frequency : ''
                          }
                          onChange={handleChange}
                        />
                      </div>

                      <div className=" gap-x-8  grid grid-cols-[100px_auto] items-center">
                        <TextField
                          className=" w-[9rem] xl:w-[11rem] 2xl:w-[14rem] h-12 "
                          label="Periodicity"
                          type="text"
                          id="periodicity"
                          name="periodicity"
                          value={values.periodicity}
                          error={touched.periodicity && !!errors.periodicity}
                          helperText={touched.periodicity ? errors.periodicity : ''}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="space-y-10">
                      <div className=" gap-x-8  grid grid-cols-[120px_auto] items-center">
                        <TextField
                          className=" w-[9rem] xl:w-[11rem] 2xl:w-[14rem] h-12 "
                          label="Financing ID"
                          type="text"
                          id="financing_id"
                          name="financing_id"
                          value={values.financing_id}
                          error={touched.financing_id && !!errors.financing_id}
                          helperText={touched.financing_id ? errors.financing_id : ''}
                          onChange={handleChange}
                        />
                      </div>
                      <div className=" gap-x-8  grid grid-cols-[120px_auto] items-center">
                        <TextField
                          className=" w-[9rem] xl:w-[11rem] 2xl:w-[14rem] h-12 "
                          label="Interest Free"
                          type="text"
                          id="interest_free_period"
                          name="interest_free_period"
                          value={values.interest_free_period}
                          error={touched.interest_free_period && !!errors.interest_free_period}
                          helperText={
                            touched.interest_free_period ? errors.interest_free_period : ''
                          }
                          onChange={handleChange}
                        />
                      </div>
                      <div className=" gap-x-8  grid grid-cols-[120px_auto] items-center">
                        <TextField
                          className=" w-[9rem] xl:w-[11rem] 2xl:w-[14rem] h-12 "
                          label="Payment Day"
                          type="text"
                          id="recurring_payment_day"
                          name="recurring_payment_day"
                          value={values.recurring_payment_day}
                          error={touched.recurring_payment_day && !!errors.recurring_payment_day}
                          helperText={
                            touched.recurring_payment_day ? errors.recurring_payment_day : ''
                          }
                          onChange={handleChange}
                        />
                      </div>

                      <div className=" gap-x-8  grid grid-cols-[120px_auto] items-center">
                        <TextField
                          className=" w-[9rem] xl:w-[11rem] 2xl:w-[14rem] h-12 "
                          label="Amortization"
                          type="text"
                          id="amortization"
                          name="amortization"
                          value={values.amortization}
                          error={touched.amortization && !!errors.amortization}
                          helperText={touched.amortization ? errors.amortization : ''}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </form>

                  {/* project data form three */}
                  <form
                    className={twMerge(
                      'flex justify-between gap-4 pt-6 mb-16 2xl:mb-0',
                      step === 3 ? '' : 'hidden'
                    )}
                  >
                    <div className="space-y-10 ">
                      <div className=" gap-x-8  grid grid-cols-[220px_auto] items-center">
                        <label
                          className=" flex font-semibold text-customLightBlue1 font-lato align-baseline w-40"
                          for="first_contact_date"
                        >
                          First Contact Date
                        </label>
                        <TextField
                          className=" w-[9rem] xl:w-[11rem] 2xl:w-[14rem] h-12"
                          type="date"
                          id="first_contact_date"
                          name="first_contact_date"
                          value={values.first_contact_date}
                          error={touched.first_contact_date && !!errors.first_contact_date}
                          helperText={touched.first_contact_date ? errors.first_contact_date : ''}
                          onChange={handleChange}
                        />
                      </div>
                      <div className=" gap-x-8  grid grid-cols-[220px_auto] items-center">
                        <TextField
                          className=" w-[9rem] xl:w-[11rem] 2xl:w-[14rem] h-12 "
                          label="Discussion Paper"
                          type="date"
                          id="discussion_paper"
                          name="discussion_paper"
                          value={values.discussion_paper}
                          error={touched.discussion_paper && !!errors.discussion_paper}
                          helperText={touched.discussion_paper ? errors.discussion_paper : ''}
                          onChange={handleChange}
                        />
                      </div>
                      <div className=" gap-x-8  grid grid-cols-[220px_auto] items-center">
                        <label
                          className=" flex font-semibold text-customLightBlue1 font-lato align-baseline w-60"
                          for="first_contact_date"
                        >
                          Letter of Interest Date
                        </label>
                        <TextField
                          className=" w-[9rem] xl:w-[11rem] 2xl:w-[14rem] h-12 "
                          type="date"
                          id="loi_date"
                          name="loi_date"
                          value={values.loi_date}
                          error={touched.loi_date && !!errors.loi_date}
                          helperText={touched.loi_date ? errors.loi_date : ''}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="space-y-10">
                      <div className=" gap-x-8  grid grid-cols-[220px_auto] items-center">
                        <label
                          className=" flex font-semibold text-customLightBlue1 font-lato align-baseline w-60"
                          for="first_contact_date"
                        >
                          Commitment Letter Date
                        </label>
                        <TextField
                          className=" w-[9rem] xl:w-[11rem] 2xl:w-[14rem] h-12 "
                          type="date"
                          id="commitment_letter_date"
                          name="commitment_letter_date"
                          value={values.commitment_letter_date}
                          error={touched.commitment_letter_date && !!errors.commitment_letter_date}
                          helperText={
                            touched.commitment_letter_date ? errors.commitment_letter_date : ''
                          }
                          onChange={handleChange}
                        />
                      </div>
                      <div className=" gap-x-8  grid grid-cols-[220px_auto] items-center">
                        <label
                          className=" flex font-semibold text-customLightBlue1 font-lato align-baseline w-60"
                          for="first_contact_date"
                        >
                          Finalized Agreement
                        </label>
                        <TextField
                          className=" w-[9rem] xl:w-[11rem] 2xl:w-[14rem] h-12 "
                          type="date"
                          id="finalized_agreement"
                          name="finalized_agreement"
                          value={values.finalized_agreement}
                          error={touched.finalized_agreement && !!errors.finalized_agreement}
                          helperText={touched.finalized_agreement ? errors.finalized_agreement : ''}
                          onChange={handleChange}
                        />
                      </div>
                      <div className=" gap-x-8  grid grid-cols-[220px_auto] items-center">
                        <TextField
                          className=" w-[9rem] xl:w-[11rem] 2xl:w-[14rem] h-12 "
                          label="Closing Date"
                          type="date"
                          id="closing_date"
                          name="closing_date"
                          value={values.closing_date}
                          error={touched.closing_date && !!errors.closing_date}
                          helperText={touched.closing_date ? errors.closing_date : ''}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </form>

                  {step === 3 && (
                    <Button
                      title="Save"
                      type="submit"
                      className="h-16 w-[120px] mt-20 mx-auto"
                      isSubmitting={isSubmitting}
                      onClick={handleSubmit}
                    />
                  )}
                </section>
              )}
            </Formik>
            <div className="xl:absolute w-full xl:w-[90%] xl:bottom-20">
              <ProgressBar progress={step === 1 ? 33.33 : step === 2 ? 66.67 : 100} />
            </div>
          </div>
        )
      )}
    </div>
  )
}
