import React, { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip } from 'chart.js';
import ReactDOM from 'react-dom';
import TooltipComponent from '../../../components/tool-tips/tooltipscomp'; 
import ProjectIcon from '../../../assets/Tooltip Icons/Project.svg';
import AmountIcon from '../../../assets/Tooltip Icons/Amount.svg';
import DateIcon from '../../../assets/Tooltip Icons/Date.svg';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip);

const colorPalette = [
  '#E6E6FA', '#E0B0FF', '#DA70D6', '#D8BFD8', '#CCCCFF',
  '#DDA0DD', '#9966CC', '#EE82EE', '#DF73FF', '#9370DB',
  '#CF9FFF', '#6F00FF'
];

const NetCashFlow = ({ data }) => {
  const uniqueProjects = useMemo(() => [...new Set(data.map(item => item.project))], [data]);

  const projectColors = useMemo(() => {
    return uniqueProjects.reduce((acc, project, index) => {
      acc[project] = colorPalette[index % colorPalette.length];
      return acc;
    }, {});
  }, [uniqueProjects]);

  const groupedData = useMemo(() => {
    return data.reduce((acc, cur) => {
      const [year, month] = cur.month.split('-').map(Number);
      const correctedDate = new Date(year, month - 1);
      const formattedMonth = correctedDate.toISOString().substring(0, 7);

      if (!acc[formattedMonth]) acc[formattedMonth] = [];
      acc[formattedMonth].push(cur);

      acc[formattedMonth].sort((a, b) => parseFloat(a.amount) - parseFloat(b.amount));

      return acc;
    }, {});
  }, [data]);

  const labels = useMemo(() => Object.keys(groupedData).sort(), [groupedData]);

  const datasets = useMemo(() => {
    // Initialize cumulativeDataPositive and cumulativeDataNegative inside the useMemo
    const cumulativeDataPositive = {};
    const cumulativeDataNegative = {};

    return uniqueProjects.map((project) => {
      const gradientColor = (ctx) => {
        const chart = ctx.chart;
        const { ctx: chartCtx, chartArea } = chart;
        if (!chartArea) {
          return null;
        }
        const gradient = chartCtx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        gradient.addColorStop(0, `${projectColors[project]}80`);
        gradient.addColorStop(1, projectColors[project]);
        return gradient;
      };

      const data = labels.map((month) => {
        const projectData = groupedData[month]?.find((d) => d.project === project);
        const value = projectData ? parseFloat(projectData.amount) || 0 : 0;

        if (value >= 0) {
          cumulativeDataPositive[month] = (cumulativeDataPositive[month] || 0) + value;
          return cumulativeDataPositive[month];
        } else {
          cumulativeDataNegative[month] = (cumulativeDataNegative[month] || 0) + value;
          return cumulativeDataNegative[month];
        }
      });

      return {
        label: project,
        backgroundColor: gradientColor,
        borderColor: projectColors[project],
        fill: true,
        data: data,
        tension: 0.4,
        pointRadius: 0,
        borderWidth: 5,
      };
    });
  }, [uniqueProjects, projectColors, groupedData, labels]);

  const chartData = useMemo(() => ({
    labels,
    datasets,
  }), [labels, datasets]);

  const options = useMemo(() => ({
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: function (context) {
          const tooltipModel = context.tooltip;
          if (!tooltipModel.opacity) {
            document.getElementById('chartjs-tooltip').style.opacity = 0;
            return;
          }

          const rawMonth = context.tooltip.title[0];
          const [year, month] = rawMonth.split('-').map(Number);
          const date = new Date(year, month - 1);

          const formattedMonth = date.toLocaleDateString('en-US', {
            month: 'short',
            year: 'numeric',
          }).replace(' ', ', ');

          const project = context.tooltip.dataPoints[0].dataset.label;
          const projectData = groupedData[rawMonth].find((d) => d.project === project);
          let amount = projectData ? projectData.amount : 0;
          amount = parseFloat(amount).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          });

          const icons = [
            <img src={DateIcon} className="w-4 h-4 mr-1" alt="Date" />,
            <img src={ProjectIcon} className="w-4 h-4 mr-1 text-customDarkBlue2" alt="Project" />,
            <img src={AmountIcon} className="w-4 h-4 mr-1" alt="Amount" />, 
          ];

          const fields = [
            { value: formattedMonth },
            { value: project },
            { value: amount }
          ];

          const tooltipElement = document.getElementById('chartjs-tooltip');
          tooltipElement.style.opacity = 1;
          tooltipElement.style.left = context.chart.canvas.offsetLeft + tooltipModel.caretX + 'px';
          tooltipElement.style.top = context.chart.canvas.offsetTop + tooltipModel.caretY + 'px';
          tooltipElement.style.font = tooltipModel.options.bodyFont.string;

          ReactDOM.render(
            <TooltipComponent fields={fields} icons={icons} />,
            tooltipElement
          );
        },
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: true,
          color: '#8a7ca6',
        },
        ticks: {
          display: true,
          callback: function (value) {
            const label = this.getLabelForValue(value);
            const [year, month] = label.split('-').map(Number);
            const date = new Date(year, month - 1);

            const formattedMonth = date.toLocaleDateString('en-US', { month: 'short' });
            const formattedYear = date.getFullYear().toString().slice(-2);

            return [`${formattedMonth}`, `'${formattedYear}`];
          },
          color: '#E6E6FA',
          font: {
            size: 7,
          },
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        grid: {
          display: true,
          color: '#8a7ca6',
        },
        ticks: {
          display: false,
        },
      },
    },
  }), [groupedData]);

  return (
    <div className="w-[85vw] h-[27vh] relative">
      <Line data={chartData} options={options} />
      <div id="chartjs-tooltip" className="absolute pointer-events-none"></div>
    </div>
  );
};

export default NetCashFlow;
