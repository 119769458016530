import { IoChevronBackCircleOutline, IoAddCircleOutline } from 'react-icons/io5'
import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAppContext } from 'context'
import { CircularProgress } from '@mui/material'
import { toast } from 'sonner'
import { useBankAccounts } from 'data/account'

export default function ProjectAccount() {
  const navigate = useNavigate()
  const { developer } = useAppContext()
  const [searchParams] = useSearchParams()

  const projectName = searchParams.get('project').replace(/-/g, ' ')

  const {
    data: accounts,
    isLoading: isLoadingAccounts,
    isError: isErrorFetchingAccounts,
  } = useBankAccounts({ developerName: developer.split(' ')?.[0], project: projectName })

  if (isErrorFetchingAccounts) {
    toast.error('Error fetching bank accounts')
  }

  return (
    <div className=" glass-card relative w-[80%] shadow-lg backdrop-blur-lg bg-lavender/5        relative border-r-2 border-b-2 border-white/30 mt-24 min-h-[65vh] max-h-[76vh]  overflow-y-hidden overflow-x-hidden rounded-[40px] px-16 py-8 justify-center justify-items-center">
      <div className="w-full text-center flex">
        <div className="capitalButton absolute left-0 w-2/12 ">
          <button onClick={() => navigate('/client-data-form')}>
            <IoChevronBackCircleOutline size={40} color="#ccc8f6" />
          </button>
        </div>
        <div className="capitalHeading w-full">
          <span className="bg-gradient-to-b from-[#da70d6] via-[#ccccff] to-[#e6e6fa] font-lato text-4xl text-transparent bg-clip-text  ">
            {projectName} Accounts
          </span>
        </div>
        <div className="capitalButton absolute right-0 w-2/12 ">
          <button onClick={() => navigate(`/add-account?project=${searchParams.get('project')}`)}>
            <IoAddCircleOutline size={40} color="#ccc8f6" />
          </button>
        </div>
      </div>
      {isLoadingAccounts ? (
        <div className=" grid place-items-center mt-36">
          <CircularProgress color="inherit" className="text-customLightBlue1" size={30} />
        </div>
      ) : (
        accounts &&
        (accounts.length ? (
          <div className="  max-h-[48vh]   pt-4 overflow-y-auto flex justify-center  ">
            <table className=" min-w-[80%] max-w-[90%]   rounded-md ">
              <thead className="font-lato font-semibold text-customLightBlue1  ">
                <tr className=" graph-title  text-left h-[10vh] ">
                  <th className=" graph-title font-semibold font-lato   tracking-wider text-customLightBlue1">
                    Account Type
                  </th>
                  <th className="font-semibold  font-lato   tracking-wider text-customLightBlue1">
                    Institution
                  </th>
                  <th className="  font-semibold  font-lato   tracking-wider text-customLightBlue1">
                    Account Number
                  </th>
                  <th className=" font-semibold font-lato   tracking-wider text-customLightBlue1">Currency</th>
                </tr>
              </thead>
              <tbody>
                {accounts.map((account) => (
                  <tr
                    className="hover:bg-[#9370db] cursor-pointer align-middle"
                    onClick={() => navigate(`/account/${account.id}`)}
                  >
                    <td className=" py-3 text-sm capitalize font-lato tracking-wider text-customLightBlue1 rounded-l-lg">
                      {account.account_type}
                    </td>
                    <td className=" py-3 text-sm font-lato tracking-wider text-customLightBlue1">
                      {account.institution_name}
                    </td>
                    <td className=" py-3 text-sm font-lato tracking-wider text-customLightBlue1">
                      Ending in {account.account_ending}
                    </td>
                    <td className=" py-3 text-sm font-lato tracking-wider text-customLightBlue1 rounded-r-lg ">
                      {account.currency}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className=" mt-40 w-full grid place-items-center graph-title  font-lato font-semibold text-lg text-customLightBlue1">
            You haven't added an account
          </p>
        ))
      )}
    </div>
  )
}
