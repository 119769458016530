// Importing required libraries and components
import React, { useState } from 'react' // Import React and useState hook
import { DeveloperDropdown } from './developer-dropdown' // Import DeveloperDropdown component
import { twMerge } from 'tailwind-merge' // Utility to merge Tailwind CSS classes
import Backdrop from '@mui/material/Backdrop' // Import Material UI Backdrop component
import axios from 'axios' // Import axios for HTTP requests
import { IoCamera } from 'react-icons/io5' // Import close and camera icons
import { Tooltip, CircularProgress } from '@mui/material' // Import Material UI Tooltip and CircularProgress
import { uploadImage } from 'lib/api/client' // Import custom function to upload images
import { toast } from 'sonner' // Import toast notification library
import { CURRENT_DEVELOPER_ORG_NAME } from 'lib/constants' // Import constant for developer organization name key
import { useAppContext, useAuthActions } from 'context' // Import context for global state and authentication actions
import { useEffect } from 'react' // Import useEffect hook
import { recalculateAllFunctions } from 'lib/api/liquidity' // Import function to recalculate liquidity-related functions
import { MenuDropdown } from '../menu-dropdown' // Import custom MenuDropdown component
import { FaSyncAlt, FaSignOutAlt} from 'react-icons/fa';
import { IoMdCloseCircle } from "react-icons/io";

// SideBar component to display the sidebar UI and functionality
export default function SideBar({ close, open, profile, isLoadingProfile, refetchProfile }) {
  const { updateDeveloper } = useAuthActions() // Get the updateDeveloper function from context
  const { developer } = useAppContext() // Get the current developer from global context

  // State management
  const [image, setImage] = useState(null) // State for profile image
  const [isSubmitting, setSubmitting] = useState(false) // State to track if the image upload is in progress
  const [recalculating, setRecalculating] = useState(false) // State to track if recalculation is in progress

  // useEffect to set the developer if the profile is available and no developers are set
  useEffect(() => {
    if (profile && !profile?.payload?.developers) {
      const currentDeveloper = sessionStorage.getItem(CURRENT_DEVELOPER_ORG_NAME);
      const newDeveloper = profile?.payload?.user?.organizationName;
  
      // Only update if the developer has changed
      if (newDeveloper !== currentDeveloper) {
        sessionStorage.setItem(CURRENT_DEVELOPER_ORG_NAME, newDeveloper);
        updateDeveloper({ developer: newDeveloper });
      }
    }
  }, [profile, updateDeveloper]); // Dependencies: profile and updateDeveloper

  // Function to handle image upload submission
  async function handleSubmit(imageFile) {
    try {
      setSubmitting(true) // Set submission state to true
      const formData = new FormData() // Create a new FormData object to send image
      formData.append('file', imageFile) // Append the image file to FormData
      await uploadImage({ payload: formData }) // Upload the image using the API
      refetchProfile() // Refetch the profile after upload
      // Show success message depending on whether the user had a previous image
      toast.success(
        profile?.payload?.user?.image_base
          ? 'You have successfully changed your profile picture'
          : 'You have successfully uploaded your profile picture'
      )
    } catch (error) {
      // Handle errors and display error message
      toast.error(error?.message ?? 'An error occurred while uploading image')
    } finally {
      setSubmitting(false) // Set submission state to false after completion
    }
  }

  // Function to handle user logout
  const handleLogout = async () => {
    // Clear authentication headers and tokens from sessionStorage
    axios.defaults.headers.common.Authorization = ''
    window.sessionStorage.removeItem('WEB_APP_TOKEN')
    window.sessionStorage.removeItem('WEB_APP_REFRESH_TOKEN')
    // Set a logout key to ensure logout across all tabs/windows
    window.sessionStorage.setItem('WEB_LOGOUT_KEY', Date.now().toString())
    window.location.replace('/') // Redirect to the homepage after logout
  }

  // Function to handle recalculation of functions
  const handleRecalculate = async () => {
    try {
      setRecalculating(true) // Set recalculating state to true
      await recalculateAllFunctions({
        developerName: developer.split(' ')?.[0], // Use the first part of the developer name
      })
      // Show success message after recalculation
      toast.success('You have successfully triggered functions recalculation')
    } catch (error) {
      // Handle errors and show an error message
      toast.error(
        error.message ??
          error.detail ??
          'An error occurred while triggering functions recalculation'
      )
    } finally {
      setRecalculating(false) // Set recalculating state to false
    }
  }

  return (
    // Render the sidebar with a backdrop
    <Backdrop sx={{ color: '#fff', zIndex: '100' }} open={open}>
      <div
        className={twMerge(
          // Merge Tailwind classes for styling the sidebar
          'h-full overflow-hidden absolute right-0 bg-customLightBlue1 bg-opacity-85 z-50 px-1 xl:px-2 py-5 focus:outline-none w-[14rem] xl:w-[16rem] 2xl:w-[22rem]',
          open ? '' : 'hidden' // Conditionally render based on whether the sidebar is open
        )}
      >
        {/* Close button for the sidebar */}
        <div className="flex w-full justify-start pt-1 pl-2">
          <button onClick={close} className="outline-none">
            <IoMdCloseCircle color="#180d2b" size={20} /> {/* Close icon */}
          </button>
        </div>

        {/* If the profile is still loading, show a loading indicator */}
        {isLoadingProfile ? (
          <div className="grid place-items-center mt-36">
            <CircularProgress color="inherit" className="text-customDarkBlue2" size={30} /> {/* Loading spinner */}
          </div>
        ) : (
          // Profile content display after loading
          <div className="flex flex-col pt-5 items-center">
            {/* Tooltip for changing/uploading the profile picture */}
            <Tooltip
              // Position and offset for the tooltip
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -14],
                      },
                    },
                  ],
                },
              }}
              title={
                <span className="text-sm">
                  {profile?.payload?.user?.image_base
                    ? 'Change profile picture'
                    : 'Upload profile picture'} {/* Tooltip text */}
                </span>
              }
              placement="top"
              className="relative group"
            >
              {/* Display the profile image or default image */}
              <div className="relative grid place-items-center">
                <img
                  src={
                    image
                      ? image
                      : profile?.payload?.user?.image_base
                        ? `data:image/png;base64,${profile?.payload?.user?.image_base}`
                        : null
                  }
                  alt=""
                  style={{
                    background: `${image || profile?.payload?.user?.image_base ? `url('')` : `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/assets/images/default-user-image.png")`}`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                  className="w-[5rem] rounded-full h-[5rem] object-cover 2xl:w-[7rem] 2xl:h-[7rem]" // Profile image styling
                />
                {/* If there's no image, show a camera icon */}
                {!image && !profile?.payload?.user?.image_base && (
                  <IoCamera color="#E6E6FA" size={20} className="absolute" />
                )}
              </div>

              {/* Overlay camera icon on hover if the user has an image */}
              {(profile?.payload?.user?.image_base || image) && (
                <div
                  className="opacity-0 cursor-pointer absolute !transition-[opacity,translate] group-hover:opacity-100 top-0 grid place-items-center w-[5rem] rounded-full h-[5rem] 2xl:w-[7rem] 2xl:h-[7rem]"
                  style={{
                    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  <IoCamera color='#E6E6FA' size={20} className="absolute" />
                </div>
              )}

              {/* File input for image upload */}
              <input
                type="file"
                name="profile-image"
                accept="image/*"
                disabled={isSubmitting} // Disable input if submitting
                onChange={async (e) => {
                  if (e.target.files?.[0]) {
                    const reader = new FileReader() // Create a file reader to read the image
                    reader.readAsDataURL(e.target.files[0])
                    reader.onload = function () {
                      setImage(reader.result) // Set the image state to the uploaded file
                    }
                    handleSubmit(e.target.files[0]) // Call the handleSubmit function to upload the image
                  }
                }}
                className="absolute inset-0 opacity-0 cursor-pointer"
              />
            </Tooltip>

            {/* Display backdrop and loader when submitting or recalculating */}
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isSubmitting || recalculating} // Open when either submitting or recalculating
            >
              <span className="flex flex-col items-center">
                <CircularProgress color="inherit" /> {/* Circular progress loader */}
                <span className="mt-2">
                  {recalculating ? 'Recalculating...' : isSubmitting ? 'Uploading...' : ''}
                </span>
              </span>
            </Backdrop>

            {/* Display user's name */}
            <p className="text-xl 2xl:text-2xl text-customDarkBlue2 font-lato font-bold py-4">
              {profile?.payload?.user?.firstName} {profile?.payload?.user?.lastName?.[0]}.
            </p>

            {/* Display developer dropdown if developers exist in the profile */}
            {profile?.payload?.developers ? (
              profile?.payload?.developers?.length ? (
                <DeveloperDropdown
                  options={profile?.payload?.developers?.map((item) => ({
                    id: item?.developer?.id,
                    label: item.developer?.organizationName,
                    value: item.developer?.organizationName.split(' ')?.[0],
                  }))}
                />
              ) : (
                <p className="text-sm text-customDarkBlue2 font-lato">No assigned developer yet</p>
              )
            ) : (
              <div
                className="bg-gradient-to-r from-[#9a3eb5] via-[#6843b3] to-[#330075] grid justify-between px-6 2xl:px-8 items-center grid-cols-[auto_auto] w-[90%] h-12 2xl:h-14  font-lato rounded-full"
              >
                {/* Display organization name if no developers exist */}
                <span className="text-base 2xl:text-base text-lavender text-bold text-center">
                  {profile?.payload?.user?.organizationName}
                </span>
              </div>
            )}
              
            {/* Display menu dropdown for navigation */}
            <MenuDropdown profile={profile} />
              
              {/* Buttons for recalculation and logout */}
             <div style={{ position: 'absolute', bottom: '20px', width: '250px', paddingLeft: '15px' }}> 
            <button onClick={handleRecalculate} disabled={recalculating}
              className="flex items-center cursor-pointer text-center w-[90%] px-12 py-3 font-lato text-base text-customDarkBlue2 rounded-2xl border-b-2  border-customDarkBlue2/20 hover:bg-darkrinkle"
              >
              <FaSyncAlt style={{ marginRight:'10px', size:'30px' }} />
              <span>Recalculate</span>
            </button>
            <button onClick={handleLogout}
              className="flex items-center cursor-pointer text-center w-[90%] px-12 py-3 font-lato text-base text-customDarkBlue2 rounded-2xl border-b-2  border-customDarkBlue2/20 hover:bg-darkrinkle"
              >
              <FaSignOutAlt style={{ marginRight:'10px', size:'30px' }} />
              <span>Log Out</span>
            </button>
            </div>
          </div>
        )}
      </div>
    </Backdrop>
  )
}
