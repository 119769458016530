import React from 'react';
import { twMerge } from 'tailwind-merge';
import GlassCard from './debt-components/DebtsGlasscard'; // Adjust the path if necessary
import MaturityBarChart from './debt-components/MaturityBarChart';
import ExpectedFinBarChart from './debt-components/ExpectedFinBarChart';
import MilestoneTracker from './debt-components/FinancingMilstoneTracker';
import CreditExposureCredit from './debt-components/CreditExposurePie';
import InterestLTVTracker from './debt-components/InterestLTV';
import {
  useCreditExposureCredits,
  useCreditExposureInterests,
  useCreditExposureLenders,
  useCreditTermCoverage,
  useExpectedFinancing,
  useLoanToValue,
  useMilestoneTracker,
  useUpcomingMaturities,
} from 'data/debt';
import { useAppContext } from 'context';
import { CircularProgress } from '@mui/material';
import { toast } from 'sonner';
import { useDeveloperProjects } from 'data/project';
import ExpectedFinancingIcon from "../../assets/Title Icons/ExpectedFinancings.svg";
import CreditExposureIcon  from "../../assets/Title Icons/CreditExposure.svg";
import UpcomingMaturitiesIcon from "../../assets/Title Icons/UpcomingMaturities.svg";
import MilestoneTrackerIcon from "../../assets/Title Icons/FinancingMilestoneTracker.svg";


export default function Debt() {
  const { developer } = useAppContext();

  // Extract the first word of the developer
  const developerName = developer?.split(' ')?.[0];

  // Hooks for fetching data
  const {
    data: projects,
    isLoading: isLoadingProjects,
    isError: isErrorFetchingProjects,
  } = useDeveloperProjects({ developer: developerName });

  const creditExposureLenders = useCreditExposureLenders({ developer: developerName });
  const creditExposureCredits = useCreditExposureCredits({ developer: developerName });
  const creditExposureInterests = useCreditExposureInterests({ developer: developerName });
  const upcomingMaturities = useUpcomingMaturities({ developer: developerName });
  const loanToValue = useLoanToValue({ developer: developerName });
  const expectedFinancing = useExpectedFinancing({ developer: developerName });
  const creditTermCoverage = useCreditTermCoverage({ developer: developerName });
  const milestoneTracker = useMilestoneTracker({ developer: developerName });

  // Handle early return if developer is not available
  if (!developer) {
    return <div>Developer information not available</div>;
  }

  // Error handling for project fetching
  if (isErrorFetchingProjects) {
    toast.error('Error fetching developer projects');
    return null; // Stop further rendering if there's an error
  }

  // Error handling for individual debt-related data sources
  if (
    creditExposureInterests.isError ||
    creditExposureLenders.isError ||
    creditExposureCredits.isError ||
    upcomingMaturities.isError ||
    loanToValue.isError ||
    expectedFinancing.isError ||
    creditTermCoverage.isError ||
    milestoneTracker.isError
  ) {
    toast.error('An error occurred while fetching graph information');
    return null; // Stop further rendering if there's an error
  }

  // Show loading spinner if data is being fetched
  if (
    isLoadingProjects ||
    creditExposureLenders.isLoading ||
    creditExposureCredits.isLoading ||
    creditExposureInterests.isLoading ||
    upcomingMaturities.isLoading ||
    loanToValue.isLoading ||
    expectedFinancing.isLoading ||
    creditTermCoverage.isLoading ||
    milestoneTracker.isLoading
  ) {
    return <CircularProgress />;
  }

  // Graceful handling for empty data with explicit checks
  const isCreditExposureEmpty = !creditExposureCredits.data || creditExposureCredits.data.length === 0;
  const isUpcomingMaturitiesEmpty = !upcomingMaturities.data || upcomingMaturities.data.length === 0;
  const isExpectedFinancingEmpty = !expectedFinancing.data || expectedFinancing.data.length === 0;
  const isMilestoneTrackerEmpty = !milestoneTracker.data || milestoneTracker.data.length === 0;
  const isLoanToValueEmpty = !loanToValue.data || loanToValue.data.length === 0;

  if (
    isCreditExposureEmpty &&
    isUpcomingMaturitiesEmpty &&
    isExpectedFinancingEmpty &&
    isMilestoneTrackerEmpty &&
    isLoanToValueEmpty
  ) {
    return <div  className="text-base font-light pt-20 text-customLightBlue1 font-lato glow-effect">No debt data available for this developer</div>;
  }

  // If no data is available, provide fallbacks within each card
  return (
    <div className="bg-my-background-svg bg-cover bg-center h-screen w-full flex items-center justify-center">
      <div className="flex flex-wrap justify-between items-start p-[1%] pt-[3%] pb-[1%] w-full h-full box-border">
        {!isLoanToValueEmpty ? (
        <InterestLTVTracker data={loanToValue.data} />
        ) : (
          <div className="text-base font-light mb-1 h-[7vh] w-full text-customLightBlue1 font-lato glow-effect">No LTV data available</div>
          )}
        <GlassCard title="Upcoming Maturities" iconSrc={UpcomingMaturitiesIcon}>
          {!isUpcomingMaturitiesEmpty ? (
            <MaturityBarChart data={upcomingMaturities.data} />
          ) : (
            <div className="text-base font-light mb-2 text-customLightBlue1 font-lato glow-effect">No upcoming maturities data available</div>
          )}
        </GlassCard>

        {!isCreditExposureEmpty ? (
          <CreditExposureCredit 
            creditData={creditExposureCredits.data} 
            interestData={creditExposureInterests.data} 
            lenderData={creditExposureLenders.data} 
            iconSrc={CreditExposureIcon} 
          />
        ) : (
          <GlassCard iconSrc={CreditExposureIcon} dynamicTitle="Credit Exposure">
            <div className="text-base font-light mb-2 text-customLightBlue1 font-lato glow-effect">
              No credit exposure data available
            </div>
          </GlassCard>
        )}


        <GlassCard title="Financing Milestone Tracker" iconSrc={MilestoneTrackerIcon}>
          {!isMilestoneTrackerEmpty ? (
            <MilestoneTracker data={milestoneTracker.data} />
          ) : (
            <div className="text-base font-light mb-2 text-customLightBlue1 font-lato glow-effect">No milestone tracker data available</div>
          )}
        </GlassCard>

        <GlassCard title="Expected Financings" iconSrc={ExpectedFinancingIcon}>
          {!isExpectedFinancingEmpty ? (
            <ExpectedFinBarChart data={expectedFinancing.data} />
          ) : (
            <div className="text-base font-light mb-2 text-customLightBlue1 font-lato glow-effect">No expected financings data available</div>
          )}
        </GlassCard>
      </div>
    </div>
  );
}
