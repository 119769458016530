import React, { useState, useMemo } from 'react';
import { FaProjectDiagram, FaTag, FaBullseye, FaCalendarAlt, FaDollarSign } from 'react-icons/fa';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

const MaturityBarChart = ({ data }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  // Function to convert string values to numbers
  const convertToNumbers = (entry) => ({
    ...entry,
    amount: parseFloat(entry.amount),
  });

  // Memoize numericData so it only recalculates if the data prop changes
  const numericData = useMemo(() => data.map(convertToNumbers), [data]);

  const renderTooltip = ({ payload }) => {
    if (payload && payload.length) {
      const { project, loan_name, purpose, maturity_date, amount } = payload[0].payload;

      const tooltipData = [
        { icon: <FaProjectDiagram className="mr-1" />, label: project },
        { icon: <FaTag className="mr-1" />, label: loan_name },
        { icon: <FaBullseye className="mr-1" />, label: purpose },
        { icon: <FaCalendarAlt className="mr-1" />, label: maturity_date },
        { icon: <FaDollarSign className="mr-1" />, label: `$${amount.toLocaleString()}` }, // Corrected string interpolation here
      ];

      return (
        <div className="p-4 rounded-lg bg-gradient-to-r from-customLightBlue1 to-white border-r-2 border-b-2 border-customMidBlue3 shadow-lg opacity-95 transform transition duration-200 ease-in-out">
          <table className="table-auto text-customDarkBlue2 font-lato">
            <tbody>
              {tooltipData.map((item, index) => (
                <tr key={index}>
                  <td className="pr-2 font-bold border-r-2 border-customMidBlue3">
                    <span className="inline-flex items-center">{item.icon}</span>
                  </td>
                  <td className="pl-2">{item.label}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
    return null;
  };

  const CustomBar = (props) => {
    const { fill, x, y, width, height, index } = props;
    const topRadius = Math.min(20, height); // Ensure the radius doesn't exceed the height

    return (
      <g filter="url(#glow)">
        <path
          d={`
            M${x},${y + height} 
            h${width} 
            v-${height - topRadius} 
            a${topRadius},${topRadius} 0 0 0 -${topRadius},-${topRadius} 
            h-${width - 2 * topRadius} 
            a${topRadius},${topRadius} 0 0 0 -${topRadius},${topRadius} 
            z
          `}
          fill={fill}
          style={{
            filter: hoveredIndex === index ? 'brightness(1.2)' : 'none', // Apply brightness on hover
            cursor: 'pointer',
            transition: 'filter 0.3s ease',
          }}
          onMouseEnter={() => setHoveredIndex(index)} // Set hover state
          onMouseLeave={() => setHoveredIndex(null)} // Reset hover state
        />
      </g>
    );
  };

  return (
    <div className="w-full h-full">
      <ResponsiveContainer width="100%" height="80%">
        <BarChart data={numericData} margin={{ top: 0, right: 30, left: 20, bottom: 5 }}>
          <XAxis dataKey="id" hide={true} />
          <YAxis hide={true} />
          <Tooltip content={renderTooltip} cursor={{ fill: 'transparent' }} />
          <Bar 
            dataKey="amount" 
            fill="url(#colorGradient)" 
            shape={<CustomBar />} 
            isAnimationActive={true} // Enable animation
            animationBegin={0} // Start animation immediately
            animationDuration={1500} // Duration in milliseconds
            animationEasing="ease" // Easing type
          />
          <defs>
            <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#ccc8f6" />
              <stop offset="100%" stopColor="rgba(204, 200, 246, 0.7)" />
            </linearGradient>
            <filter id="glow" x="-50%" y="-50%" width="200%" height="200%">
              <feGaussianBlur stdDeviation="4.5" result="coloredBlur" />
              <feMerge>
                <feMergeNode in="coloredBlur" />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
          </defs>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MaturityBarChart;
