import React from 'react';
import AppLogo from '../../../assets/images/AppLogo.png';

function Header() {
    return (
        <header className="fixed top-0 left-0 z-30">
                <a href="/">
                    <img src={AppLogo} alt="App Logo" className="h-20 pl-5" />
                </a>
        </header>
    );
}

export default Header;