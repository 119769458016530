// Import necessary constants, libraries, and components
import { REPORTS_LIST } from 'lib/constants'; // Import list of reports
import * as Dropdown from '@radix-ui/react-dropdown-menu'; // Import Dropdown components from Radix UI
import { twMerge } from 'tailwind-merge'; // Utility to merge Tailwind CSS classes
import { useState } from 'react'; // Import useState hook for managing component state
import { FaFileDownload, FaCloudDownloadAlt  } from 'react-icons/fa';

import { useAppContext } from 'context'; // Import custom context to access global state

// Define the ReportsDropDown component
export default function ReportsDropDown() {
  const { developer, tokenContent } = useAppContext(); // Get the current developer and tokenContent from the global context
  const [downloadReportName, setDownloadReportName] = useState(''); // State to track the name of the currently selected report

  // Function to handle report download and open in a new tab
  const handleDownload = async (url) => {
    try {
      // Include the authentication token in the request headers
      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${tokenContent}`, // Use tokenContent as the authentication token
        },
      });

      // Check if the response is OK (status code is in the 200–299 range)
      if (!response.ok) {
        // Throw an error with the status text (e.g., "Forbidden" or "Not Found")
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      // Process the response data (PDF in Blob format)
      const data = await response.blob(); // Convert the response into a Blob (binary data)
      const blobUrl = window.URL.createObjectURL(data); // Create a temporary URL for the Blob

      // Open the PDF in a new tab
      window.open(blobUrl); // This will open the PDF in a new browser tab

      // Trigger the download of the PDF
      const aTag = document.createElement('a'); // Create an anchor element
      const filename = url.split('/').pop(); // Extract the file name from the URL
      aTag.href = blobUrl; // Set the href of the anchor to the Blob URL
      aTag.setAttribute('download', filename ?? ''); // Set the download attribute to specify the filename
      document.body.appendChild(aTag); // Append the anchor element to the document body
      aTag.click(); // Simulate a click to trigger the download
      aTag.remove(); // Clean up by removing the anchor element
    } catch (error) {
      // Log the error to the console for debugging
      console.error('Download failed:', error.message);
    }
  };

  return (
    // Dropdown root element to handle the dropdown functionality
    <Dropdown.Root>
      {/* Dropdown trigger element (button) that opens the dropdown when clicked */}
      <Dropdown.Trigger className="focus:outline-none w-full">
        <div className="flex items-center cursor-pointer text-center w-full px-10 2xl:px-10 py-3 font-lato text-base text-customDarkBlue2 rounded-2xl border-b-2  border-customDarkBlue2/20 hover:bg-darkrinkle">
          <FaFileDownload style={{ marginRight: '10px', size: '35px' }} />
          Reports {/* Button label */}
        </div>
      </Dropdown.Trigger>

      {/* Portal to render dropdown content outside the DOM hierarchy */}
      <Dropdown.Portal>
        {/* Dropdown content styling with Tailwind CSS classes and animations */}
        <Dropdown.Content
          className={twMerge(
            'z-[100] data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideDownAndFade',
            'min-w-[10rem] rounded-3xl border border-darkrinkle bg-customLightBlue1 2 px-5 py-4 shadow-[0px_7px_7px_-2px_#10182808]',
            ' text-customDarkBlue2 mt-2 font-lato will-change-[opacity,transform] data-[side=bottom]:animate-slideUpAndFade'
          )}
          align="start" // Align dropdown to the start of the trigger
          alignOffset={30} // Adjust alignment offset
          sideOffset={-50} // Offset for dropdown placement
          side="left" // Position the dropdown to the left of the trigger
        >
          {/* Group of dropdown items */}
          <Dropdown.Group className="px-4">
            {/* Loop through the list of reports */}
            {REPORTS_LIST.map(({ label, value, url }) => (
              <button
                key={value} // Unique key for each report
                className="flex items-center cursor-pointer text-center w-full px-4 2xl:px-4 font-lato text-base text-customDarkBlue2 rounded-3xl border-b-2 border-darkrinkle hover:bg-darkrinkle"
                onClick={() => {
                  // Update the state with the selected report name
                  setDownloadReportName(value);
                  // Trigger the report download using the developer's subdomain
                  handleDownload(`https://${developer.split(' ')?.[0]}.alphamadesimple.ca/${url}`);
                }}
              >
                {/* Anchor element to link to the report */}
                <div className="flex w-full gap-x-2 items-center cursor-pointer text-center px-7 py-3 font-lato text-sm outline-none">
                  {/* Show a checkmark if the report is the one currently selected for download */}
                  <span className={downloadReportName === value ? 'visible' : 'invisible'}>
                    <FaCloudDownloadAlt color="#180d2b" size={20} />
                  </span>
                  {/* Display the report label */}
                  <span>{label}</span>
                </div>
              </button>
            ))}
          </Dropdown.Group>
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  );
}
