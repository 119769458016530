import makeApiCall from '.'

export async function uploadInterestRate({ payload }) {
  return await makeApiCall('/f-admin/upload/interest-rate-path/', 'post', payload)
}

export async function uploadPrimeSchedule({ payload }) {
  return await makeApiCall('/f-admin/upload/prime-schedule/', 'post', payload)
}

export async function uploadFundingTimeline({ payload }) {
  return await makeApiCall('/f-admin/upload/average-funding-timeline/', 'post', payload)
}
