import { addMonths, format, isBefore, subDays, isEqual, isAfter } from 'date-fns'

export const getNextDayDate = (dayName) => {
  // The current day
  const currentDate = new Date()
  const currentDay = currentDate.getDay()

  // Days of the week
  const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

  // The index for the day you want
  const day = days.indexOf(dayName.toLowerCase())

  // Find the difference between the current day and the one you want
  // If it's the same day as today (or a negative number), jump to the next week
  let diff = day - currentDay
  diff = diff < 1 ? 7 + diff : diff

  // Get the timestamp for the desired day
  const nextDayTimestamp = currentDate.getTime() + 1000 * 60 * 60 * 24 * diff

  // Get the next day
  return format(new Date(nextDayTimestamp), 'dd/MM/yyyy')
}

export const getMonthFirstDate = () => {
  let date = new Date()
  let firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
  return format(new Date(firstDay), 'dd/MM/yyyy')
}

export const getFileInfo = (currentDueDate, createdDate, fileUploadStatus) => {
  const currentDay = new Date().getDay()
  let diff = Math.abs(currentDay - 1)
  const previousDueDate = subDays(new Date(), diff)

  if (format(new Date(), 'E') === 'Mon') {
    return fileUploadStatus === true
      ? { date: currentDueDate, status: 'Outstanding' }
      : format(new Date(createdDate), 'dd/MM/yyyy') === format(new Date(), 'dd/MM/yyyy')
        ? { date: currentDueDate, status: 'Outstanding' }
        : { date: format(new Date(), 'dd/MM/yyyy'), status: 'Past due' }
  } else {
    return fileUploadStatus === true
      ? { date: currentDueDate, status: 'Done' }
      : isBefore(new Date(createdDate), new Date(previousDueDate))
        ? { date: format(new Date(previousDueDate), 'dd/MM/yyyy'), status: 'Past due' }
        : { date: currentDueDate, status: 'Outstanding' }
  }
}

export const getMonthlyFileInfo = (createdDate, fileUploadStatus) => {
  const date = new Date()

  const nextMonthDate = addMonths(date, 1)

  const currentDueDate = `01/${format(new Date(nextMonthDate), 'MM/yyyy')}`

  if (format(date, 'MM') === '01') {
    return fileUploadStatus === true
      ? { date: currentDueDate, status: 'Outstanding' }
      : format(new Date(createdDate), 'dd/MM/yyyy') === format(date, 'dd/MM/yyyy')
        ? { date: currentDueDate, status: 'Outstanding' }
        : { date: format(date, 'dd/MM/yyyy'), status: 'Past due' }
  } else {
    return fileUploadStatus === true
      ? { date: currentDueDate, status: 'Done' }
      : isBefore(new Date(createdDate), new Date(date.getFullYear(), date.getMonth(), 1))
        ? {
            date: format(new Date(date.getFullYear(), date.getMonth(), 1), 'dd/MM/yyyy'),
            status: 'Past due',
          }
        : { date: currentDueDate, status: 'Outstanding' }
  }
}

export function groupBy(array, key) {
  return array.reduce((result, currentValue) => {
    const groupKey = currentValue[key]
    if (!result[groupKey]) {
      result[groupKey] = []
    }
    result[groupKey].push(currentValue)
    return result
  }, {})
}

export const getScheduleFeasibilityIcons = ({
  initialCompletionDate,
  currentCompletionDate,
  debtMaturityDate,
}) => {
  const iconsNameArray = []

  if (
    isBefore(currentCompletionDate, debtMaturityDate) &&
    isBefore(currentCompletionDate, initialCompletionDate)
  ) {
    iconsNameArray.push('calendar')
    if (isBefore(initialCompletionDate, debtMaturityDate)) {
      iconsNameArray.push('target')
      iconsNameArray.push('dollar')
    } else if (isBefore(debtMaturityDate, initialCompletionDate)) {
      iconsNameArray.push('dollar')
      iconsNameArray.push('target')
    }
  } else if (
    isBefore(debtMaturityDate, currentCompletionDate) &&
    isBefore(debtMaturityDate, initialCompletionDate)
  ) {
    iconsNameArray.push('dollar')
    if (isBefore(initialCompletionDate, currentCompletionDate)) {
      iconsNameArray.push('target')
      iconsNameArray.push('calendar')
    } else if (isBefore(currentCompletionDate, initialCompletionDate)) {
      iconsNameArray.push('calendar')
      iconsNameArray.push('target')
    } else if (isEqual(currentCompletionDate, initialCompletionDate)) {
      iconsNameArray.push('target')
    }
  } else if (
    isBefore(initialCompletionDate, currentCompletionDate) &&
    isBefore(initialCompletionDate, debtMaturityDate)
  ) {
    iconsNameArray.push('target')
    if (isBefore(currentCompletionDate, debtMaturityDate)) {
      iconsNameArray.push('calendar')
      iconsNameArray.push('dollar')
    } else if (isBefore(debtMaturityDate, currentCompletionDate)) {
      iconsNameArray.push('dollar')
      iconsNameArray.push('calendar')
    }
  } else if (isEqual(initialCompletionDate, currentCompletionDate)) {
    if (isBefore(initialCompletionDate, debtMaturityDate)) {
      iconsNameArray.push('target')
      iconsNameArray.push('dollar')
    } else if (isAfter(initialCompletionDate, debtMaturityDate)) {
      iconsNameArray.push('dollar')
      iconsNameArray.push('target')
    }
  }

  return iconsNameArray
}

export const getCurrentWeekDates = () => {
  let now = new Date()
  let dayOfWeek = now.getDay() //0-6
  let numDay = now.getDate()

  let start = new Date(now) //copy
  start.setDate(numDay - dayOfWeek)
  start.setHours(0, 0, 0, 0)

  let end = new Date(now) //copy
  end.setDate(numDay + (7 - dayOfWeek))
  end.setHours(0, 0, 0, 0)

  return [start, end]
}

export const formatter = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  // @ts-ignore Browsers support this, but TS doesn't. see https://caniuse.com/mdn-javascript_builtins_intl_numberformat_numberformat_options_parameter_options_trailingzerodisplay_parameter
  trailingZeroDisplay: 'stripIfInteger',
})

export const formatAmount = (amount) => {
  return Number(amount.toString().replace(/,/g, ''))
}
