import React, { useState } from 'react';
import { Range, getTrackBackground } from 'react-range';
import { CurrencyDollarIcon, ClockIcon, ChartBarIcon } from '@heroicons/react/outline';
import { toast } from 'sonner';  // Import toast notification for error handling
import { saveCostVariation, saveRevenueVariation, saveTimeVariation } from 'lib/api/capital';  // API function to save cost variation data
import { useAppContext } from 'context';  // Custom hook to access app-wide context (developer data, etc.)

const SliderComponent = ({ next, resetResults }) => {
  const { developer } = useAppContext();  // Get developer from context
  const [selectedOption, setSelectedOption] = useState(null);  // Track selected option (Cost, Time, or Revenue)
  const [sliderValue, setSliderValue] = useState([0]);  // Track current slider value
  const [isLoading, setIsLoading] = useState(false);  // Track loading state

  // Define the options with their respective icons and tooltips
  const options = [
    { name: 'Cost', icon: <CurrencyDollarIcon className="h-6 w-6" />, tooltip: 'Vary cost to complete' },
    { name: 'Time', icon: <ClockIcon className="h-6 w-6" />, tooltip: 'Vary time to complete' },
    { name: 'Revenue', icon: <ChartBarIcon className="h-6 w-6" />, tooltip: 'Vary revenue to complete' },
  ];

  // Define the steps for the slider
  const STEPS = [-30, -20, -10, 0, 10, 20, 30];
  const MIN = STEPS[0]; // Minimum value of the slider
  const MAX = STEPS[STEPS.length - 1]; // Maximum value of the slider

  // Function to handle the selection of an option (Cost, Time, or Revenue)
  const handleOptionSelect = (option) => {
    if (selectedOption !== option) {
      resetResults(option);  // Reset results when a new option is selected
    }
    setSelectedOption(option);  // Update the selected option
  };

  // Function to handle the slider value change based on selected option
  const handleSliderChange = async (values) => {
    const value = values[0]; // Get the single value from the slider array
    setSliderValue(values);  // Update the slider value

    setIsLoading(true);  // Set loading state
    try {
      if (selectedOption === 'Cost') {
        const response = await saveCostVariation({
          developerName: developer.split(' ')[0],
          payload: {
            value_change: Math.abs(value / 100),
            status: value < 0 ? 'decrement' : 'increment',
          },
        });
        // Call the next function with the API response data
        next({
          capitalData: response.payload.capital_adequacy,
          roiData: response.payload.annualized_roi,
        });
      } else if (selectedOption === 'Time') {
        const response = await saveTimeVariation({
          developerName: developer.split(' ')[0],
          payload: {
            value_change: Math.abs(value / 100),
            status: value < 0 ? 'decrement' : 'increment',
          },
        });
        next({
          scheduleData: response.payload.schedule_feasibility,
          roiData: response.payload.annualized_roi,
        });
      } else if (selectedOption === 'Revenue') {
        const response = await saveRevenueVariation({
          developerName: developer.split(' ')[0],
          payload: {
            value_change: Math.abs(value / 100),
            status: value < 0 ? 'decrement' : 'increment',
          },
        });
        next({
          roiData: response.payload.annualized_roi,
        });
      }
    } catch (error) {
      toast.error(error.message ?? 'An error occurred while processing the slider change');
    } finally {
      setIsLoading(false);  // Reset loading state
    }
  };

  return (
    <div className="flex flex-col justify-center items-center h-full p-2 box-border">
      {/* Icons section */}
      <div className="flex justify-around w-full mb-4">
        {options.map((option) => (
          <div
            key={option.name}
            className={`relative cursor-pointer p-2 rounded-md ${selectedOption === option.name ? 'bg-lavender text-electricIndigo shadow-lg shadow-lavender/50' : 'text-lavender hover:bg-customLightBlue1'}`}
            onClick={() => handleOptionSelect(option.name)}  // Handle option select
          >
            {option.icon}
            {/* Tooltip */}
            <div className="absolute bottom-full left-1/2 w-32 -mb-4 transform -translate-x-1/2 p-2 rounded-2xl bg-gradient-to-r from-customLightBlue1 to-white border-r-2 border-b-2 border-customMidBlue3 shadow-lg bg-opacity-90 opacity-0 transition-opacity duration-300 hover:opacity-100 text-customDarkBlue2 text-center font-lato text-base font-thin">
              {option.tooltip}
            </div>
          </div>
        ))}
      </div>

      {/* Slider section */}
      <div className="w-full max-w-xs">
        <Range
          values={sliderValue}
          step={10}
          min={MIN}
          max={MAX}
          onChange={handleSliderChange}
          disabled={!selectedOption || isLoading}  // Disable when no option is selected or loading
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              className="w-full h-2 flex"
            >
              <div
                ref={props.ref}
                className="h-2 w-full rounded-md"
                style={{
                  background: getTrackBackground({
                    values: sliderValue,
                    colors: ['#e3d9f4', '#743d96', '#e3d9f4'],
                    min: MIN,
                    max: MAX,
                  }),
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              className="h-5 w-5 bg-lavender border-2 border-customLightBlue1 rounded-full"
            />
          )}
        />
        {/* Slider labels */}
        <div className="flex justify-between text-xs mt-2 text-mauve glow-effect text-lato">
          {STEPS.map((step) => (
            <span key={step}>{step}%</span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SliderComponent;
