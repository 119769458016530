import React from 'react';
import { useCashflowForecast, useFinancialRatios, useVarianceAnalysis } from 'data/solvency';
import { useAppContext } from 'context';
import { CircularProgress } from '@mui/material';
import { toast } from 'sonner';
import { groupBy } from 'lib/utils';
import { useDeveloperProjects } from 'data/project';
import SolvencyGlassCard from './solvency-components/SolvencyGlassComponent';
import NetCashFlow from './solvency-components/NetCashflowForecast';
import ForecastVariance from './solvency-components/VarianceAnalysisComponent';
import FinancialRatios from './solvency-components/OtherFinRatios';
import NetCashIcon from '../../assets/Title Icons/NetCashflowForecast.svg';
import FinRatIcon from '../../assets/Title Icons/FinancialRatiosIcon.svg';
import VarianceIcon from '../../assets/Title Icons/Variance Analysis.svg';


export default function Solvency() {
  const { developer } = useAppContext();

  // Extract the first word of developer
  const developerName = developer?.split(' ')?.[0];

  // Hooks for fetching data
  const cashflowForecast = useCashflowForecast({ developer: developerName });
  const varianceAnalysis = useVarianceAnalysis({ developer: developerName });
  const financialRatios = useFinancialRatios({ developer: developerName });

  const {
    data: projects,
    isLoading: isLoadingProjects,
    isError: isErrorFetchingProjects,
  } = useDeveloperProjects({ developer: developerName });

  // Handle early return if developer is not available
  if (!developer) {
    return <div>Developer information not available</div>;
  }

  // Error handling for project fetching
  if (isErrorFetchingProjects) {
    toast.error('Error fetching developer projects');
    return null; // Stop further rendering if there's an error
  }

  // Error handling for individual solvency-related data sources
  if (cashflowForecast.isError || varianceAnalysis.isError || financialRatios.isError) {
    toast.error('An error occurred while fetching graph information');
    return null; // Stop further rendering if there's an error
  }

  // Show loading spinner if data is being fetched
  if (
    cashflowForecast.isLoading ||
    varianceAnalysis.isLoading ||
    financialRatios.isLoading ||
    isLoadingProjects
  ) {
    return <CircularProgress />;
  }

  // Graceful handling for empty data with explicit checks
  const isCashflowForecastEmpty = !cashflowForecast.data || cashflowForecast.data.length === 0;
  const isVarianceAnalysisEmpty = !varianceAnalysis.data || varianceAnalysis.data.length === 0;
  const isFinancialRatiosEmpty = !financialRatios.data || financialRatios.data.length === 0;

  if (isCashflowForecastEmpty && isVarianceAnalysisEmpty && isFinancialRatiosEmpty) {
    return <div className="text-base font-light pt-20 text-customLightBlue1 font-lato glow-effect">No solvency data available for this developer</div>;
  }

  // Grouping data for variance analysis (with fallback)
  const groupedData = groupBy(varianceAnalysis?.data ?? [], 'period');

  return (
    <div className="bg-my-background-svg bg-cover bg-center h-screen w-[95vw] p-4 2xl:p-8 flex flex-col gap-2 ">
      {/* Padding at the top */}
      <div className="flex flex-grow h-[65vh] mt-[6%]">
        <SolvencyGlassCard title="Net Cashflow Forecast" iconSrc={NetCashIcon}>
          {!isCashflowForecastEmpty ? (
            <NetCashFlow data={cashflowForecast.data} />
          ) : (
            <div className="text-base font-light mb-2 text-customLightBlue1 font-lato glow-effect">No net cashflow forecast data available</div>
          )}
        </SolvencyGlassCard>
      </div>
      <div className="flex flex-grow gap-3 h-[90vh] mb-0">
        <div className="w-1/3 h-full">
          <SolvencyGlassCard title="Variance Analysis" iconSrc={VarianceIcon}>
            {!isVarianceAnalysisEmpty ? (
              <ForecastVariance data={varianceAnalysis.data} />
            ) : (
              <div className="text-base font-light mb-2 text-customLightBlue1 font-lato glow-effect">No variance analysis data available</div>
            )}
          </SolvencyGlassCard>
        </div>
        <div className="w-2/3 h-full">
          <SolvencyGlassCard title="Financial Ratios" iconSrc={FinRatIcon}>
            {!isFinancialRatiosEmpty ? (
              <FinancialRatios data={financialRatios.data} />
            ) : (
              <div className="text-base font-light mb-2 text-customLightBlue1 font-lato glow-effect">No financial ratios data available</div>
            )}
          </SolvencyGlassCard>
        </div>
      </div>
    </div>
  );
}

  