import React, { useState, useEffect, useRef } from 'react';
import { Pie, Bar } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js';
import GlassCard from './DebtsGlasscard';

Chart.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

const CreditExposureCredit = ({ creditData, interestData, lenderData, iconSrc }) => {
  // Combine the datasets into an array to cycle through
  const datasets = [
    { type: 'credit', data: creditData, label: 'Credit Type' },
    { type: 'interest', data: interestData, label: 'Interest Type' },
    { type: 'lender', data: lenderData, label: 'Lender Type' },
  ];

  const [currentDatasetIndex, setCurrentDatasetIndex] = useState(0);
  const currentDataset = datasets[currentDatasetIndex];

  const dynamicTitle = `Credit Exposure by ${currentDataset.label}`; // Dynamic title based on dataset type

  // Use refs to store chart instances for manual updates
  const barChartRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDatasetIndex((prevIndex) => (prevIndex + 1) % datasets.length);
    }, 5000); // 5 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [datasets.length]);

  // Force update of bar chart when dataset changes
  useEffect(() => {
    if (barChartRef.current) {
      barChartRef.current.update(); // Force chart update when dataset changes
    }
  }, [currentDatasetIndex]);

  const colors = {
    lightViolet: '#CF9FFF',
    electricIndigo: '#6F00FF',
    thistle: '#9999eb',
    periwinkle: '#CCCCFF',
    plum: '#DDA0DD',
    amethyst: '#9966CC',
    violet: '#EE82EE',
    heliotrope: '#DF73FF',
    mediumPurple: '#9370DB',
    orchid: '#DA70D6',
    lavender: '#E6E6FA',
    mauve: '#E0B0FF',
  };

  const chartColors = [
    colors.mauve, colors.orchid, colors.mediumPurple, colors.thistle, colors.periwinkle,
    colors.plum, colors.amethyst, colors.violet, colors.heliotrope, colors.lightViolet,
    colors.lavender, colors.electricIndigo,
  ];

  const createBarGradient = (ctx, chartArea) => {
    const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(0, colors.thistle);
    gradient.addColorStop(1, 'rgba(255, 255, 255, 1)');
    return gradient;
  };

  const createPieGradient = (ctx, index, chartArea) => {
    const gradient = ctx.createRadialGradient(
      (chartArea.left + chartArea.right) / 2,
      (chartArea.top + chartArea.bottom) / 2,
      chartArea.width / 2,
      (chartArea.left + chartArea.right) / 2,
      (chartArea.top + chartArea.bottom) / 2,
      0
    );
    gradient.addColorStop(0.5, chartColors[index]);
    gradient.addColorStop(1, 'rgba(255, 255, 255, 0.8)');
    return gradient;
  };

  const labels = currentDataset.data.map((item) => {
    if (currentDataset.type === 'credit') return item.credit_type;
    if (currentDataset.type === 'interest') return item.interest_type;
    if (currentDataset.type === 'lender') return item.lender_type;
    return '';
  });

  const wairData = currentDataset.data.map((item) => (item.wair * 100).toFixed(2));

  const barData = {
    labels,
    datasets: [
      {
        label: 'Weighted Average Interest Rate (WAIR)',
        data: wairData,
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return;
          }
          return createBarGradient(ctx, chartArea);
        },
        hoverBackgroundColor: colors.thistle,
        borderRadius: {
          topLeft: 0,
          topRight: 30,
          bottomLeft: 0,
          bottomRight: 30,
        },
      },
    ],
  };

  const pieData = {
    labels,
    datasets: [
      {
        label: 'Credit Exposure Percentage',
        data: currentDataset.data.map((item) => item.percentage),
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return;
          }
          return currentDataset.data.map((_, index) => createPieGradient(ctx, index, chartArea));
        },
        hoverBackgroundColor: chartColors.slice(0, currentDataset.data.length),
        borderWidth: 0,
        borderRadius: 5,
      },
    ],
  };
  const pieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '50%',
    plugins: {
      legend: { display: false },
      tooltip: {
        backgroundColor: '#ccc8f6', // Tooltip background color
        bodyFont: { family: 'Lato', size: 16 }, // Tooltip body font
        bodyColor: '#180d2b', // Tooltip body color
        padding: 10,
        borderRadius: 10,  
        callbacks: {
          // Remove title by overriding the title callback
          title: () => null,
  
          // Customize the label format
          label: (tooltipItem) => {
            const label = tooltipItem.label || ''; // Get the label (if any)
            const percentage = tooltipItem.raw; // Get the percentage
            const amount = currentDataset.data[tooltipItem.dataIndex].amount || 0; // Assuming you have 'amount' in your data
  
            // Format the amount with commas and a dollar sign
            const formattedAmount = `$${parseFloat(amount).toLocaleString('en-US')}`;
            
            // Format the percentage value to 2 decimal places
            const formattedPercentage = !isNaN(Number(percentage)) ? Number(percentage).toFixed(2) : '0.00';
            
            // Return the label and percentage on the first line, and the formatted amount on the second line
            return [`${label}`, `${formattedPercentage}%`, `${formattedAmount}`];
          },
        },
      },
    },
  };
  

  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    scales: {
      x: { beginAtZero: true, display: false },
      y: { display: false },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        backgroundColor: '#ccc8f6', // Tooltip background color
        titleFont: { family: 'Lato', weight: 'bold', size: 16 }, // Tooltip title font
        bodyFont: { family: 'Lato', size: 16 }, // Tooltip body font
        titleColor: '#180d2b', // Tooltip title color
        bodyColor: '#180d2b', // Tooltip body color
        padding: 10,
        borderRadius: 10, 
        callbacks: {
          title: () => null, // Remove title by overriding the title callback
          label: (tooltipItem) => {
            const label = tooltipItem.label || '';
            const wair = wairData[tooltipItem.dataIndex];
            return [`${label}`, `${wair}% WAIR`];
          },
        },
      },
    },
  };
  const glowEffectPlugin = {
    id: 'glowEffect',
    beforeDraw: (chart) => {
      const { ctx } = chart;
      ctx.save();
      ctx.shadowColor = 'rgba(255, 255, 255, 0.8)';
      ctx.shadowBlur = 20;
      ctx.shadowOffsetX = 0;
      ctx.shadowOffsetY = 0;
      ctx.clearRect(0, 0, chart.width, chart.height);
      ctx.restore();
    },
  };

  return (
    <GlassCard title="Credit Exposure" dynamicTitle={dynamicTitle} iconSrc={iconSrc}>
      <div className="w-[95vw] h-full flex flex-col justify-center items-center gap-2 max-w-3xl mx-auto">
        <div className="flex items-center gap-4 w-full h-full max-w-3xl mx-auto">
          <div className="flex-1 max-w-xs flex justify-center">
            <div className="w-full h-full">
              <Pie data={pieData} options={pieOptions} plugins={[glowEffectPlugin]} />
            </div>
          </div>
          <div className="flex-2 flex justify-center w-[90%] h-[70%]">
              <Bar ref={barChartRef} data={barData} options={barOptions} plugins={[glowEffectPlugin]} />
          </div>
        </div>
      </div>
    </GlassCard>
  );
};

export default CreditExposureCredit;
