import React from 'react';
import { useCashProjectionBalance, useMoneyMarket, useShortTermFinancing } from 'data/liquidity';
import { useAppContext } from 'context';
import { useDeveloperProjects } from 'data/project';
import { CircularProgress } from '@mui/material';
import { groupBy } from 'lib/utils';
import { toast } from 'sonner';
import CashBalanceProj from './liq-components/CashBalanceProjectionsBar';
import TitleCard from './liq-components/TitleGlass';
import ShortTerm from './liq-components/ShortTermFinancingBar';
import MoneyMarket from './liq-components/MoneyMarketComponent';
import CashBalanceIcon from '../../assets/Title Icons/CashBalanceProjections.svg';
import ShortTermIcon from '../../assets/Title Icons/ShortTermFinancing.svg';

export default function Liquidity() {
  const { developer } = useAppContext();

  // Extract the first word of developer
  const developerName = developer?.split(' ')?.[0];

  // Hooks must be called unconditionally at the top of the component
  const cashBalances = useCashProjectionBalance({ developer: developerName });
  const moneyMarket = useMoneyMarket({ developer: developerName });
  const shortTermFinancing = useShortTermFinancing({ developer: developerName });

  const {
    data: projects,
    isLoading: isLoadingProjects,
    isError: isErrorFetchingProjects,
  } = useDeveloperProjects({ developer: developerName });


  // Handle early return only after hooks are called
  if (!developer) {
    return <div>Developer information not available</div>;
  }

  // Error handling for project fetching
  if (isErrorFetchingProjects) {
    toast.error('Error fetching developer projects');
    return null; // Stop further rendering if there's an error
  }

  // Error handling for individual liquidity data sources
  if (cashBalances.isError || moneyMarket.isError || shortTermFinancing.isError) {
    toast.error('An error occurred while fetching graph information');
    return null; // Stop further rendering if there's an error
  }

  // Show loading spinner if data is being fetched
  if (cashBalances.isLoading || moneyMarket.isLoading || shortTermFinancing.isLoading || isLoadingProjects) {
    return <CircularProgress />;
  }

  // Graceful handling for empty data with explicit checks
  const isCashBalancesEmpty = !cashBalances.data || cashBalances.data.length === 0;
  const isMoneyMarketEmpty = !moneyMarket.data || moneyMarket.data.length === 0;
  const isShortTermFinancingEmpty = !shortTermFinancing.data || shortTermFinancing.data.length === 0;

  if (isCashBalancesEmpty && isMoneyMarketEmpty && isShortTermFinancingEmpty) {
    return <div className="text-base font-light pt-20 text-customLightBlue1 font-lato glow-effect">No liquidity data available for this developer</div>;
  }

  return (
    <div className="bg-my-background-svg bg-cover bg-center h-screen w-full relative">
      {/* Cash Balance Projections */}
      <TitleCard title="Cash Balance Projections" position={{ top: '7%', left: '5%' }}iconSrc={CashBalanceIcon} />
      {!isCashBalancesEmpty ? (
        <CashBalanceProj data={cashBalances.data} />
      ) : (
        <div className="text-base font-light mt-15 text-customLightBlue1 font-lato glow-effect">No cash balance projections available</div>
      )}

      {/* Short Term Financing */}
      <TitleCard title="Short Term Financing" position={{ top: '51%', left: '5%' }} iconSrc={ShortTermIcon} />
      {!isShortTermFinancingEmpty ? (
        <ShortTerm data={shortTermFinancing.data} />
      ) : (
        <div className="text-base font-light mb-2 text-customLightBlue1 font-lato glow-effect">No short-term financing data available</div>
      )}

      {/* Money Market positioned relatively using percentages */}
      {!isMoneyMarketEmpty ? (
        <div className="absolute bottom-[8%] 2xl:bottom-[9%] right-[3%] 2xl:right-[3%]">
          <MoneyMarket data={moneyMarket.data} />
        </div>
      ) : (
        <div className="absolute bottom-[15%] right-[3%] text-base font-light mb-2 text-customLightBlue1 font-lato glow-effect">No money market data available</div>
      )}
    </div>
  );
}
