import React from 'react'
import { twMerge } from 'tailwind-merge'
import { IoAddCircleOutline } from 'react-icons/io5'
import * as Dropdown from '@radix-ui/react-dropdown-menu'
import { Link, useNavigate } from 'react-router-dom'
import { useAuthActions } from 'context'

const MainSubMenu = () => {
  const navigate = useNavigate()
  const { setActiveMenu } = useAuthActions()

  return (
    <Dropdown.Root>
      <Dropdown.Trigger className="focus:outline-none">
        <IoAddCircleOutline size={45} color="#ccc8f6" />
      </Dropdown.Trigger>
      <Dropdown.Portal>
        <Dropdown.Content
          className={twMerge(
            'z-[100] data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideDownAndFade',
            'min-w-[18rem] rounded-3xl border border-bca-grey-2 bg-[#ccc8f6] px-10 py-4 shadow-[0px_7px_7px_-2px_#10182808]',
            'mt-2 font-lato will-change-[opacity,transform] data-[side=bottom]:animate-slideUpAndFade text-left'
          )}
          align="end"
        >
          <Dropdown.Group>
            <ul className="m-auto text-sm borderRadius: '10px' text-[#180d2b] divide-y-[2px] divide-darkrinkle block justify-start">
              <li>
                <Link
                  to="/project-data-form"
                  className="block borderRadius: '10px' py-2 pl-2 hover:bg-[#afaaed]"
                >
                  Add Project Data
                </Link>
              </li>
              <li>
                <Link
                  to="/account?project=Firmwide"
                  className="block borderRadius: '10px' py-2 pl-2 hover:bg-[#afaaed]"
                >
                  Firmwide Account
                </Link>
              </li>
              <li>
                <button
                  onClick={() => {
                    setActiveMenu({ activeMenu: 'app-data' })
                    navigate('/app-data/liquidity')
                  }}
                  className="block borderRadius: '10px' py-2 pl-2 hover:bg-[#afaaed]"
                >
                  Add Liquidity Data
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    setActiveMenu({ activeMenu: 'app-data' })
                    navigate('/app-data/debt')
                  }}
                  className="block borderRadius: '10px' py-2 pl-2 hover:bg-[#afaaed] borderRadius: '10px'"
                >
                  Add Debt Data
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    setActiveMenu({ activeMenu: 'app-data' })
                    navigate('/app-data/solvency')
                  }}
                  className="block borderRadius: '10px' py-2 pl-2 hover:bg-[#afaaed]"
                >
                  Add Firmwide Data
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    setActiveMenu({ activeMenu: 'app-data' })
                    navigate('/app-data/capital')
                  }}
                  className="block borderRadius: '10px' py-2 pl-2 hover:bg-[#afaaed]"
                >
                  Add Sales and Activity Tables
                </button>
              </li>
              <li>
                <Link
                  to="/client-onboarding-form"
                  className="borderRadius: '10px' block py-2 pl-2 hover:bg-[#afaaed]"
                >
                  Edit Onboarding Data
                </Link>
              </li>
            </ul>
          </Dropdown.Group>
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  )
}

export default MainSubMenu
