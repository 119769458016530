import React from 'react'
import { IoChevronBackCircleOutline, IoChevronForwardCircleOutline } from 'react-icons/io5'
import ProgressBar from 'components/progress-bar'
import { TextField } from 'components/form/input'
import { twMerge } from 'tailwind-merge'
import Button from 'components/button'
import { useState } from 'react'
import { Select } from 'components/form/input'
import { useNavigate } from 'react-router-dom'
import yup from 'lib/yup'
import { useAppContext } from 'context'
import { Formik } from 'formik'
import { createProject } from 'lib/api/project'
import { toast } from 'sonner'
import { formatAmount } from 'lib/utils'

const validationSchema = yup.object({
  project_name: yup.string().required('Enter project name'),
  street_number: yup.string().required('This is required'),
  street_name: yup.string().required('This is required'),
  city: yup.string().required('This is required'),
  province: yup.string().required('This is required'),
  postal_code: yup.string().required('This is required'),
  legal_entity: yup.string().required('This is required'),
  project_stage: yup.string().required('This is required'),
  property_use: yup.string().required('This is required'),
  number_of_units: yup.number().required('This is required'),
  build_type: yup.string().required('Select build type'),
  gross_floor_area: yup.string().required('This is required'),
  appraiser: yup.string().required('This is required'),
  appraisal_type: yup.string().required('Select appraisal type'),
  appraisal_date: yup.string().required('This is required'),
  appraisal_value: yup.string().required('This is required'),
})

const formDefaultValues = {
  project_name: '',
  street_number: '',
  street_name: '',
  city: '',
  province: '',
  postal_code: '',
  legal_entity: '',
  project_stage: '',
  property_use: '',
  build_type: '',
  number_of_units: 0,
  gross_floor_area: '',
  appraiser: '',
  appraisal_date: '',
  appraisal_type: '',
  appraisal_value: '',
}

export default function ProjectData() {
  const [step, setStep] = useState(1)
  const navigate = useNavigate()

  const { developer } = useAppContext()

  return (
    <div className="glass-card relative w-[80%] shadow-lg backdrop-blur-lg bg-lavender/5        relative border-r-2 border-b-2 border-white/30  min-h-[68vh] max-h-[76vh] mt-24 overflow-y-auto overflow-x-hidden rounded-[40px] px-14 py-8 justify-center justify-items-center">
      <div>
        <div className="w-1/12 flex absolute items-center gap-x-3 ">
          <button
            onClick={() => {
              step === 1 ? navigate('/client-data-form') : setStep(1)
            }}
          >
            <IoChevronBackCircleOutline size={40} color="#ccc8f6" />
          </button>
          {step === 1 && (
            <button
              onClick={() => {
                setStep(2)
              }}
            >
              <IoChevronForwardCircleOutline size={40} color="#ccc8f6" />
            </button>
          )}
        </div>
        <div className="  text-center">
          <span className="bg-gradient-to-b from-[#da70d6] via-[#ccccff] to-[#e6e6fa] font-lato text-4xl text-transparent bg-clip-text  ">
            Project Data
          </span>
        </div>
      </div>
      <Formik
        initialValues={formDefaultValues}
        onSubmit={async (values, actions) => {
          values.gross_floor_area = formatAmount(values.gross_floor_area)
          values.appraisal_value = formatAmount(values.appraisal_value)
          try {
            const payload = {
              ...values,
              developer,
            }
            await createProject({ developerName: developer.split(' ')?.[0], payload })
            toast.success('Project successfully created')
            actions.resetForm()
            navigate('/client-data-form')
          } catch (error) {
            toast.error(error.message ?? 'An error occurred while creating project')
          }
        }}
        validationSchema={validationSchema}
      >
        {({ values, errors, touched, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
          <section>
            <div className=" min-h-[48vh] mb-8  mt-6">
              {/* project data form one */}
              <form
                className={twMerge(
                  'flex justify-between gap-y-4 gap-x-0 2xl:gap-4 pt-6',
                  step === 1 ? '' : 'hidden'
                )}
              >
                <div className="space-y-8">
                  <div className=" gap-x-16  grid  grid-cols-[70px_auto] xl:grid-cols-[100px_auto] 2xl:grid-cols-[130px_auto] items-center">
                    <TextField
                      label="Project Name"
                      type="text"
                      id="project_name"
                      name="project_name"
                      value={values.project_name}
                      error={touched.project_name && !!errors.project_name}
                      helperText={touched.project_name ? errors.project_name : ''}
                      onChange={handleChange}
                    />
                  </div>
                  <div className=" gap-x-16  grid grid-cols-[70px_auto] xl:grid-cols-[100px_auto] 2xl:grid-cols-[130px_auto]   items-center">
                    <TextField
                      label=" Street Number"
                      type="text"
                      id="street_number"
                      name="street_number"
                      value={values.street_number}
                      error={touched.street_number && !!errors.street_number}
                      helperText={touched.street_number ? errors.street_number : ''}
                      onChange={handleChange}
                      maxLength={10}
                    />
                  </div>
                  <div className=" gap-x-16  grid grid-cols-[70px_auto] xl:grid-cols-[100px_auto] 2xl:grid-cols-[130px_auto]   items-center">
                    <TextField
                      label=" Street Name"
                      type="text"
                      id="street_name"
                      name="street_name"
                      value={values.street_name}
                      error={touched.street_name && !!errors.street_name}
                      helperText={touched.street_name ? errors.street_name : ''}
                      onChange={handleChange}
                    />
                  </div>

                  <div className=" gap-x-16  grid grid-cols-[70px_auto] xl:grid-cols-[100px_auto] 2xl:grid-cols-[130px_auto]   items-center">
                    <TextField
                      label="City"
                      maxLength={50}
                      type="text"
                      id="city"
                      name="city"
                      value={values.city}
                      error={touched.city && !!errors.city}
                      helperText={touched.city ? errors.city : ''}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="space-y-8">
                  <div className=" gap-x-16  grid grid-cols-[70px_auto] xl:grid-cols-[100px_auto] 2xl:grid-cols-[130px_auto]   items-center">
                    <label
                      className=" flex font-semibold text-customLightBlue1 font-lato align-baseline"
                      for="province"
                    >
                      Province
                    </label>
                    <select
                      className="flex   w-[10rem] xl:w-[13rem] 2xl:w-[16rem] h-10    text-bca-grey-9 focus:border-bca-success-6 focus:outline-none text-sm border-0 bg-lavender font-lato border-[#42644C]  rounded-full text-center"
                      name="province"
                      id="province"
                      value={values.province}
                      error={touched.province && !!errors.province}
                      helperText={touched.province ? errors.province : ''}
                      onChange={handleChange}
                    >
                      <option value="" selected disabled>
                        Select
                      </option>
                      <option value="ON">ON</option>
                      <option value="QC">QC</option>
                      <option value="NS">NS</option>
                      <option value="NB">NB</option>
                      <option value="MB">MB</option>
                      <option value="BC">BC</option>
                      <option value="PE">PE</option>
                      <option value="SK">SK</option>
                      <option value="AB">AB</option>
                      <option value="NL">NL</option>
                    </select>
                  </div>

                  <div className=" gap-x-16  grid grid-cols-[70px_auto] xl:grid-cols-[100px_auto] 2xl:grid-cols-[130px_auto]   items-center">
                    <TextField
                      label="Postal Code"
                      type="text"
                      id="postal_code"
                      name="postal_code"
                      value={values.postal_code}
                      error={touched.postal_code && !!errors.postal_code}
                      helperText={touched.postal_code ? errors.postal_code : ''}
                      onChange={handleChange}
                      maxLength={20}
                    />
                  </div>
                  <div className=" gap-x-16  grid grid-cols-[70px_auto] xl:grid-cols-[100px_auto] 2xl:grid-cols-[130px_auto]   items-center">
                    <TextField
                      label=" Legal Entity"
                      type="text"
                      id="legal_entity"
                      name="legal_entity"
                      value={values.legal_entity}
                      error={touched.legal_entity && !!errors.legal_entity}
                      helperText={touched.legal_entity ? errors.legal_entity : ''}
                      onChange={handleChange}
                    />
                  </div>
                  <div className=" gap-x-16  grid grid-cols-[70px_auto] xl:grid-cols-[100px_auto] 2xl:grid-cols-[130px_auto]   items-center">
                    <label
                      className=" flex font-semibold text-customLightBlue1 font-lato align-baseline"
                      for="project_stage"
                    >
                      Project Stage
                    </label>
                    <select
                      className="flex  w-[10rem] xl:w-[13rem] 2xl:w-[16rem] h-10   text-bca-grey-9 focus:border-bca-success-6 focus:outline-none text-sm border-0 bg-lavender font-lato border-[#42644C]  rounded-full text-center"
                      name="project_stage"
                      id="project_stage"
                      value={values.project_stage}
                      error={touched.project_stage && !!errors.project_stage}
                      helperText={touched.project_stage ? errors.project_stage : ''}
                      onChange={handleChange}
                    >
                      <option value="" selected disabled>
                        Select
                      </option>
                      <option value="Approval-OPA">Approval-OPA</option>
                      <option value="Approval-ZBA">Approval-ZBA</option>
                      <option value="Approval-SPA-DPA">Approval-SPA-DPA</option>
                      <option value="Servicing">Servicing</option>
                      <option value="Development Permit">Development Permit</option>
                      <option value="Presales">Presales</option>
                      <option value="Building Permit">Building Permit</option>
                      <option value="Construction">Construction</option>
                      <option value="Occupancy">Occupancy</option>
                      <option value="Operational">Operational</option>
                    </select>
                  </div>
                </div>
              </form>

              {/* project data form two */}
              <form
                className={twMerge(
                  'flex justify-between gap-4 pt-6 mb-16',
                  step === 2 ? '' : 'hidden'
                )}
              >
                <div className="space-y-8">
                  <div className=" gap-x-24  grid  grid-cols-[70px_auto] xl:grid-cols-[100px_auto] 2xl:grid-cols-[130px_auto] items-center">
                    <Select
                      id="property_use"
                      name="property_use"
                      label="Property Use"
                      value={values.property_use}
                      onChange={(e) => {
                        setFieldValue('property_use', e.target.value)
                      }}
                    >
                      <option value="" disabled selected>
                        Select
                      </option>
                      <option value="Residential">Residential</option>
                      <option value="Retail">Retail</option>
                      <option value="Hospitality">Hospitality</option>
                      <option value="Office">Office</option>
                      <option value="Industrial">Industrial</option>
                      <option value="Mixed-Use">Mixed-Use</option>
                      <option value="Other">Other</option>
                    </Select>
                  </div>
                  <div className=" gap-x-24  grid  grid-cols-[70px_auto] xl:grid-cols-[100px_auto] 2xl:grid-cols-[130px_auto]   items-center">
                    <Select
                      label=" Build Type"
                      id="build_type"
                      name="build_type"
                      value={values.build_type}
                      onChange={(e) => setFieldValue('build_type', e.target.value)}
                    >
                      <option value="" disabled selected>
                        Select
                      </option>
                      <option value="Detached Homes">Detached Homes</option>
                      <option value="Townhomes">Townhomes</option>
                      <option value="Stacked Towns">Stacked Towns</option>
                      <option value="Mixed Low Rise">Mixed Low Rise</option>
                      <option value="Low-rise Condominium">Low-rise Condominium</option>
                      <option value="Mid-rise Condominium">Mid-rise Condominium</option>
                      <option value="High-rise Condominium">High-rise Condominium</option>
                      <option value="Regional Mall">Regional Mall</option>
                      <option value="Neighbourhood Shopping Center">
                        Neighbourhood Shopping Center
                      </option>
                      <option value="Community Mall">Community Mall</option>
                      <option value="Power Center">Power Center</option>
                      <option value="Strip Mall">Strip Mall</option>
                    </Select>
                  </div>
                  <div className=" gap-x-24  grid  grid-cols-[70px_auto] xl:grid-cols-[100px_auto] 2xl:grid-cols-[130px_auto]   items-center">
                    <TextField
                      label=" Number of Units"
                      type="number"
                      id="number_of_units"
                      name="number_of_units"
                      value={values.number_of_units}
                      error={touched.number_of_units && !!errors.number_of_units}
                      helperText={touched.number_of_units ? errors.number_of_units : ''}
                      onChange={handleChange}
                    />
                  </div>

                  <div className=" gap-x-24  grid  grid-cols-[70px_auto] xl:grid-cols-[100px_auto] 2xl:grid-cols-[130px_auto]   items-center">
                    <TextField
                      label="Gross Floor Area"
                      maxLength={50}
                      type="text"
                      id="gross_floor_area"
                      name="gross_floor_area"
                      value={values.gross_floor_area}
                      error={touched.gross_floor_area && !!errors.gross_floor_area}
                      helperText={touched.gross_floor_area ? errors.gross_floor_area : ''}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="space-y-8">
                  <div className=" gap-x-24  grid  grid-cols-[50px_auto] xl:grid-cols-[100px_auto] 2xl:grid-cols-[130px_auto] items-center">
                    <TextField
                      label=" Appraiser"
                      maxLength={50}
                      type="text"
                      id="appraiser"
                      name="appraiser"
                      value={values.appraiser}
                      error={touched.appraiser && !!errors.appraiser}
                      helperText={touched.appraiser ? errors.appraiser : ''}
                      onChange={handleChange}
                    />
                  </div>
                  <div className=" gap-x-24  grid  grid-cols-[50px_auto] xl:grid-cols-[100px_auto] 2xl:grid-cols-[130px_auto]   items-center">
                    <TextField
                      label="Appraisal Date"
                      type="date"
                      id="appraisal_date"
                      name="appraisal_date"
                      value={values.appraisal_date}
                      error={touched.appraisal_date && !!errors.appraisal_date}
                      helperText={touched.appraisal_date ? errors.appraisal_date : ''}
                      onChange={handleChange}
                    />
                  </div>
                  <div className=" gap-x-24  grid grid-cols-[50px_auto] xl:grid-cols-[100px_auto] 2xl:grid-cols-[130px_auto]   items-center">
                    <Select
                      label=" Appraisal Type"
                      id="appraisal_type"
                      name="appraisal_type"
                      defaultValue="As-Complete"
                      value={values.appraisal_type}
                      onChange={(e) => setFieldValue('appraisal_type', e.target.value)}
                    >
                      <option value="" disabled selected>
                        Select
                      </option>
                      <option value="As-Is">As-Is</option>
                      <option value="As-Approved">As-Approved</option>
                      <option value="As-Complete">As-Complete</option>
                    </Select>
                  </div>

                  <div className=" gap-x-24  grid grid-cols-[50px_auto] xl:grid-cols-[100px_auto] 2xl:grid-cols-[130px_auto]   items-center">
                    <TextField
                      label="  Appraisal Value"
                      type="text"
                      id="appraisal_value"
                      name="appraisal_value"
                      value={values.appraisal_value}
                      error={touched.appraisal_value && !!errors.appraisal_value}
                      helperText={touched.appraisal_value ? errors.appraisal_value : ''}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </form>
              {step === 2 && (
                <Button
                  type="submit"
                  isSubmitting={isSubmitting}
                  onClick={handleSubmit}
                  title="Save"
                  className="h-16 -mt-4 w-[120px] mx-auto"
                />
              )}
            </div>
          </section>
        )}
      </Formik>

      <ProgressBar progress={step === 1 ? 50 : 100} />
    </div>
  )
}
