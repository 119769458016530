import { IoChevronBackCircleOutline } from 'react-icons/io5'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from 'components/button'
import { useAppContext } from 'context'
import { toast } from 'sonner'
import CircularProgress from '@mui/material/CircularProgress'
import { useUpcomingFinancing } from 'data/debt'
import { editUpcomingFinancing } from 'lib/api/debt'
import { useState, useEffect } from 'react'

export default function UpcomingFinancings() {
  const navigate = useNavigate()
  const { developer } = useAppContext()
  const [isSubmitting, setSubmitting] = useState(false)
  const {
    data: financing,
    isLoading: isLoadingFinancing,
    isError: isErrorFetchingFinancings,
    refetch: refetchFinancing,
  } = useUpcomingFinancing({ developer: developer.split(' ')[0] })

  if (isErrorFetchingFinancings) {
    toast.error('Error fetching upcoming financing')
  }

  const [finances, setFinances] = useState([])

  useEffect(() => {
    if (financing) {
      setFinances(
        financing.map((item) => ({
          id: item.id,
          actual_financing_stage: item.actual_financing_stage,
        }))
      )
    }
  }, [financing])

  const handleSubmit = async () => {
    for (let i = 0; i < finances.length; i++) {
      try {
        setSubmitting(true)
        const payload = {
          actual_financing_stage: finances[i].actual_financing_stage,
        }
        const response = await editUpcomingFinancing({
          developer: developer.split(' ')[0],
          payload,
          id: finances[i].id,
        })
        toast.success(`${response?.project}: Upcoming Financing Data saved successfully`)
        refetchFinancing()
      } catch (error) {
        toast.error(error.message ?? 'An error occurred while saving upcoming financing data')
      } finally {
        setSubmitting(false)
      }
    }
  }

  return (
    <div className="glass-card relative w-[90%] shadow-lg backdrop-blur-lg bg-lavender/5        relative border-r-2 border-b-2 border-white/30 h-[75vh] mt-24 overflow-y-hidden overflow-x-hidden rounded-[40px] px-8 xl:px-16 py-8 justify-center justify-items-center">
      <div className="w-full text-center">
        <span className="bg-gradient-to-b from-[#da70d6] via-[#ccccff] to-[#e6e6fa] font-lato text-4xl text-transparent bg-clip-text ">
          Upcoming Financings
        </span>
      </div>
      <button onClick={() => navigate(-1)} className="top-8 absolute">
        <IoChevronBackCircleOutline size={40} color="#ccc8f6" />
      </button>

      {isLoadingFinancing ? (
        <div className="grid place-items-center mt-36">
          <CircularProgress color="inherit" className="text-customLightBlue1" size={30} />
        </div>
      ) : financing ? (
        financing.length ? (
          <section>
            <div className="pt-4 overflow-y-auto flex justify-center">
              <table className="border-separate w-full xl:w-[80%] rounded-md border-spacing-y-8">
                <thead>
                  <tr className="graph-title">
                    <th className="graph-title font-semibold font-lato tracking-wider text-customLightBlue1 text-center">
                      Project
                    </th>
                    <th className="graph-title font-semibold font-lato tracking-wider text-customLightBlue1 text-center">
                      Purpose
                    </th>
                    <th className="font-semibold font-lato tracking-wider text-customLightBlue1 text-center">
                      Financing Period
                    </th>
                    <th className="font-semibold font-lato tracking-wider text-customLightBlue1 text-center">
                      Actual Stage
                    </th>
                  </tr>
                </thead>
                <tbody className="border-spacing-2">
                  {financing.map((item) => (
                    <tr className="bg-mediumPurple  align-middle">
                      <td className="py-2 text-sm font-lato tracking-wider text-customLightBlue1 rounded-l-lg text-center">
                        {item.project}
                      </td>
                      <td className="py-2 text-sm font-lato tracking-wider text-customLightBlue1 text-center">
                        {item.purpose}
                      </td>
                      <td className="py-2 text-sm font-lato tracking-wider pr-10 xl:pr-20 text-customLightBlue1 text-center">
                        {item.financing_period}
                      </td>
                      <td className="py-2 text-sm font-lato tracking-wider text-customDarkBlue2  text-center rounded-r-lg relative ">
                        <select
                          className=" absolute top-1/2 px-2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[9rem] xl:w-[10rem] 2xl:w-[12rem] h-10  2xl:h-12 text-bca-grey-9 focus:border-bca-success-6 focus:outline-none text-sm border-2 bg-lavender font-lato border-customLightBlue1 rounded-full inline-block"
                          name="actual_financial_stage"
                          id="actual_financial_stage"
                          value={
                            finances?.find((finance) => finance.id === item.id)
                              ?.actual_financing_stage
                          }
                          onChange={(e) => {
                            if (finances?.find((finance) => finance.id === item.id)) {
                              return setFinances(
                                finances.map((finance) =>
                                  finance.id === item.id
                                    ? {
                                        id: finance.id,
                                        actual_financing_stage: e.target.value,
                                      }
                                    : finance
                                )
                              )
                            } else {
                              setFinances([
                                ...finances,
                                {
                                  id: item.id,
                                  actual_financing_stage: e.target.value,
                                },
                              ])
                            }
                          }}
                        >
                          <option value="" disabled selected>
                            Select
                          </option>
                          <option value="First contact">First contact</option>
                          <option value="Discussion paper">Discussion paper</option>
                          <option value="Finalized agreement">Finalized agreement</option>
                          <option value="Letter of interest">Letter of interest</option>
                          <option value="Closing">Closing</option>
                        </select>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Button
              title="Save"
              type="submit"
              className="h-16 w-[120px] mt-10  mx-auto mb-6"
              isSubmitting={isSubmitting}
              onClick={handleSubmit}
            />
          </section>
        ) : (
          <p className=" mt-40 w-full grid place-items-center graph-title  font-lato font-semibold text-lg text-customLightBlue1">
            No upcoming financing yet
          </p>
        )
      ) : null}
    </div>
  )
}
