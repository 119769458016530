import React, { useMemo, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell, ReferenceLine } from 'recharts';
import LiqGlassCard from './LiquidityGlass';
import AccountIcon from '../../../assets/Tooltip Icons/Account.svg';
import AmountIcon from '../../../assets/Tooltip Icons/Amount.svg';
import DateIcon from '../../../assets/Tooltip Icons/Date.svg';

const ShortTerm = ({ data }) => {
  // Hover state to track the hovered bar
  const [hoveredIndex, setHoveredIndex] = useState(null);

  // Function to convert string values to numbers
  const convertToNumbers = (entry) => ({
    ...entry,
    amount: parseFloat(entry.amount),
  });

  // Memoize numericData so it's only recalculated if data changes
  const numericData = useMemo(() => data.map(convertToNumbers), [data]);

  // Memoize groupedData to prevent unnecessary recalculations
  const groupedData = useMemo(() => {
    const grouped = numericData.reduce((acc, current) => {
      const project = current.project;
      if (!acc[project]) {
        acc[project] = [];
      }
      acc[project].push(current);
      return acc;
    }, {});

    // Filter the data to include only the earliest date for each project
    Object.keys(grouped).forEach((project) => {
      const earliestDate = new Date(Math.min(...grouped[project].map(d => new Date(d.date))));
      grouped[project] = grouped[project].filter(item => 
        new Date(item.date).getTime() === earliestDate.getTime()
      );
    });

    return grouped;
  }, [numericData]);

  const colors = {
    liquidityRed: '#e4cfcf',
    liquidityGreen: '#e4fff5'
  };

  // Get the number of charts (one per project)
  const numberOfCharts = Object.keys(groupedData).length;

  // Calculate the width for each chart while keeping the height fixed
  const cardWidth = `${100 / numberOfCharts}%`;
  const cardHeight = '80%';  // Adjusted height for a shorter chart

  // Handle hover state and apply dynamic styles
  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const renderTooltip = ({ payload }) => {
    if (payload && payload.length) {
      const { account_type, account_ending, amount, date } = payload[0].payload;
  
      const tooltipData = [
        {
          icon: <img src={AccountIcon} className="w-4 h-4 mr-1" alt="Account Type" />,  // Account icon
          label: `${account_type} ${account_ending}`,  // Concatenated account_type and account_ending
        },
        {
          icon: <img src={AmountIcon} className="w-4 h-4 mr-1" alt="Amount" />,  // Amount icon
          label: `$${amount.toLocaleString()}`,  // Formatted amount value
        },
        {
          icon: <img src={DateIcon} className="w-4 h-4 mr-1" alt="Date" />,  // Date icon
          label: new Date(date).toISOString().split('T')[0],  // Formatted date value
        },
      ];
  
      return (
        <div className="p-4 rounded-lg bg-gradient-to-r from-customLightBlue1 to-white border-r-2 border-b-2 border-customMidBlue3 shadow-lg opacity-95 transform transition duration-200 ease-in-out">
          <table className="table-auto text-customDarkBlue2 font-lato">
            <tbody>
              {tooltipData.map((item, index) => (
                <tr key={index}>
                  {/* Icon cell */}
                  <td className="pr-2 font-bold border-r-2 border-customMidBlue3">
                    <span className="inline-flex items-center">{item.icon}</span>
                  </td>
                  {/* Label (text value) cell */}
                  <td className="pl-2">{item.label}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
    return null;
  };
  
  return (
    <div className="w-[70vw] 2xl:w-[65vw] h-[30vh] flex flex-col items-start">
      {/* Container for the charts */}
      <div className="w-full h-full flex justify-start mt-20 2xl:mt-28 p-12 2xl:mr-10">
        {Object.keys(groupedData).map((project) => {
          return (
            <div 
              key={project} 
              style={{ width: cardWidth, height: cardHeight }}  // Ensure each card fits within the available space
            >
              <LiqGlassCard title={project}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart 
                    data={groupedData[project]} 
                    layout="horizontal"  // This sets the chart layout to horizontal (default)
                  >
                    <XAxis 
                      type="category" 
                      dataKey="account_ending"  // Use account_ending as the X-axis
                      tick={false}  // Hide the X-axis labels but keep the axis line
                    />
                    <YAxis type="number" hide={true} />
                    <Tooltip cursor={{ fill: 'transparent' }} content={renderTooltip} />
                    <ReferenceLine y={0} stroke="#E6E6FA" strokeWidth={1} />  {/* Add a horizontal line at y=0 */}
                    <Bar 
                      dataKey="amount" 
                      radius={[30, 30, 0, 0]}  // Adjust the rounding for horizontal bars
                      minPointSize={5}  // Ensure small bars are still visible
                    >
                      {groupedData[project].map((entry, index) => (
                        <Cell 
                          key={`cell-${index}`} 
                          fill={entry.amount >= 0 ? colors.liquidityGreen : colors.liquidityRed} 
                          onMouseEnter={() => handleMouseEnter(index)}  // Track the index of hovered bar
                          onMouseLeave={handleMouseLeave}  // Reset hover state on mouse leave
                          style={{
                            filter: hoveredIndex === index ? 'brightness(1.2)' : 'none',  // Apply hover effect conditionally
                            cursor: 'pointer',
                            transition: 'filter 0.3s ease',
                          }}
                        />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </LiqGlassCard>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ShortTerm;
