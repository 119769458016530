import React from 'react'
import { twMerge } from 'tailwind-merge'
import { CircularProgress } from '@mui/material'

export const Button = ({ title, className, isSubmitting, onClick, ...rest }) => {
  return (
    <button
      onClick={onClick}
      disabled={isSubmitting}
      className={twMerge(
        'grid place-items-center bg-gradient-to-r from-[#da70d6] via-[#ccccff] to-[#e6e6fa] shadow-[6px_6px_4px_0px_rgba(0,0,0,0.15)] font-lato text-customDarkBlue2 rounded-full ',
        className
      )}
      {...rest}
    >
      {isSubmitting ? (
        <>
          <CircularProgress color="inherit" className="text-lavender" size={22} />
          <span className="sr-only">Submitting</span>
        </>
      ) : (
        <span>{title}</span>
      )}
    </button>
  )
}
export default Button
