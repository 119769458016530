import React, { useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip } from 'chart.js';
import ReactDOM from 'react-dom';
import TooltipComponent from '../../../components/tool-tips/tooltipscomp';  // Import the central TooltipComponent
import ProjectIcon from '../../../assets/Tooltip Icons/Project.svg';
import AmountIcon from '../../../assets/Tooltip Icons/Amount.svg';
import DateIcon from '../../../assets/Tooltip Icons/Date.svg';
import CategoryIcon from '../../../assets/Tooltip Icons/Category.svg';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

const ForecastVariance = ({ data }) => {
  const chartRef = useRef(null);

  // Define a color palette
  const colorPalette = [
    '#E6E6FA', '#E0B0FF', '#DA70D6', '#D8BFD8', '#CCCCFF', 
    '#DDA0DD', '#9966CC', '#EE82EE', '#DF73FF', '#9370DB', 
    '#CF9FFF', '#6F00FF'
  ];

  // Get a list of unique categories
  const uniqueCategories = [...new Set(data.map(item => item.category))];

  // Assign colors to each category
  const categoryColors = uniqueCategories.reduce((acc, category, index) => {
    acc[category] = colorPalette[index % colorPalette.length]; // Cycle through colors if there are more categories than colors
    return acc;
  }, {});

  // Group the data by period
  const groupedData = data.reduce((acc, cur) => {
    const period = cur.period;
    if (!acc[period]) acc[period] = [];
    acc[period].push(cur);
    return acc;
  }, {});

  // Prepare the data for the chart
  const labels = Object.keys(groupedData).sort();
  const datasets = uniqueCategories.map((category, index) => ({
    label: category,
    backgroundColor: categoryColors[category], // Set a solid color as the initial background
    borderRadius: 40,
    data: labels.map((period) => {
      const categoryData = groupedData[period]?.find((d) => d.category === category);
      return categoryData ? parseFloat(categoryData.variance) : 0;  // Convert variance to a number
    }),
  }));

  const chartData = {
    labels,
    datasets,
  };

  const options = {
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      tooltip: {
        enabled: false, // Disable default tooltip
        external: (context) => {
          const tooltipModel = context.tooltip;
          if (!tooltipModel.opacity) {
            document.getElementById('chartjs-tooltip').style.opacity = 0;
            return;
          }

          const rawPeriod = context.tooltip.title[0];
          const periodData = groupedData[rawPeriod];

          const category = context.tooltip.dataPoints[0].dataset.label;
          const categoryData = periodData ? periodData.find((d) => d.category === category) : null;

          if (!categoryData) return;

          const project = categoryData.project;
          const variance = parseFloat(categoryData.variance).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          });

          const icons = [
            <img src={DateIcon} className="w-4 h-4 mr-1" alt="Period" />,
            <img src={ProjectIcon} className="w-4 h-4 mr-1 text-customDarkBlue2" alt="Project" />,
            <img src={CategoryIcon} className="w-4 h-4 mr-1" alt="Category" />,
            <img src={AmountIcon} className="w-4 h-4 mr-1" alt="Variance" />, 
          ];

          const fields = [
            { value: `${categoryData.period}` },
            { value: `${project}` },
            { value: `${category}` },
            { value: `${variance}` },
          ];

          const tooltipElement = document.getElementById('chartjs-tooltip');
          tooltipElement.style.position = 'fixed';  // Change position to fixed
          tooltipElement.style.opacity = 1;
          tooltipElement.style.left = context.chart.canvas.offsetLeft + tooltipModel.caretX + 'px';
          tooltipElement.style.top = context.chart.canvas.offsetTop + tooltipModel.caretY - 10 + 'px';
          tooltipElement.style.zIndex = '999999';  // Increase z-index to ensure the tooltip is in front
          tooltipElement.style.font = tooltipModel.options.bodyFont.string;

          ReactDOM.render(
            <TooltipComponent fields={fields} icons={icons} />,
            tooltipElement
          );
        },
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false, // Remove the grid background
        },
        ticks: {
          display: false, // Remove the x-axis labels
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        grid: {
          display: false, // Remove the grid background
        },
        ticks: {
          display: false, // Remove the y-axis labels
        },
        border: {
          display: true, // Ensure the y-axis has a border line
        },
        borderColor: '#ccc8f6',
        borderWidth: 1,
        gridLines: {
          drawBorder: true,
        },
      },
    },
    animation: {
      onComplete: () => {
        const chart = chartRef.current;

        if (chart) {
          const ctx = chart.ctx;
          const chartArea = chart.chartArea;

          chart.data.datasets.forEach((dataset, datasetIndex) => {
            const meta = chart.getDatasetMeta(datasetIndex);
            meta.data.forEach((bar) => {
              // Save the current state before applying shadow
              ctx.save();

              // Apply shadow for the glow effect
              ctx.shadowColor = categoryColors[dataset.label];
              ctx.shadowBlur = 20;
              ctx.shadowOffsetX = 0;
              ctx.shadowOffsetY = 0;

              // Create a gradient for the bar
              const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
              gradient.addColorStop(0, categoryColors[dataset.label]);
              gradient.addColorStop(1, `${categoryColors[dataset.label]}33`);

              // Draw the bar with the gradient and shadow
              ctx.fillStyle = gradient;
              ctx.fillRect(bar.x - bar.width / 2, bar.y, bar.width, bar.base - bar.y);

              // Restore the original state without shadow
              ctx.restore();
            });
          });

          // Draw the x-axis line at y=0
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(chartArea.left, chart.scales.y.getPixelForValue(0));
          ctx.lineTo(chartArea.right, chart.scales.y.getPixelForValue(0));
          ctx.lineWidth = 1;
          ctx.strokeStyle = '#ccc8f6'; // Thin line with the desired color
          ctx.stroke();
          ctx.restore();
        }
      },
    },
  };

  return (
    <div className="w-[85vw] h-[23vh] relative">
      <Bar ref={chartRef} data={chartData} options={options} />
      <div id="chartjs-tooltip" className="absolute pointer-events-none"></div>
    </div>
  );
};

export default ForecastVariance;