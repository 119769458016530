import makeApiCall from '.'

export async function createProject({ developerName, payload }) {
  const { data } = await makeApiCall('/e-creprojects/projects/', 'post', payload, {
    baseURL: `https://${developerName}.alphamadesimple.ca`,
  })

  return data
}

export async function editProject({ developerName, payload, id }) {
  const { data } = await makeApiCall(`/e-creprojects/projects/${id}/`, 'patch', payload, {
    baseURL: `https://${developerName}.alphamadesimple.ca`,
  })

  return data
}

export async function fetchDeveloperProjects({ developer }) {
  const data = await makeApiCall('/e-creprojects/projects/', 'get', undefined, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })

  return data
}

export async function fetchDeveloperProject({ developer, id }) {
  const data = await makeApiCall(`/e-creprojects/projects/${id}/`, 'get', undefined, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })

  return data
}

export async function fetchFilesUploadStatus({ developer }) {
  const data = await makeApiCall(`/e-creprojects/project/state/`, 'get', undefined, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })

  return data
}

export async function fetchFirmwideFilesUploadStatus({ developer }) {
  return await makeApiCall(`/a-liquidity/firmwide-upload-status/`, 'get', undefined, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })
}

export async function fetchCLData({ developer }) {
  const data = await makeApiCall('/b-debts/debt-commitment-letters/', 'get', undefined, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })

  return data
}

export async function fetchOpeningBalanceStatus({ developer }) {
  const data = await makeApiCall(`/e-creprojects/bank-account-status/`, 'get', undefined, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })

  return data
}
