import Button from 'components/button'
import { useAuthActions } from 'context'
import { useNavigate } from 'react-router-dom'

export default function NotFound() {
  const navigate = useNavigate()
  const { setActiveMenu } = useAuthActions()

  return (
    <section className="h-[100vh]">
      <div
        className="flex flex-col justify-center items-center h-[60vh]  text-center relative px-4 "
        data-test-id="not-found"
      >
        <h1
          className="text-[9rem] sm:text-[12rem] text-primary font-bold leading-8"
          style={{ textShadow: '0.25rem 0.8125rem 0.25rem #0898a026' }}
        >
          404
        </h1>
        <p className="text-[2.8125rem] sm:text-[5rem] text-fill-transparent text-stroke-primary text-stroke-2 font-bold leading-normal">
          ERROR!
        </p>
        <p className="m-1 max-w-[44ch] text-soft">
          Sorry, the page you are looking for was not found
        </p>
        <Button
          title="Go Back"
          onClick={() => {
            setActiveMenu({ activeMenu: 'dashboard' })
            navigate('/dashboard/liquidity')
          }}
          type="submit"
          className="h-20 w-[160px] mt-12 2xl:mt-20 mx-auto"
        />
      </div>
    </section>
  )
}
