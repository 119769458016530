import React from 'react'
import { IoChevronBackCircleOutline } from 'react-icons/io5'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import { toast } from 'sonner'
import Button from 'components/button'
import { TextField } from 'components/form/input'
import { createDebtTransaction } from 'lib/api/debt'
import { useAppContext } from 'context'
import { useDebtCl } from 'data/debt'
import { CircularProgress } from '@mui/material'
import { formatAmount } from 'lib/utils'

const validationSchema = yup.object().shape({
  project: yup.string().required('Enter project name'),
  transaction_type: yup.string().required('Enter transaction type'),
  transaction_date: yup
    .string()
    .matches(/^\d{4}-\d{2}-\d{2}$/, 'Invalid date format (YYYY-MM-DD)')
    .required('A valid date is required'),
  loan_name: yup.string().required('Enter Loan name'),
  amount: yup.string().required('Enter Loan amount'),
  debt_outstanding: yup.string(),
})

export default function AddDebtTransaction() {
  const navigate = useNavigate()
  const { developer } = useAppContext()
  const [searchParams] = useSearchParams()

  const id = searchParams.get('cl-data-id')

  const {
    data: CLData,
    isLoading,
    isError,
  } = useDebtCl({ developer: developer.split(' ')?.[0], id })

  if (isError) {
    toast.error('An error occurred while fetching CL Data')
  }

  const formDefaultValues = {
    project: CLData?.project ?? '',
    transaction_type: '',
    transaction_date: '',
    loan_name: CLData?.project?.split(' ')?.[0] + ' - ' + CLData?.lender?.split(' ')?.[0] ?? '',
    amount: '',
    debt_outstanding: CLData?.debt_outstanding ?? '',
  }

  return (
    <div className="glass-card relative w-[90%] shadow-lg backdrop-blur-lg bg-lavender/5        relative border-r-2 border-b-2 border-white/30 h-[75vh] mt-24 overflow-y-auto overflow-x-hidden rounded-[40px] px-16 py-8 justify-center justify-items-center">
      <div className="w-full text-center">
        <span className="bg-gradient-to-b from-[#da70d6] via-[#ccccff] to-[#e6e6fa] font-lato text-4xl text-transparent bg-clip-text">
          Debt Transaction Form
        </span>
      </div>

      {isLoading ? (
        <div className=" grid place-items-center mt-36">
          <CircularProgress color="inherit" className="text-customLightBlue1" size={30} />
        </div>
      ) : (
        CLData && (
          <Formik
            initialValues={formDefaultValues}
            onSubmit={async (values) => {
              values.amount = formatAmount(values.amount)
              values.debt_outstanding = formatAmount(values.debt_outstanding)
              try {
                const payload = {
                  ...values,
                  developer,
                  debt_commitment_letter: Number(id),
                }
                await createDebtTransaction({ developer: developer.split(' ')?.[0], payload })
                toast.success('Debt transaction saved successfully')
                navigate(`/app-data/debt/transaction/history?cl-data-id=${id}`)
              } catch (error) {
                toast.error(error.message ?? 'An error occurred while saving debt transaction')
              }
            }}
            validationSchema={validationSchema}
          >
            {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
              <section>
                <button
                  className="top-8 absolute"
                  onClick={() => navigate(`/app-data/debt/transaction/history?cl-data-id=${id}`)}
                >
                  <IoChevronBackCircleOutline size={40} color="#ccc8f6" />
                </button>
                <div className="mt-8">
                  <form className="flex justify-between pt-6 mb-16">
                    <div className="space-y-10">
                      <div className="gap-x-4 xl:gap-x-8 grid grid-cols-[150px_auto] xl:grid-cols-[220px_auto] items-center">
                        <TextField
                          className="w-[10rem] xl:w-[12rem]  2xl:w-[16rem] h-12"
                          label="Project"
                          type="text"
                          id="project"
                          name="project"
                          value={values.project}
                          error={touched.project && !!errors.project}
                          helperText={touched.project ? errors.project : ''}
                          onChange={handleChange}
                        />
                      </div>
                      <div className=" gap-x-4 xl:gap-x-8  grid grid-cols-[150px_auto] xl:grid-cols-[220px_auto]  items-center">
                        <label
                          className=" flex font-semibold text-customLightBlue1 font-lato align-baseline"
                          for="transaction_type"
                        >
                          Transaction Type
                        </label>
                        <select
                          className="flex  w-[10rem]  xl:w-[12rem] 2xl:w-[16rem] h-12   text-bca-grey-9 focus:border-bca-success-6 focus:outline-none text-sm border-0 bg-lavender font-lato border-[#42644C]  rounded-full text-center"
                          name="transaction_type"
                          id="transaction_type"
                          value={values.transaction_type}
                          error={touched.transaction_type && !!errors.transaction_type}
                          helperText={touched.transaction_type ? errors.transaction_type : ''}
                          onChange={handleChange}
                        >
                          <option value="" selected disabled>
                            Select
                          </option>
                          <option value="Advance">Advance</option>
                          <option value="Discharge">Discharge</option>
                          <option value="Interest Type">Interest Type</option>
                        </select>
                      </div>
                      <div className="gap-x-4 xl:gap-x-8  grid grid-cols-[150px_auto] xl:grid-cols-[220px_auto] items-center">
                        <TextField
                          className="w-[10rem] xl:w-[12rem]  2xl:w-[16rem] h-12"
                          label="Transaction Date"
                          type="date"
                          id="transaction-date"
                          name="transaction_date"
                          value={values.transaction_date}
                          error={touched.transaction_date && !!errors.transaction_date}
                          helperText={touched.transaction_date ? errors.transaction_date : ''}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="space-y-10">
                      <div className="gap-x-4 xl:gap-x-8 grid grid-cols-[150px_auto] xl:grid-cols-[220px_auto] items-center">
                        <TextField
                          className="w-[10rem] xl:w-[12rem]  2xl:w-[16rem] h-12"
                          label="Loan Name"
                          type="text"
                          id="loan-name"
                          name="loan_name"
                          value={values.loan_name}
                          error={touched.loan_name && !!errors.loan_name}
                          helperText={touched.loan_name ? errors.loan_name : ''}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="gap-x-4 xl:gap-x-8 grid grid-cols-[150px_auto] xl:grid-cols-[220px_auto] items-center">
                        <TextField
                          className="w-[10rem] xl:w-[12rem]  2xl:w-[16rem] h-12"
                          label="Amount"
                          id="amount"
                          type="text"
                          name="amount"
                          value={values.amount}
                          onChange={handleChange}
                          error={touched.amount && !!errors.amount}
                          helperText={touched.amount ? errors.amount : ''}
                        />
                      </div>
                      <div className="gap-x-4 xl:gap-x-8 grid grid-cols-[150px_auto] xl:whitespace-nowrap xl:grid-cols-[220px_auto] items-center">
                        <TextField
                          className="w-[10rem] xl:w-[12rem] 2xl:w-[16rem] h-12"
                          label="Debt Outstanding"
                          type="text"
                          id="debt-outstanding"
                          name="debt_outstanding"
                          error={touched.debt_outstanding && !!errors.debt_outstanding}
                          helperText={touched.debt_outstanding ? errors.debt_outstanding : ''}
                          value={values.debt_outstanding}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="mt-24 mb-5 flex justify-between">
                  <Button
                    onClick={() => navigate(`/app-data/debt/transaction/history?cl-data-id=${id}`)}
                    title="View History"
                    className="h-16 w-[271px]"
                  />
                  <Button
                    type="submit"
                    title="Save Transaction"
                    className="h-16 w-[271px]"
                    isSubmitting={isSubmitting}
                    onClick={handleSubmit}
                  />
                </div>
              </section>
            )}
          </Formik>
        )
      )}
    </div>
  )
}
