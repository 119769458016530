import makeApiCall from '.'

export async function saveCLData({ developer, payload }) {
  const { data } = await makeApiCall('/b-debts/debt-commitment-letters/', 'post', payload, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })

  return data
}

export async function editCLData({ developer, payload, id }) {
  const { data } = await makeApiCall(`/b-debts/debt-commitment-letters/${id}/`, 'patch', payload, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })

  return data
}

export async function fetchDebtCl({ developer, id }) {
  return await makeApiCall(`/b-debts/debt-commitment-letters/${id}/`, 'get', undefined, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })
}

export async function fetchDebtCls({ developer }) {
  return await makeApiCall('/b-debts/debt-commitment-letters/', 'get', undefined, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })
}

export async function fetchUpcomingFinancing({ developer }) {
  return await makeApiCall('/b-debts/upcomingfinancing/', 'get', undefined, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })
}

export async function editUpcomingFinancing({ developer, payload, id }) {
  return await makeApiCall(`/b-debts/upcomingfinancing/${id}/`, 'patch', payload, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })
}

export async function fetchDebtTransactions({ developer }) {
  return await makeApiCall('/b-debts/debt-transactions/', 'get', undefined, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })
}

export async function fetchDebtTransaction({ developer, id }) {
  return await makeApiCall(`/b-debts/debt-transactions/${id}/`, 'get', undefined, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })
}

export async function editDebtTransaction({ developer, payload, id }) {
  return await makeApiCall(`/b-debts/debt-transactions/${id}/`, 'patch', payload, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })
}

export async function createDebtTransaction({ developer, payload }) {
  return await makeApiCall('/b-debts/debt-transactions/', 'post', payload, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })
}

export async function fetchCreditExposureLenders({ developer }) {
  const data = await makeApiCall('/b-debts/credit-exposure-lenders/', 'get', undefined, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })

  return data
}

export async function fetchCreditExposureCredits({ developer }) {
  const data = await makeApiCall('/b-debts/credit-exposure-credits/', 'get', undefined, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })

  return data
}

export async function fetchCreditExposureInterests({ developer }) {
  const data = await makeApiCall('/b-debts/credit-exposure-interests/', 'get', undefined, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })

  return data
}

export async function fetchLoanToValue({ developer }) {
  const data = await makeApiCall(
    '/b-debts/interest-rate-loan-to-value-tracking/',
    'get',
    undefined,
    {
      baseURL: `https://${developer}.alphamadesimple.ca`,
    }
  )

  return data
}

export async function fetchUpcomingMaturities({ developer }) {
  const data = await makeApiCall('/b-debts/upcoming-maturities/', 'get', undefined, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })
  return data
}

export async function fetchExpectedFinancing({ developer }) {
  const data = await makeApiCall('/b-debts/expected-financing/', 'get', undefined, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })
  return data
}

export async function fetchCreditTermCoverage({ developer }) {
  const data = await makeApiCall('/b-debts/credit-term-coverage/', 'get', undefined, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })
  return data
}

export async function fetchMilestoneTracker({ developer }) {
  const data = await makeApiCall('/b-debts/financing-milestone-tracker/', 'get', undefined, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })
  return data
}

export async function fetchDebtCLStatus({ developer }) {
  const data = await makeApiCall('/b-debts/debt-transaction-upload-status', 'get', undefined, {
    baseURL: `https://${developer}.alphamadesimple.ca`,
  })
  return data
}

export async function fetchUpcomingFinancingStatus({ developer }) {
  const data = await makeApiCall(
    '/b-debts/get-upcoming-financing-updated-status/',
    'get',
    undefined,
    {
      baseURL: `https://${developer}.alphamadesimple.ca`,
    }
  )
  return data
}
