import React, { useState, useEffect } from 'react';
import { FaRegCircle, FaHandshake, FaEnvelopeOpenText, FaFileSignature } from 'react-icons/fa';
import { RiDiscussFill } from "react-icons/ri";
import { FaMoneyBillTransfer } from 'react-icons/fa6';
import Tooltip from '@mui/material/Tooltip';
import TooltipComponent from '../../../components/tool-tips/tooltipscomp';  // Import your custom TooltipComponent
import ProjectIcon from '../../../assets/Tooltip Icons/Project.svg';
import DueDateIcon from '../../../assets/Tooltip Icons/EarliestDueDate.svg';
import { FaBullseye } from 'react-icons/fa';

const milestones = [
    "First Contact",
    "Discussion Paper",
    "Letter of Interest",
    "Commitment Letter",
    "Closing Date"
];

// Map the milestones to their respective icons
const milestoneIcons = {
    "First Contact": FaHandshake,
    "Discussion Paper": RiDiscussFill,
    "Letter of Interest": FaEnvelopeOpenText,
    "Commitment Letter": FaFileSignature,
    "Closing Date": FaMoneyBillTransfer,
};

const MilestoneTracker = ({ data }) => {
    const [animationDelays, setAnimationDelays] = useState([]);

    useEffect(() => {
        // Set up delays for the animations
        const delays = milestones.map((_, index) => ({
            iconDelay: index * 600, // delay for each icon
        }));
        setAnimationDelays(delays);
    }, []);

    // Function to sort by required_closing_date and get first 4
    const sortedData = data
        .sort((a, b) => new Date(a.required_closing_date) - new Date(b.required_closing_date))
        .slice(0, 4);

    return (
        <div className="p-0 m-0 w-full">
            {sortedData.map(item => {
                const actualIndex = milestones.indexOf(item.actual_funding_stage);
                const requiredIndex = milestones.indexOf(item.required_funding_stage_today);            

                return (
                    <div key={item.id} style={{ marginBottom: '2rem', padding: 6, margin: 0, width: '100%' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 0, flexWrap: 'wrap', width: '100%' }}>
                            {milestones.map((milestone, index) => {
                                const IconComponent = milestoneIcons[milestone]; // Get the icon component for the milestone
                                const isActive = index <= actualIndex || index <= requiredIndex;
                                const isLineRed = index >= actualIndex && index < requiredIndex;
                                const isCompleted = index <= actualIndex;
                                const status = isCompleted ? "Completed" : "Pending"; // Determine if the milestone is completed or pending

                                const lineColor = index < actualIndex ? '#cefaed' : isLineRed ? '#ffbbbb' : '#E6E6FA';
                                const lineOpacity = lineColor === '#E6E6FA' ? 0.45 : 1.0;

                                return (
                                    <React.Fragment key={milestone}>
                                        <Tooltip
                                            title={
                                                <div style={{ 
                                                    padding: '10px', 
                                                    fontSize: '12px', 
                                                    color: '#180d2b', 
                                                    backgroundColor: '#ccc8f6', 
                                                    fontFamily: 'Lato', 
                                                    borderRadius: '4px',
                                                    lineHeight: '1.5',
                                                    }}>
                                                    {`${milestone}: ${status}`} {/* Display the milestone and its status */}
                                                </div>
                                            }
                                            placement="top"  // This ensures the tooltip appears above the element
                                            PopperProps={{
                                                disablePortal: false, // Ensures tooltip doesn't affect the layout
                                                modifiers: [
                                                    {
                                                        name: 'preventOverflow',
                                                        options: {
                                                            boundary: 'viewport', // Ensure it doesn't overflow off the screen
                                                        },
                                                    },
                                                ],
                                            }}
                                            arrow // Add arrow to the tooltip for better UX
                                        >

                                            <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', flex: '1 1 auto' }}>
                                                <div
                                                    style={{
                                                        animation: `fadeInIcon 0.5s ease-in-out ${animationDelays[index]?.iconDelay}ms forwards`,
                                                        opacity: 0, // start hidden, reveal with animation
                                                    }}
                                                >
                                                    {isActive ? (
                                                        <IconComponent
                                                            style={{
                                                                fontSize: '2.5vw',
                                                                color: index <= actualIndex ? '#cefaed' : isLineRed ? '#ffbbbb' : '#ffbbbb',
                                                            }}
                                                        />
                                                    ) : (
                                                        <FaRegCircle
                                                            style={{
                                                                fontSize: '2.5vw',
                                                                color: '#E6E6FA',
                                                                opacity: 0.45,
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </Tooltip>
                                        {index < milestones.length - 1 && (
                                            <Tooltip
                                                title={
                                                    <TooltipComponent
                                                        fields={[
                                                            { value: `${item.project}` },
                                                            { value: `${item.purpose}` },
                                                            { value: `${item.required_closing_date}` }
                                                        ]}
                                                        icons={[
                                                            <img src={ProjectIcon} className="w-4 h-4 mr-1" alt={`Project Icon`} />,
                                                            <FaBullseye className="mr-1 text-customDarkBlue2 w-4 h-4" />,
                                                            <img src={DueDateIcon} className="w-4 h-4 mr-1" alt={`Due Date Icon`} />
                                                          ]}
                                                    />
                                                }
                                                placement="top"  // This ensures the tooltip appears above the element
                                                PopperProps={{
                                                    disablePortal: false, // Ensures tooltip doesn't affect the layout
                                                    modifiers: [
                                                        {
                                                            name: 'preventOverflow',
                                                            options: {
                                                                boundary: 'viewport', // Ensure it doesn't overflow off the screen
                                                            },
                                                        },
                                                    ],
                                                }}
                                                arrow // Add arrow to the tooltip for better UX
                                            >
                                                <div
                                                    style={{
                                                        flexGrow: 1,
                                                        height: '0.4rem',
                                                        margin: '0 0vw',
                                                        borderRadius: '0.25rem',
                                                        backgroundColor: lineColor,
                                                        opacity: lineOpacity, // final opacity based on color
                                                    }}
                                                />
                                            </Tooltip>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

// Add keyframe animations for fadeInIcon
const styles = document.createElement('style');
styles.innerHTML = `
    @keyframes fadeInIcon {
        to { opacity: 1; transform: scale(1); }
    }
`;
document.head.appendChild(styles);

export default MilestoneTracker;
