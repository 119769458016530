import React from 'react';

const TitleCard = ({ title, iconSrc, position }) => {
  return (
    <div
      // className="glass-card inline-block p-4 rounded-full shadow-lg backdrop-blur-lg bg-lavender/5        relative border-r-2 border-b-2 border-white/30"
      style={{ top: position.top, left: position.left, position: 'absolute' }}  // Enable positioning
    >
      <h2 className="text-xl font-light text-customLightBlue1 font-lato glow-effect">
          <img
            src={iconSrc}
            alt={` Icon`}
            className="mr-2 w-6 h-6 glow-icon text-customLightBlue1"
          />
        {title}
      </h2>
    </div>
  );
};

export default TitleCard;
