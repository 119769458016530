import { CircularProgress } from '@mui/material'
import axios from 'axios'
import { Input } from 'components/form/input'
import { jwtDecode } from 'jwt-decode'
import { login } from 'lib/api/auth'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Visibility, VisibilityOff } from '@mui/icons-material' // Import visibility icons
import logo from '../../assets/images/AppLogo.png' // Import the logo

export default function Login() {
  const navigate = useNavigate() // Initialize navigation for route redirection
  const [username, setUsername] = useState('') // State to manage the inputted username
  const [password, setPassword] = useState('') // State to manage the inputted password
  const [showPassword, setShowPassword] = useState(false) // State to toggle password visibility
  const [loginError, setLoginError] = useState('') // State to track any login error messages
  const [isSubmitting, setIsSubmitting] = useState(false) // State to track the login form submission status

  // Function to handle login logic
  const handleLogin = async () => {
    setIsSubmitting(true) // Start submission, show loading spinner
    try {
      setLoginError('') // Reset any previous error message
      const { access_token: token, refresh_token } = await login({ username, password })

      const tokenContent = token ? jwtDecode(token) : null

      if (tokenContent?.role === 'admin') {
        throw new Error('You cannot login with an admin account')
      }

      sessionStorage.setItem('WEB_APP_TOKEN', token)
      sessionStorage.setItem('WEB_APP_REFRESH_TOKEN', refresh_token)

      axios.defaults.headers.common.Authorization = `Bearer ${token}`

      localStorage.setItem('ACTIVE_MENU', 'dashboard')

      navigate('/dashboard/liquidity')
    } catch (error) {
      setLoginError(error.message ?? 'An error occurred')
    } finally {
      setIsSubmitting(false)
    }
  }

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev)
  }

  return (
    <div className="h-[100vh] flex flex-col bg-[#42644c] items-center justify-start w-full bg-gradient-base-bg bg-cover bg-no-repeat bg-center pt-10"> {/* Use justify-start and add padding-top */}
      <img src={logo} alt="App Logo" className="h-80 mb-[-30px]" /> {/* Negative margin to reduce spacing */}

      <div className="glass-card flex flex-col justify-between h-[40vh] w-[40vw] p-10 rounded-3xl shadow-lg backdrop-blur-lg bg-lavender/5        border-r-2 border-b-2 border-white/30">
        
        {/* Username input field */}
        <Input
          onChange={(e) => {
            setUsername(e.target.value)
          }}
          className="w-3/5 mx-auto h-[3.5rem] bg-lavender placeholder:text-opacity-100 font-lato font-thin italic text-customDarkBlue2 placeholder:text-text-customDarkBlue2"
          placeholder="Username"
          value={username}
        />

        {/* Password input field with visibility toggle */}
        <div className="relative w-3/5 mx-auto">
          <Input
            type={showPassword ? 'text' : 'password'}
            className="w-full h-[3.5rem] bg-lavender placeholder:text-opacity-100 font-lato font-thin italic text-customDarkBlue2 placeholder:text-customMidBlue3"
            placeholder="Password"
            onChange={(e) => {
              setPassword(e.target.value)
            }}
            value={password}
          />
          <span
            onClick={togglePasswordVisibility}
            className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </span>
          <p className="text-red-700 text-center mt-2">{loginError}</p>
        </div>

        {/* Login button */}
        <button
          onClick={() => handleLogin()}
          disabled={isSubmitting || !username || !password}
          className="rounded-full w-[117px] h-14 mx-auto py-3 px-5 grid place-items-center"
          style={{
            background: 'linear-gradient(to right, #da70d6, #ccccff)',
          }}
        >
          <span className="bg-gradient-to-b font-lato font-semibold text-customDarkBlue1 text-xl">
            {isSubmitting ? (
              <>
                <CircularProgress color="inherit" className="text-customLightBlue1" size={22} />
                <span className="sr-only">Submitting</span>
              </>
            ) : (
              'LOGIN'
            )}
          </span>
        </button>
      </div>
    </div>
  )
}
