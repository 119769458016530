import {
  fetchDeveloperProject,
  fetchDeveloperProjects,
  fetchFilesUploadStatus,
  fetchFirmwideFilesUploadStatus,
  fetchOpeningBalanceStatus,
} from 'lib/api/project'
import { useQuery } from 'react-query'

export function useDeveloperProjects({ developer }) {
  const query = useQuery(['developer-projects', developer], () =>
    fetchDeveloperProjects({ developer })
  )

  return query
}

export function useDeveloperProject({ developer, id }) {
  const query = useQuery(['developer-project', developer, id], () =>
    fetchDeveloperProject({ developer, id })
  )

  return query
}

export function useFilesUploadStatus({ developer }) {
  const query = useQuery(['file-upload-status', developer], () =>
    fetchFilesUploadStatus({ developer })
  )

  return query
}

export function useFirmwideFilesUploadStatus({ developer }) {
  const query = useQuery(['firmwide-file-upload-status', developer], () =>
    fetchFirmwideFilesUploadStatus({ developer })
  )

  return query
}

export function useOpeningBalanceStatus({ developer }) {
  const query = useQuery(['opening-balance-status', developer], () =>
    fetchOpeningBalanceStatus({ developer })
  )

  return query
}
