import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'rgba(204, 200, 246, 0.7)', // Adjust the opacity using the alpha value (0.8 = 80% opacity)
          color: '#180d2b',
          padding: '0px',
          fontFamily: 'Lato',
          borderRadius: '10px',
        },
      },
    },
  },
})

export default theme
