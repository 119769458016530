import { useQuery } from 'react-query'
import {
  fetchCreditExposureCredits,
  fetchCreditExposureInterests,
  fetchCreditExposureLenders,
  fetchCreditTermCoverage,
  fetchDebtCLStatus,
  fetchDebtCl,
  fetchDebtCls,
  fetchDebtTransaction,
  fetchDebtTransactions,
  fetchExpectedFinancing,
  fetchLoanToValue,
  fetchMilestoneTracker,
  fetchUpcomingFinancing,
  fetchUpcomingFinancingStatus,
  fetchUpcomingMaturities,
} from 'lib/api/debt'

export function useDebtCls({ developer }) {
  const query = useQuery(['debt-commitment-letters', developer], () => fetchDebtCls({ developer }))

  return query
}

export function useDebtCl({ developer, id }) {
  const query = useQuery(['debt-commitment-letter', developer, id], () =>
    fetchDebtCl({ developer, id })
  )

  return query
}

export function useUpcomingFinancing({ developer }) {
  const query = useQuery(['upcoming-financing', developer], () =>
    fetchUpcomingFinancing({ developer })
  )

  return query
}

export function useDebtTransactions({ developer }) {
  const query = useQuery(['debt-transactions', developer], () =>
    fetchDebtTransactions({ developer })
  )

  return query
}

export function useDebtTransaction({ developer, id }) {
  const query = useQuery(['debt-transaction', developer, id], () =>
    fetchDebtTransaction({ developer, id })
  )

  return query
}

export function useCreditExposureLenders({ developer }) {
  const query = useQuery(['credit-exposure-lenders', developer], () =>
    fetchCreditExposureLenders({ developer })
  )

  return query
}

export function useCreditExposureCredits({ developer }) {
  const query = useQuery(['credit-exposure-credits', developer], () =>
    fetchCreditExposureCredits({ developer })
  )

  return query
}

export function useCreditExposureInterests({ developer }) {
  const query = useQuery(['credit-exposure-interests', developer], () =>
    fetchCreditExposureInterests({ developer })
  )

  return query
}

export function useLoanToValue({ developer }) {
  const query = useQuery(['loan-to-value', developer], () => fetchLoanToValue({ developer }))

  return query
}

export function useUpcomingMaturities({ developer }) {
  const query = useQuery(['upcoming-maturities', developer], () =>
    fetchUpcomingMaturities({ developer })
  )

  return query
}

export function useExpectedFinancing({ developer }) {
  const query = useQuery(['expected-financing', developer], () =>
    fetchExpectedFinancing({ developer })
  )

  return query
}

export function useCreditTermCoverage({ developer }) {
  const query = useQuery(['credit-term-coverage', developer], () =>
    fetchCreditTermCoverage({ developer })
  )

  return query
}

export function useMilestoneTracker({ developer }) {
  const query = useQuery(['milestone-tracker', developer], () =>
    fetchMilestoneTracker({ developer })
  )

  return query
}

export function useDebtCLStatus({ developer }) {
  const query = useQuery(['debt-cl-status', developer], () => fetchDebtCLStatus({ developer }))

  return query
}

export function useUpcomingFinancingStatus({ developer }) {
  const query = useQuery(['upcoming-financing-status', developer], () =>
    fetchUpcomingFinancingStatus({ developer })
  )

  return query
}
