import { React, useState } from 'react'
import { EditFileButton, UploadFileButton } from 'components/button/data-entry'
import { LuUpload } from 'react-icons/lu'
import { BiSolidEdit } from 'react-icons/bi'
import { useAppContext } from 'context'
import { CircularProgress } from '@mui/material'
import { toast } from 'sonner'
import { useDeveloperProjects, useFilesUploadStatus } from 'data/project'
import Button from 'components/button'
import { uploadActivityFile, uploadFinancingSchedule, uploadSalesSummary } from 'lib/api/capital'

export default function ActivityAndSalesDataForm() {
  const { developer: clientOrgName } = useAppContext()
  const [fileDetails, setFileDetails] = useState([])
  const [isSubmittingFile, setIsSubmittingFile] = useState(false)
  const {
    data: projects,
    isLoading: isLoadingProjects,
    isError: isErrorFetchingProjects,
  } = useDeveloperProjects({ developer: clientOrgName.split(' ')?.[0] })

  const {
    data: filesUploadStatus,
    isLoading: isLoadingFileStatus,
    isError: isErrorFetchingUploadStatus,
    refetch: refetchFileUploadStatus,
  } = useFilesUploadStatus({
    developer: clientOrgName.split(' ')?.[0],
  })

  if (isErrorFetchingProjects) {
    toast.error('Error fetching developer projects')
  }

  if (isErrorFetchingUploadStatus) {
    toast.error('Error fetching files upload status')
  }

  const handleFileUpload = async (e, fileType, projectName) => {
    if (!e.target.files || !e.target.files[0]) {
      toast.error('No file selected')
      return
    }
    const resource = e.target.files[0]
    if (fileDetails.length) {
      if (
        fileDetails?.find((item) => item.projectName === projectName && item.fileType === fileType)
      ) {
        return setFileDetails(
          fileDetails.map((item) =>
            item.projectName === projectName && item.fileType === fileType
              ? {
                  file: resource,
                  projectName: projectName,
                  fileType: fileType,
                }
              : item
          )
        )
      }
    }
    setFileDetails([
      ...fileDetails,
      {
        file: resource,
        projectName: projectName,
        fileType: fileType,
      },
    ])
  }

  const uploadFile = async () => {
    for (let i = 0; i < fileDetails.length; i++) {
      try {
        setIsSubmittingFile(true)
        const formData = new FormData()
        formData.append('file', fileDetails[i].file)
        formData.append('developer', clientOrgName)

        if (fileDetails[i].fileType === 'activity_table') {
          const result = await uploadActivityFile({
            developerName: clientOrgName.split(' ')?.[0],
            payload: formData,
          })
          toast.success(`${fileDetails[i]?.projectName} Activity Table : ${result.status}`)
        } else if (fileDetails[i].fileType === 'sales_summary') {
          const result = await uploadSalesSummary({
            developerName: clientOrgName.split(' ')?.[0],
            payload: formData,
          })
          toast.success(`${fileDetails[i]?.projectName} Sales Summary: ${result.status}`)
        } else {
          const result = await uploadFinancingSchedule({
            developerName: clientOrgName.split(' ')?.[0],
            payload: formData,
          })
          toast.success(`${fileDetails[i]?.projectName} Financing Schedule: ${result.status}`)
        }
      } catch (error) {
        toast.error(
          `${fileDetails[i]?.projectName} ${fileDetails[i].fileType.replace('_', ' ')}   : ${error.message}` ??
            'An error occurred'
        )
      } finally {
        refetchFileUploadStatus()
        setIsSubmittingFile(false)
      }
    }
  }

  const getButtonTitle = (fileType, projectName, action, title) => {
    if (
      fileDetails.length &&
      fileDetails?.find((item) => item.projectName === projectName && item.fileType === fileType)
    ) {
      return fileDetails?.find(
        (item) => item.projectName === projectName && item.fileType === fileType
      )?.file.name
    } else {
      return action === 'edit' ? title : `Upload ${fileType.replace('_', ' ')}`
    }
  }

  return (
    <div className="glass-card relative w-[90%] shadow-lg backdrop-blur-lg bg-lavender/5 relative border-r-2 border-b-2 border-white/30  h-[75vh] mt-24  overflow-y-auto oveflow-x-auto 2xl:overflow-x-hidden rounded-[40px] px-16 py-8 flex flex-col items-center">
      <div className="capitalHeading w-full text-center">
        <span className="bg-gradient-to-b from-[#da70d6] via-[#ccccff] to-[#e6e6fa] font-lato text-4xl text-transparent bg-clip-text  ">
          Activity And Sales Table
        </span>
      </div>
      {isLoadingProjects || isLoadingFileStatus ? (
        <div className=" grid place-items-center mt-36">
          <CircularProgress color="inherit" className="text-customLightBlue1" size={30} />
        </div>
      ) : (
        projects &&
        (projects.length ? (
          <div className=" mt-16 ">
            <div className="grid grid-cols-[150px_200px_5px_200px_5px_200px] xl:grid-cols-[150px_230px_5px_230px_5px_230px] 2xl:grid-cols-[150px_250px_5px_250px_5px_250px] w-full gap-x-4 ">
              <div className="flex flex-col gap-y-9">
                {projects.map((item) => (
                  <label
                    key={item.id}
                    className=" graph-title w-40 font-lato font-semibold text-base text-customLightBlue1"
                  >
                    {item.project_name}
                  </label>
                ))}
              </div>
              <div className="flex flex-col gap-y-6">
                {projects?.map((item) =>
                  filesUploadStatus?.find((status) => status.project_name === item.project_name)
                    ?.activity_exists ? (
                    <div className="relative" key={item.id}>
                      <EditFileButton
                        title={getButtonTitle(
                          'activity_table',
                          `${item.project_name.split(' ')?.[0]}`,
                          'edit',
                          'Activity table updated'
                        )}
                        disabled={isSubmittingFile}
                        className="w-[200px] xl:w-[230px] 2xl:w-[250px] cursor-pointer"
                        icon={<BiSolidEdit color="customDarkBlue2" size={22} />}
                      />
                      <input
                        type="file"
                        name="file"
                        accept=" .csv"
                        disabled={isSubmittingFile}
                        onChange={(e) =>
                          handleFileUpload(
                            e,
                            'activity_table',
                            `${item.project_name.split(' ')?.[0]}`
                          )
                        }
                        className="absolute cursor-pointer inset-0 opacity-0"
                      />
                    </div>
                  ) : (
                    <div className="relative" key={item.id}>
                      <UploadFileButton
                        title={getButtonTitle(
                          'activity_table',
                          `${item.project_name.split(' ')?.[0]}`
                        )}
                        disabled={isSubmittingFile}
                        className="w-[200px] xl:w-[230px] 2xl:w-[250px] cursor-pointer"
                        icon={<LuUpload color="customDarkBlue2" size={22} />}
                      />
                      <input
                        type="file"
                        name="file"
                        accept=" .csv"
                        disabled={isSubmittingFile}
                        onChange={(e) =>
                          handleFileUpload(
                            e,
                            'activity_table',
                            `${item.project_name.split(' ')?.[0]}`
                          )
                        }
                        className="absolute cursor-pointer inset-0 opacity-0"
                      />
                    </div>
                  )
                )}
              </div>
              <div className=" w-[2.5px] bg-customLightBlue1"></div>
              <div className="flex flex-col gap-y-6">
                {projects?.map((item) =>
                  filesUploadStatus?.find((status) => status.project_name === item.project_name)
                    ?.sales_summary_exists ? (
                    <div className="relative" key={item.id}>
                      <EditFileButton
                        title={getButtonTitle(
                          'sales_summary',
                          `${item.project_name.split(' ')?.[0]}`,
                          'edit',
                          'Sales summary updated'
                        )}
                        disabled={isSubmittingFile}
                        className="w-[200px] xl:w-[230px] 2xl:w-[250px] cursor-pointer"
                        icon={<BiSolidEdit color="customDarkBlue2" size={22} />}
                      />
                      <input
                        type="file"
                        name="file"
                        accept=" .csv"
                        disabled={isSubmittingFile}
                        onChange={(e) =>
                          handleFileUpload(
                            e,
                            'sales_summary',
                            `${item.project_name.split(' ')?.[0]}`
                          )
                        }
                        className="absolute cursor-pointer inset-0 opacity-0"
                      />
                    </div>
                  ) : (
                    <div className="relative" key={item.id}>
                      <UploadFileButton
                        title={getButtonTitle(
                          'sales_summary',
                          `${item.project_name.split(' ')?.[0]}`
                        )}
                        disabled={isSubmittingFile}
                        className="w-[200px] xl:w-[230px] 2xl:w-[250px] text-xs cursor-pointer"
                        icon={<LuUpload color="customDarkBlue2" size={22} />}
                      />
                      <input
                        type="file"
                        name="file"
                        accept=" .csv"
                        disabled={isSubmittingFile}
                        onChange={(e) =>
                          handleFileUpload(
                            e,
                            'sales_summary',
                            `${item.project_name.split(' ')?.[0]}`
                          )
                        }
                        className="absolute cursor-pointer inset-0 opacity-0"
                      />
                    </div>
                  )
                )}
              </div>
              <div className=" w-[2.5px] bg-customLightBlue1"></div>
              <div className="flex flex-col gap-y-6">
                {projects?.map((item) =>
                  filesUploadStatus?.find((status) => status.project_name === item.project_name)
                    ?.financing_schedule_exists ? (
                    <div className="relative" key={item.id}>
                      <EditFileButton
                        title={getButtonTitle(
                          'financing_schedule',
                          `${item.project_name.split(' ')?.[0]}`,
                          'edit',
                          'Financing schedule updated'
                        )}
                        disabled={isSubmittingFile}
                        className="w-[200px] xl:w-[230px] 2xl:w-[250px] cursor-pointer"
                        icon={<BiSolidEdit color="customDarkBlue2" size={22} />}
                      />
                      <input
                        type="file"
                        name="file"
                        accept=" .csv"
                        disabled={isSubmittingFile}
                        onChange={(e) =>
                          handleFileUpload(
                            e,
                            'financing_schedule',
                            `${item.project_name.split(' ')?.[0]}`
                          )
                        }
                        className="absolute cursor-pointer inset-0 opacity-0"
                      />
                    </div>
                  ) : (
                    <div className="relative" key={item.id}>
                      <UploadFileButton
                        title={getButtonTitle(
                          'financing_schedule',
                          `${item.project_name.split(' ')?.[0]}`
                        )}
                        disabled={isSubmittingFile}
                        className="w-[200px] xl:w-[230px] 2xl:w-[250px] cursor-pointer"
                        icon={<LuUpload color="customDarkBlue2" size={22} />}
                      />
                      <input
                        type="file"
                        name="file"
                        accept=" .csv"
                        disabled={isSubmittingFile}
                        onChange={(e) =>
                          handleFileUpload(
                            e,
                            'financing_schedule',
                            `${item.project_name.split(' ')?.[0]}`
                          )
                        }
                        className="absolute cursor-pointer inset-0 opacity-0"
                      />
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="w-full mt-28 grid  justify-end">
              <Button
                title="Upload File"
                onClick={uploadFile}
                isSubmitting={isSubmittingFile}
                className="h-16 w-[150px]"
              />
            </div>
          </div>
        ) : (
          <p className=" mt-40 w-full grid place-items-center graph-title  font-lato font-semibold text-lg text-customLightBlue1">
            No active project
          </p>
        ))
      )}
    </div>
  )
}
