import {
  fetchAnnualizedRoi,
  fetchCapitalAdequacy,
  fetchCapitalSource,
  fetchCapitalSources,
  fetchLoanToValue,
  fetchScenarioAnnualizedRoi,
  fetchScenarioCapitalAdequacy,
  fetchScenarioScheduleFeasibility,
  fetchScheduleFeasibility,
} from 'lib/api/capital'
import { useQuery } from 'react-query'

export function useCapitalSources({ developer, project }) {
  const query = useQuery(['capital-sources', developer, project], () =>
    fetchCapitalSources({ developer, project })
  )

  return query
}

export function useCapitalSource({ developer, id }) {
  const query = useQuery(['capital-source', developer, id], () =>
    fetchCapitalSource({ developer, id })
  )

  return query
}

export function useLoanToValue({ developer }) {
  const query = useQuery(['loan-to-value', developer], () => fetchLoanToValue({ developer }))

  return query
}

export function useCapitalAdequacy({ developer }) {
  const query = useQuery(['capital-adequacy', developer], () => fetchCapitalAdequacy({ developer }))

  return query
}

export function useScenarioCapitalAdequacy({ developer }) {
  const query = useQuery(['scenario-capital-adequacy', developer], () =>
    fetchScenarioCapitalAdequacy({ developer })
  )

  return query
}

export function useScheduleFeasibility({ developer }) {
  const query = useQuery(['schedule-feasibility', developer], () =>
    fetchScheduleFeasibility({ developer })
  )

  return query
}

export function useScenarioScheduleFeasibility({ developer }) {
  const query = useQuery(['scenario-schedule-feasibility', developer], () =>
    fetchScenarioScheduleFeasibility({ developer })
  )

  return query
}

export function useAnnualizedRoi({ developer }) {
  const query = useQuery(['annualized-roi', developer], () => fetchAnnualizedRoi({ developer }))

  return query
}

export function useScenarioAnnualizedRoi({ developer }) {
  const query = useQuery(['scenario-annualized-roi', developer], () =>
    fetchScenarioAnnualizedRoi({ developer })
  )

  return query
}
