// Import necessary modules and components from various libraries
import { IoIosArrowDown } from 'react-icons/io' // Importing down arrow icon from react-icons
import * as Dropdown from '@radix-ui/react-dropdown-menu' // Import Radix UI's dropdown menu component
import { useState } from 'react' // Import React's useState hook for state management
import { IoIosArrowBack } from 'react-icons/io' // Importing back arrow icon from react-icons
import { twMerge } from 'tailwind-merge' // Importing a utility to merge Tailwind CSS classes
import { useEffect } from 'react' // Import React's useEffect hook for lifecycle events
import { useAuthActions } from 'context' // Import custom hook for authentication actions
import { CURRENT_DEVELOPER_ID_KEY, CURRENT_DEVELOPER_ORG_NAME } from 'lib/constants' // Import constants for session storage keys

// Define a DeveloperDropdown component that takes in options as a prop
export const DeveloperDropdown = ({ options }) => {
  const { updateDeveloper } = useAuthActions() // Destructure and use the updateDeveloper function from context
  // Find the currently selected developer from sessionStorage, using the org name
  const currentDeveloper = options?.find(
    (item) => item.value === sessionStorage.getItem(CURRENT_DEVELOPER_ORG_NAME)
  )?.label
  // Set developer state to the current developer or default to the first option's label
  const [developer, setDeveloper] = useState(currentDeveloper ?? options?.[0]?.label)

  // useEffect to update session storage and trigger side effects when developer state changes
  useEffect(() => {
    // Update sessionStorage with the selected developer's ID
    sessionStorage.setItem(
      CURRENT_DEVELOPER_ID_KEY,
      options?.find((item) => item.label === developer).id
    )
    // Update sessionStorage with the selected developer's organization name
    sessionStorage.setItem(
      CURRENT_DEVELOPER_ORG_NAME,
      options?.find((item) => item.label === developer).value
    )
    // Call updateDeveloper to reflect the change in the global context
    updateDeveloper({ developer })
    // eslint-disable-next-line
  }, [developer]) // Depend on developer to run the effect whenever it changes

  // Render the dropdown UI
  return (
    <>
      {options?.length ? ( // Only render if there are options
        <Dropdown.Root> {/* Radix UI Dropdown component root */}
          <Dropdown.Trigger className="py-2.5 focus:outline-none w-full z-[120]">
            {/* Dropdown trigger button with gradient background */}
            <div
              className=" bg-gradient-to-r from-[#9a3eb5] via-[#6843b3] to-[#330075] grid justify-between px-4 2xl:px-8 items-center grid-cols-[auto_auto] w-full h-12 2xl:h-14  font-lato rounded-full"
            >
              {/* Display selected developer name */}
              <span className="text-base 2xl:text-base text-lavender text-bold ">{developer}</span>
              <div className="w-full">
                {/* Down arrow icon */}
                <IoIosArrowDown color="#E6E6FA" size={20} />
              </div>
            </div>
          </Dropdown.Trigger>
          {/* Portal to display dropdown content */}
          <Dropdown.Portal>
            <Dropdown.Content
              className={twMerge(
                // Applying Tailwind CSS classes with animations and styles for the dropdown
                'z-[100] data-[side=center]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideDownAndFade',
                'min-w-[10rem] 2xl:min-w-[18rem] rounded-3xl border border-darkrinkle bg-customLightBlue1 px-5 py-4 shadow-[0px_7px_7px_-2px_#10182808]',
                ' text-customDarkBlue2 font-lato will-change-[opacity,transform] data-[side=bottom]:animate-slideUpAndFade'
              )}
              align="end"
            >
              {/* Dropdown items group */}
              <Dropdown.Group className=" divide-y-[2px] rounded-3xl ">
                {options.map(({ label, value }) => (
                  <Dropdown.Item
                    // Render each dropdown item with its label and onClick handler to update the developer
                    className="flex items-center cursor-pointer text-center  px-2 2xl:px-4 py-3 font-lato text-sm text-customDarkBlue2 rounded-full border-t-1 border-darkrinkle hover:bg-darkrinkle"
                    key={value}
                    onClick={() => setDeveloper(label)} // When clicked, update the developer state
                  >
                    {/* Display a back arrow icon if this is the selected developer */}
                    <span className={developer === label ? 'visible' : 'invisible'}>
                      <IoIosArrowBack color="#afaaed" size={25} />
                    </span>

                    {/* Developer label */}
                    <p>{label}</p>
                  </Dropdown.Item>
                ))}
              </Dropdown.Group>
            </Dropdown.Content>
          </Dropdown.Portal>
        </Dropdown.Root>
      ) : null} {/* If no options, render nothing */}
    </>
  )
}
