import React from 'react';

function Footer() {
    return (
        <footer className="fixed bottom-2 right-10 text-customLightBlue1 items-center justify-center">
            <div className="container mx-auto text-sm text-center items-center justify-center text-centert">
                <p>&copy; {new Date().getFullYear()} Realalfa Financial Inc. All Rights Reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;