import { React } from 'react'
import MainSubMenu from 'app-menus/main-sub-menu'
import { useAppContext } from 'context'
import { useDeveloperProjects } from 'data/project'
import { CircularProgress } from '@mui/material'
import { toast } from 'sonner'
import { twMerge } from 'tailwind-merge'
import * as Dropdown from '@radix-ui/react-dropdown-menu'
import { Link } from 'react-router-dom'
import { BsThreeDotsVertical } from 'react-icons/bs'

export default function ClientDataForm() {
  const { developer } = useAppContext()

  const {
    data: projects,
    isLoading: isLoadingProjects,
    isError: isErrorFetchingProjects,
  } = useDeveloperProjects({ developer: developer.split(' ')?.[0] })

  if (isErrorFetchingProjects) {
    toast.error('Error fetching developer projects')
  }

  return (
    <div className="glass-card relative w-[80%] shadow-lg backdrop-blur-lg bg-lavender/5        relative border-r-2 border-b-2 border-white/30  min-h-[65vh] max-h-[76vh] mt-24 overflow-y-hidden overflow-x-hidden rounded-[40px] px-16 py-8 justify-center justify-items-center">
      <div className="w-full justify-center relative  flex ">
        <div className="capitalHeading">
          <span className="bg-gradient-to-b from-[#da70d6] via-[#ccccff] to-[#e6e6fa] font-lato text-4xl text-transparent bg-clip-text ">
            {developer.split(' ')?.[0]}
          </span>
          <span className="bg-gradient-to-b from-[#da70d6] via-[#ccccff] to-[#e6e6fa] font-lato text-4xl text-transparent bg-clip-text">
            {' '}
            {developer.split(' ')?.[1]}
          </span>
        </div>
        <div className="absolute top-0 right-10">
          <MainSubMenu />
        </div>
      </div>
      {isLoadingProjects ? (
        <div className=" grid place-items-center mt-36">
          <CircularProgress color="inherit" className="text-customLightBlue1" size={30} />
        </div>
      ) : (
        projects &&
        (projects.length ? (
          <div className="  max-h-[48vh] pt-4 overflow-y-auto ml-10 flex justify-center  ">
            <table className=" w-[70%] min-w-[65%]  max-w-[80%] ">
              <thead className="font-lato font-semibold text-customLightBlue1">
                <tr className=" graph-title  ">
                  <th className=" w-[200px] pl-6 py-5 text-left font-semibold font-lato   tracking-wider text-customLightBlue1">
                    Project Name
                  </th>
                  <th className=" w-[100px] pl-4 text-left font-semibold font-lato   tracking-wider text-customLightBlue1">
                    City
                  </th>
                  <th className=" w-[150px] text-left  font-semibold font-lato   tracking-wider text-customLightBlue1">
                    Province
                  </th>
                  <th className=" w-[80px]  text-left  text-transparent font-semibold font-lato   tracking-wider text-customLightBlue1"></th>
                </tr>
              </thead>
              <tbody>
                {projects.map((item) => (
                  <tr className="odd:bg-[#9c7edb] align-middle ">
                    <td className=" text-sm py-2 pl-6 font-lato tracking-wider text-customLightBlue1 rounded-l-lg ">
                      {item.project_name}
                    </td>
                    <td className=" text-sm font-lato tracking-wider text-customLightBlue1">{item.city}</td>
                    <td className="  text-sm font-lato tracking-wider  text-customLightBlue1">
                      {item.province}
                    </td>
                    <td className=" text-sm font-lato tracking-wider text-customLightBlue1 rounded-r-lg">
                      <Dropdown.Root>
                        <Dropdown.Trigger className="focus:outline-none">
                          <button>
                            <BsThreeDotsVertical size={25}  />
                          </button>
                        </Dropdown.Trigger>
                        <Dropdown.Portal>
                          <Dropdown.Content
                            className={twMerge(
                              'z-[100] data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideDownAndFade',
                              'min-w-fit rounded-xl border border-bca-grey-2 bg-customLightBlue1 px-8 py-4 shadow-[0px_7px_7px_-2px_#10182808]',
                              ' font-lato will-change-[opacity,transform] data-[side=bottom]:animate-slideUpAndFade'
                            )}
                            align="end"
                          >
                            <Dropdown.Group>
                              <ul className=" m-auto text-sm text-center  bg-customLightBlue1 text-customDarkBlue2 divide-y-[2px] divide-darkrinkle font-lato block justify-center">
                                <li>
                                  <Link
                                    to={`/project-data-form/${item.id}`}
                                    className="block lg:inline-block px-4 py-3 hover:bg-[#afaaed] "
                                  >
                                    Edit Project Data
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={`/capital-sources?project=${item.project_name.replace(/\s+/g, '-')}`}
                                    className="block lg:inline-block px-4 py-3 hover:bg-[#afaaed] "
                                  >
                                    Capital Sources
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={`/account?project=${item.project_name.replace(/\s+/g, '-')}`}
                                    className="block lg:inline-block px-4 py-3 hover:bg-[#afaaed] "
                                  >
                                    Project Account
                                  </Link>
                                </li>
                              </ul>
                            </Dropdown.Group>
                          </Dropdown.Content>
                        </Dropdown.Portal>
                      </Dropdown.Root>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className=" mt-40 w-full grid place-items-center graph-title  font-lato font-semibold text-lg text-customLightBlue1">
            No active project
          </p>
        ))
      )}
    </div>
  )
}
